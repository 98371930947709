<template>
  <div class="contentpost">
    <b-modal
      ref="contentDetailPost"
      no-close-on-backdrop
      class="modal"
      id="contentDetailPost"
      hide-footer
      hide-backdrop
    >
      <template class="modal-header" #modal-title>
        <h4 class="modal-title">
          {{
            (obj.id_red_social == 1
              ? "Facebook Post"
              : obj.id_red_social == 2
              ? "Tweet"
              : obj.id_red_social == 3
              ? "Instagram Post"
              : obj.id_red_social == 4
              ? "Video"
              : obj.id_red_social == 5
              ? "Linkedin Post"
              : "Post") +
            ": " +
            momenttemporal(obj.post_fecha)
          }}
        </h4>
      </template>
      <b-container>
        <div class="row">
          <div class="col-sm-6 col-xs-6 img_mdl">
            <div class="row img_tt">
              <a :href="obj.url_Post || ''" target="_blank">
                <div class="mpcm">{{ obj.post_content }}</div>
                <img
                  onerror="this.onerror=null;this.src='https://cdn4.iconfinder.com/data/icons/solid-part-6/128/image_icon-128.png';"
                  :src="obj.post_imagen"
                />
              </a>
            </div>
          </div>
          <div class="col-sm-6 col-xs-6 info_mdl">
            <h4><b>Post Performance</b></h4>
            <div class="row">
              <!----FACEBOOK--->
              <div
                v-if="obj.id_red_social == 1"
                class="col-sm-2 col-xs-6 mpcr fa fa-share-alt"
              >
                {{ obj.interacciones.share || 0 }}
              </div>
              <div
                v-if="obj.id_red_social == 1"
                class="col-sm-3 col-xs-6 mpcr fa fa-comments"
              >
                {{ (obj.comentarios ? obj.comentarios.length : 0) || 0 }}
              </div>
              <div v-if="obj.id_red_social == 1" class="w-100"></div>
              <div v-if="obj.id_red_social == 1" class="w-100"></div>
              <div v-if="obj.id_red_social == 1" class="col-sm-1">
                {{
                  obj.interacciones.like +
                  obj.interacciones.love +
                  obj.interacciones.haha +
                  obj.interacciones.wow +
                  obj.interacciones.sad +
                  obj.interacciones.angry
                }}
              </div>
              <div v-if="obj.id_red_social == 1" class="col">Reactions</div>
              <div v-if="obj.id_red_social == 1" class="w-100"></div>
              <div v-if="obj.id_red_social == 1" class="w-100"></div>
              <div v-if="obj.id_red_social == 1" class="mpcr pr-0">
                <img
                  src="../../../assets/all-images/reactions/like.svg"
                  class="h-5"
                />
              </div>
              <div v-if="obj.id_red_social == 1">
                {{ obj.interacciones.like || 0 }}
              </div>
              <div v-if="obj.id_red_social == 1" class="col-xs-6 mpcr pr-0">
                <img
                  src="../../../assets/all-images/reactions/love.svg"
                  class="h-5"
                />
              </div>
              <div v-if="obj.id_red_social == 1">
                {{ obj.interacciones.love || 0 }}
              </div>
              <div v-if="obj.id_red_social == 1" class="col-xs-6 mpcr pr-0">
                <img
                  src="../../../assets/all-images/reactions/haha.svg"
                  class="h-5"
                />
              </div>
              <div v-if="obj.id_red_social == 1">
                {{ obj.interacciones.haha || 0 }}
              </div>
              <div v-if="obj.id_red_social == 1" class="col-xs-6 mpcr pr-0">
                <img
                  src="../../../assets/all-images/reactions/wow.svg"
                  class="h-5"
                />
              </div>
              <div v-if="obj.id_red_social == 1">
                {{ obj.interacciones.wow || 0 }}
              </div>
              <div v-if="obj.id_red_social == 1" class="col-xs-6 mpcr pr-0">
                <img
                  src="../../../assets/all-images/reactions/sad.svg"
                  class="h-5"
                />
              </div>
              <div v-if="obj.id_red_social == 1">
                {{ obj.interacciones.sad || 0 }}
              </div>
              <div v-if="obj.id_red_social == 1" class="col-xs-6 mpcr pr-0">
                <img
                  src="../../../assets/all-images/reactions/angry.svg"
                  class="h-5"
                />
              </div>
              <div v-if="obj.id_red_social == 1">
                {{ obj.interacciones.angry || 0 }}
              </div>
              <!----FACEBOOK--->
              <!----TWITTER--->
              <div
                v-if="obj.id_red_social == 2"
                class="col-xs-6 mpcr fa fa-retweet"
              >
                {{ obj.interacciones.retweet || 0 }}
              </div>
              <div
                v-if="obj.id_red_social == 2"
                class="col-xs-6 mpcr far fa-thumbs-up"
              >
                {{ obj.interacciones.like || 0 }}
              </div>
              <div
                v-if="obj.id_red_social == 2"
                class="col-xs-6 mpcr fa fa-comments"
              >
                {{ (obj.comentarios ? obj.comentarios.length : 0) || 0 }}
              </div>
              <!----TWITTER--->
              <!----INSTAGRAM--->
              <div
                v-if="obj.id_red_social == 3"
                class="col-xs-6 mpcr far fa-heart"
              >
                {{ obj.interacciones.like || 0 }}
              </div>
              <div
                v-if="obj.id_red_social == 3"
                class="col-xs-6 mpcr fa fa-comments"
              >
                {{ (obj.comentarios ? obj.comentarios.length : 0) || 0 }}
              </div>
              <!----INSTAGRAM--->
              <!----YOUTUBE--->
              <div
                v-if="obj.id_red_social == 4"
                class="col-xs-6 mpcr far fa-thumbs-up"
              >
                {{ obj.interacciones.like || 0 }}
              </div>
              <div
                v-if="obj.id_red_social == 4"
                class="col-xs-6 mpcr fa fa-thumbs-down"
              >
                {{ obj.interacciones.dislike || 0 }}
              </div>
              <!----YOUTUBE--->
              <!----LINKEDIN--->
              <div
                v-if="obj.id_red_social == 5"
                class="col-xs-6 mpcr fa fa-thumbs-up"
              >
                {{ obj.interacciones.like || 0 }}
              </div>
              <div
                v-if="obj.id_red_social == 5"
                class="col-xs-6 mpcr fa fa-glass-cheers"
              >
                {{ obj.interacciones.haha || 0 }}
              </div>
              <div
                v-if="obj.id_red_social == 5"
                class="col-xs-6 mpcr far fa-laugh"
              >
                {{ obj.interacciones.wow || 0 }}
              </div>
              <div
                v-if="obj.id_red_social == 5"
                class="col-xs-6 mpcr far fa-heart"
              >
                {{ obj.interacciones.love || 0 }}
              </div>
              <div
                v-if="obj.id_red_social == 5"
                class="col-xs-6 mpcr far fa-lightbulb"
              >
                {{ obj.interacciones.sad || 0 }}
              </div>
              <div
                v-if="obj.id_red_social == 5"
                class="col-xs-6 mpcr fa fa-hand-holding-heart"
              >
                {{ obj.interacciones.share || 0 }}
              </div>
              <!----LINKEDIN--->
            </div>
          </div>
        </div>
      </b-container>
    </b-modal>
  </div>
</template>
<script>
import { Helpers, mapGetters } from "@isc/styleguide";

export default {
  name: "contentpost",
  props: {
    obj: Object,
  },
  computed: {
    ...mapGetters({
      userInfo: "settings/profile/getObjUser",
    }),
  },
  methods: {
    show() {
      this.$refs["contentDetailPost"].show();
    },
    momenttemporal(date) {
      return Helpers.transformStringDate(
        date,
        this.userInfo.TIME_ZONE,
        "dddd D, MMMM YYYY h:mm a "
      );
    },
  },
};
</script>

<style>
#contentDetailPost .modal-header {
  width: 100%;

  height: 51px;
  padding: 15px;
  border-radius: 0px !important;
  border: none;
}
#contentDetailPost .modal-header h4 {
  font-size: 15px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.07;
  color: #333b52;
}
#contentDetailPost .modal-content {
  border-radius: 0px;
  width: 748px !important;
  margin: auto;
  margin-top: 60px;
  margin-left: -20%;
  margin-right: 60%;
  box-shadow: 0px 6px 4.7px 0.3px rgba(41, 35, 59, 0.15);
}
#contentDetailPost .close {
  font-size: 21px;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
}
#contentDetailPost img {
  vertical-align: middle;
  width: 100%;
}
img {
  border: 0;
}
.mpcm {
  opacity: 1;
  background-color: #f3f4f7;
  font-size: 12px;
  font-weight: normal;
  text-align: left;
  color: #5d6b88;
  padding: 10px 15px;
  word-wrap: break-word;
}
.info_mdl h4 {
  font-size: 15px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.07;
  letter-spacing: normal;
  text-align: left;
  color: #333b52;
  margin-top: 0;
  margin-bottom: 25px;
}
.row {
  margin-left: 0px;
  margin-right: 0px;
}
b,
strong {
  font-weight: 700;
}
.info_mdl .mpcl {
  font-size: 12px;
  text-align: left;
  color: #5d6b88;
  font-weight: bold;
}
.info_mdl .mpcr {
  text-align: right;
  font-size: 12px;
  color: #5d6b88;
}
</style>
