var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "funnel mb-4" }, [
    _vm._m(0),
    _c("div", { staticClass: "row funnel_row pl-2 pr-2" }, [
      _c(
        "div",
        {
          staticClass:
            "box_funnel_01 d-flex flex-column justify-content-between",
        },
        [
          _vm._m(1),
          _c(
            "div",
            {
              staticClass:
                "engagement-item-1 text-white d-flex flex-column justify-content-between",
            },
            [
              _c("div", { staticClass: "top" }, [
                _vm.funneldata.facebook.posts > 0
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "d-flex align-items-center justify-content-between mb-1 pb-1",
                      },
                      [
                        _vm._m(2),
                        _c("div", [
                          _c(
                            "span",
                            { staticClass: "font-weight-bold text-lsm" },
                            [_vm._v(_vm._s(_vm.funneldata.facebook.posts))]
                          ),
                        ]),
                      ]
                    )
                  : _vm._e(),
                _vm.funneldata.twitter.posts > 0
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "d-flex align-items-center justify-content-between mb-1 pb-1",
                      },
                      [
                        _vm._m(3),
                        _c("div", [
                          _c(
                            "span",
                            { staticClass: "font-weight-bold text-lsm" },
                            [_vm._v(_vm._s(_vm.funneldata.twitter.posts))]
                          ),
                        ]),
                      ]
                    )
                  : _vm._e(),
                _vm.funneldata.instagram.posts > 0
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "d-flex align-items-center justify-content-between mb-1 pb-1",
                      },
                      [
                        _vm._m(4),
                        _c("div", [
                          _c(
                            "span",
                            { staticClass: "font-weight-bold text-lsm" },
                            [_vm._v(_vm._s(_vm.funneldata.instagram.posts))]
                          ),
                        ]),
                      ]
                    )
                  : _vm._e(),
                _vm.funneldata.youtube.posts > 0
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "d-flex align-items-center justify-content-between mb-1 pb-1",
                      },
                      [
                        _vm._m(5),
                        _c("div", [
                          _c(
                            "span",
                            { staticClass: "font-weight-bold text-lsm" },
                            [_vm._v(_vm._s(_vm.funneldata.youtube.posts))]
                          ),
                        ]),
                      ]
                    )
                  : _vm._e(),
                _vm.funneldata.linkedin.posts > 0
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "d-flex align-items-center justify-content-between",
                      },
                      [
                        _vm._m(6),
                        _c("div", [
                          _c(
                            "span",
                            { staticClass: "font-weight-bold text-lsm" },
                            [_vm._v(_vm._s(_vm.funneldata.linkedin.posts))]
                          ),
                        ]),
                      ]
                    )
                  : _vm._e(),
              ]),
              _c(
                "div",
                {
                  staticClass:
                    "bottom d-flex align-items-center justify-content-between",
                },
                [
                  _vm._m(7),
                  _c("div", [
                    _c("span", { staticClass: "font-weight-bold text-lsm" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.funneldata.facebook.posts +
                              _vm.funneldata.twitter.posts +
                              _vm.funneldata.instagram.posts +
                              _vm.funneldata.youtube.posts +
                              _vm.funneldata.linkedin.posts
                          ) +
                          " "
                      ),
                    ]),
                  ]),
                ]
              ),
            ]
          ),
        ]
      ),
      _c("div", { staticClass: "box_funnel_02" }, [
        _vm._m(8),
        _c("div", { staticClass: "position-relative" }, [
          _c("img", {
            attrs: {
              src: require("../../../assets/all-images/funnel.svg"),
              alt: "",
            },
          }),
          _c("div", { staticClass: "box_into_funnel d-flex text-white" }, [
            _c("div", { staticClass: "bx_ b_a" }, [
              _c("div", { staticClass: "xengagement-item-2" }, [
                _c(
                  "div",
                  { staticClass: "middle" },
                  [
                    _vm._m(9),
                    _c("cp-icons", {
                      attrs: {
                        "value-facebook": _vm.funneldata.facebook.impresions,
                        "value-twitter": _vm.funneldata.twitter.impresions,
                        "value-instagram": _vm.funneldata.instagram.impresions,
                        "value-youtube": _vm.funneldata.youtube.impresions,
                        "value-linkedin": _vm.funneldata.linkedin.impresions,
                      },
                    }),
                    _c("h2", { staticClass: "informacion text-lsm" }, [
                      _vm._v(" Impressions "),
                    ]),
                    _c("h1", { staticClass: "text-3-5xl font-weight-bold" }, [
                      _c("span", { attrs: { title: "0" } }, [
                        _vm._v(
                          _vm._s(
                            _vm.numFormatter(
                              _vm.funneldata.facebook.impresions +
                                _vm.funneldata.twitter.impresions +
                                _vm.funneldata.instagram.impresions +
                                _vm.funneldata.youtube.impresions +
                                _vm.funneldata.linkedin.impresions
                            )
                          ) + " "
                        ),
                      ]),
                    ]),
                    _c("h3", { staticClass: "text-xxs" }, [_vm._v("Total")]),
                  ],
                  1
                ),
              ]),
            ]),
            _c("div", { staticClass: "bx_ b_b" }, [
              _c("div", { staticClass: "xengagement-item-3" }, [
                _c(
                  "div",
                  { staticClass: "middle" },
                  [
                    _vm._m(10),
                    _c("cp-icons", {
                      attrs: {
                        "value-facebook": _vm.funneldata.facebook.interactions,
                        "value-twitter": _vm.funneldata.twitter.interactions,
                        "value-instagram":
                          _vm.funneldata.instagram.interactions,
                        "value-youtube": _vm.funneldata.youtube.interactions,
                        "value-linkedin": _vm.funneldata.linkedin.interactions,
                      },
                    }),
                    _c(
                      "div",
                      { staticClass: "d-flex containerInteractions" },
                      [
                        _c("h2", { staticClass: "informacion text-lsm" }, [
                          _vm._v("Interactions"),
                        ]),
                        _c("i", {
                          staticClass: "fa fa-info-circle iconInfo",
                          attrs: {
                            id: "interactions-b_b",
                            "aria-hidden": "true",
                          },
                        }),
                        _c("CpTooltipDoubleCustom", {
                          attrs: {
                            target: "interactions-b_b",
                            tooltipTitle: "interactions-b_b",
                            title:
                              "All Campaign actions and Post Interactions were added.",
                            subTitle1: "",
                            subTitle2:
                              "Facebook: Reacts + comments + shares Twitter: Fav + comments Instagram: Likes + comments Linkedin: Comments + Reacts",
                            heightSubTitle1: "60px",
                            heightSubTitle2: "86px",
                          },
                        }),
                      ],
                      1
                    ),
                    _c("h1", { staticClass: "text-3-5xl font-weight-bold" }, [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.numFormatter(
                              _vm.funneldata.facebook.interactions +
                                _vm.funneldata.twitter.interactions +
                                _vm.funneldata.instagram.interactions +
                                _vm.funneldata.youtube.interactions +
                                _vm.funneldata.linkedin.interactions
                            )
                          )
                        ),
                      ]),
                    ]),
                    _c("h3", { staticClass: "text-xxs" }, [_vm._v("Total")]),
                  ],
                  1
                ),
              ]),
            ]),
            _c("div", { staticClass: "bx_ b_c" }, [
              _c("div", { staticClass: "xengagement-item-4" }, [
                _c(
                  "div",
                  { staticClass: "middle" },
                  [
                    _vm._m(11),
                    _c("cp-icons", {
                      attrs: {
                        "value-facebook": _vm.funneldata?.facebook?.addons,
                        "value-twitter": _vm.funneldata?.twitter?.addons,
                        "value-instagram": _vm.funneldata?.instagram?.addons,
                        "value-youtube": _vm.funneldata?.youtube?.addons,
                        "value-linkedin": _vm.funneldata?.linkedin?.addons,
                        "value-direct": _vm.funneldata?.direct?.addons,
                        "is-addon-visit": true,
                      },
                    }),
                    _c("h2", { staticClass: "text-lsm mt-0 mb-0" }, [
                      _vm._v("Addon Visits"),
                    ]),
                    _c(
                      "h1",
                      { staticClass: "text-3-5xl font-weight-bold mb-1" },
                      [
                        _vm.funneldata.facebook.addons +
                          _vm.funneldata.twitter.addons +
                          _vm.funneldata.instagram.addons +
                          _vm.funneldata.youtube.addons +
                          _vm.funneldata.linkedin.addons >
                        0
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.numFormatter(
                                    _vm.funneldata.facebook.addons +
                                      _vm.funneldata.twitter.addons +
                                      _vm.funneldata.instagram.addons +
                                      _vm.funneldata.youtube.addons +
                                      _vm.funneldata.linkedin.addons +
                                      _vm.funneldata.direct.addons
                                  )
                                ) + " "
                              ),
                            ])
                          : _vm._e(),
                        _vm.funneldata.facebook.addons +
                          _vm.funneldata.twitter.addons +
                          _vm.funneldata.instagram.addons +
                          _vm.funneldata.youtube.addons +
                          _vm.funneldata.linkedin.addons ==
                        0
                          ? _c("span", [_vm._v("-")])
                          : _vm._e(),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "d-flex flex-column align-items-center" },
                      [
                        true
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "pl-3 pr-3 w-75 d-flex align-items-center justify-content-between mb-0",
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "addonmegat text-xxs" },
                                  [_vm._v("Facebook")]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "addonmegav text-xxs font-weight-bold",
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(_vm.funneldata.facebook.addons)
                                      ),
                                    ]),
                                  ]
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm.funneldata.twitter.addons > 0
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "pl-3 pr-3 w-75 d-flex align-items-center justify-content-between mb-0",
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "addonmegat text-xxs" },
                                  [_vm._v("Twitter")]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "addonmegav text-xxs font-weight-bold",
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(_vm.funneldata.twitter.addons)
                                      ),
                                    ]),
                                  ]
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm.funneldata.youtube.addons > 0
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "pl-3 pr-3 w-75 d-flex align-items-center justify-content-between mb-0",
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "addonmegat text-xxs" },
                                  [_vm._v("Youtube")]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "addonmegav text-xxs font-weight-bold",
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(_vm.funneldata.youtube.addons)
                                      ),
                                    ]),
                                  ]
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm.funneldata.linkedin.addons > 0
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "pl-3 pr-3 w-75 d-flex align-items-center justify-content-between mb-0",
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "addonmegat text-xxs" },
                                  [_vm._v("Linkedin")]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "addonmegav text-xxs font-weight-bold",
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(_vm.funneldata.linkedin.addons)
                                      ),
                                    ]),
                                  ]
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm.funneldata.instagram.addons > 0
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "pl-3 pr-3 w-75 d-flex align-items-center justify-content-between mb-0",
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "addonmegat text-xxs" },
                                  [_vm._v("Instagram")]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "addonmegav text-xxs font-weight-bold",
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(_vm.funneldata.instagram.addons)
                                      ),
                                    ]),
                                  ]
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm.funneldata.facebook.addons > 0
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "pl-3 pr-3 w-75 d-flex align-items-center justify-content-between mb-0",
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "addonmegat text-xxs" },
                                  [_vm._v("Direct")]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "addonmegav text-xxs font-weight-bold",
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(_vm.funneldata.direct.addons) +
                                          " "
                                      ),
                                    ]),
                                  ]
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
      _c(
        "div",
        {
          staticClass:
            "box_funnel_03 d-flex flex-column justify-content-between",
        },
        [
          _vm._m(12),
          _c(
            "div",
            {
              staticClass:
                "engagement-item-6 text-white d-flex flex-column justify-content-between",
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "middle d-flex flex-column justify-content-center align-items-center",
                },
                [
                  _c("span", { staticClass: "text-lsm" }, [
                    _vm._v(" Results "),
                  ]),
                  _c(
                    "span",
                    { staticClass: "mt-0 mb-1 text-3-5xl font-weight-bold" },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.funneldata.facebook.new_audience +
                            _vm.funneldata.twitter.new_audience +
                            _vm.funneldata.instagram.new_audience +
                            _vm.funneldata.youtube.new_audience +
                            _vm.funneldata.linkedin.new_audience
                        ) + " "
                      ),
                    ]
                  ),
                  _c("div", { staticClass: "funnel-data w-100 flex-column" }, [
                    _vm.funneldata.facebook.posts > 0
                      ? _c("div", { staticClass: "funnel-data-result" }, [
                          _c("span", { staticClass: "text-xxs" }, [
                            _vm._v("New Facebook Followers"),
                          ]),
                          _c(
                            "span",
                            { staticClass: "text-xxs font-weight-bold" },
                            [
                              _vm._v(
                                _vm._s(_vm.funneldata.facebook.new_audience)
                              ),
                            ]
                          ),
                        ])
                      : _vm._e(),
                    _vm.funneldata.twitter.posts > 0
                      ? _c("div", { staticClass: "funnel-data-result" }, [
                          _c("span", { staticClass: "text-xxs" }, [
                            _vm._v("New Twitter Followers"),
                          ]),
                          _c(
                            "span",
                            { staticClass: "text-xxs font-weight-bold" },
                            [
                              _vm._v(
                                _vm._s(_vm.funneldata.twitter.new_audience)
                              ),
                            ]
                          ),
                        ])
                      : _vm._e(),
                    _vm.funneldata.instagram.posts > 0
                      ? _c("div", { staticClass: "funnel-data-result" }, [
                          _c("span", { staticClass: "text-xxs" }, [
                            _vm._v("New Instagram Followers"),
                          ]),
                          _c(
                            "span",
                            { staticClass: "text-xxs font-weight-bold" },
                            [
                              _vm._v(
                                _vm._s(_vm.funneldata.instagram.new_audience)
                              ),
                            ]
                          ),
                        ])
                      : _vm._e(),
                    _vm.funneldata.youtube.posts > 0
                      ? _c("div", { staticClass: "funnel-data-result" }, [
                          _c("span", { staticClass: "text-xxs" }, [
                            _vm._v("New YouTube Subscribers"),
                          ]),
                          _c(
                            "span",
                            { staticClass: "text-xxs font-weight-bold" },
                            [
                              _vm._v(
                                _vm._s(_vm.funneldata.youtube.new_audience)
                              ),
                            ]
                          ),
                        ])
                      : _vm._e(),
                    _vm.funneldata.linkedin.posts > 0
                      ? _c("div", { staticClass: "funnel-data-result" }, [
                          _c("span", { staticClass: "text-xxs" }, [
                            _vm._v("New LinkedIn Followers"),
                          ]),
                          _c(
                            "span",
                            { staticClass: "text-xxs font-weight-bold" },
                            [
                              _vm._v(
                                _vm._s(_vm.funneldata.linkedin.new_audience)
                              ),
                            ]
                          ),
                        ])
                      : _vm._e(),
                  ]),
                ]
              ),
            ]
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticStyle: {
          "text-align": "left",
          "margin-bottom": "20px",
          "margin-top": "0",
        },
      },
      [
        _c("div", { staticClass: "elti1" }, [
          _vm._v("Social Media Conversion Funnel"),
        ]),
        _c("div", { staticClass: "elti1sub" }, [
          _vm._v("The best way to turn strangers into customers"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "mb-2 text-center" }, [
      _c("span", { staticClass: "text-xs text-theme" }, [_vm._v("Actions")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("span", { staticClass: "text-lsm" }, [_vm._v("Facebook Posts")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("span", { staticClass: "text-lsm" }, [_vm._v("Tweets")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("span", { staticClass: "text-lsm" }, [_vm._v("Instagram Posts")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("span", { staticClass: "text-lsm" }, [_vm._v("Videos")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("span", { staticClass: "text-lsm" }, [_vm._v("LinkedIn Posts")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("span", { staticClass: "font-weight-bold text-lsm" }, [
        _vm._v("Total"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "mb-2 text-center" }, [
      _c("span", { staticClass: "text-xs text-theme" }, [_vm._v("Conversion")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "icon text-2xl" }, [
      _c("i", { staticClass: "fa fa-eye", attrs: { "aria-hidden": "true" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "icon text-2xl" }, [
      _c("i", {
        staticClass: "fa fa-mouse-pointer",
        attrs: { "aria-hidden": "true" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "icon text-2xl" }, [
      _c("i", {
        staticClass: "fa fa-list-alt",
        attrs: { "aria-hidden": "true" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "mb-2 text-center" }, [
      _c("span", { staticClass: "text-xs text-theme" }, [
        _vm._v("Social Performance"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }