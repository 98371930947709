import axios from 'axios'
export const geoInterestByCountry = async ({ country, interests}) => {

    const query = {
        query: `query GetGeoInterests($interest_ids: [String], $country: String) {
            getGeoInterests(interest_ids: $interest_ids, country: $country) {
              items
              count
            }
          }`,
        variables:{
            "interest_ids": interests,
            "country": country
        }
      }
    const response= await axios.post(process.env.VUE_APP_API_GRAPHGQL_LISTENING,query,{
        headers:{
            'Content-Type': 'application/json',      
            'api_key':'a6e71e02cd9c6565c2ad2fbaf135bf64'
        }})
    return await response.data.data.getGeoInterests

}