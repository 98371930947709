var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "nameData-content my-5" }, [
    _c("div", { staticClass: "row text-sm px-5" }, [
      _c(
        "div",
        {
          staticClass: "col",
          staticStyle: { "margin-left": "40px", "margin-right": "70px" },
        },
        [
          _c(
            "strong",
            { staticClass: "text-primary d-inline-block mb-4 text-lg" },
            [_vm._v("Name your Campaign")]
          ),
          _c(
            "b-form-group",
            {
              attrs: {
                id: "input-group-account",
                label: "Account",
                "label-for": "input-account",
              },
            },
            [
              _c("b-form-select", {
                attrs: {
                  id: "input-account",
                  options: _vm.accounts,
                  size: "sm",
                  "value-field": "id",
                  "text-field": "title",
                },
                on: { change: _vm.selectedAccount },
                model: {
                  value: _vm.campaign.account_id,
                  callback: function ($$v) {
                    _vm.$set(_vm.campaign, "account_id", $$v)
                  },
                  expression: "campaign.account_id",
                },
              }),
            ],
            1
          ),
          _vm.pressButton && !_vm.$v.campaign.account_name.required
            ? _c("div", { staticClass: "message-alert" }, [
                _vm._v(" Account is required "),
              ])
            : _vm._e(),
          _c(
            "b-form-group",
            {
              attrs: {
                id: "input-group-name",
                label: "Name",
                "label-for": "input-name",
              },
            },
            [
              _c("b-form-input", {
                attrs: {
                  id: "input-name",
                  placeholder: "Enter name",
                  size: "sm",
                },
                model: {
                  value: _vm.campaign.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.campaign, "name", $$v)
                  },
                  expression: "campaign.name",
                },
              }),
            ],
            1
          ),
          _vm.pressButton && !_vm.$v.campaign.name.required
            ? _c("div", { staticClass: "message-alert" }, [
                _vm._v(" Name is required "),
              ])
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "col",
          staticStyle: { "margin-left": "40px", "margin-right": "70px" },
        },
        [
          _c(
            "strong",
            { staticClass: "text-primary d-inline-block mb-4 text-lg" },
            [_vm._v(" Date Range ")]
          ),
          _c(
            "b-form-group",
            {
              attrs: {
                id: "input-group-startDate",
                label: "Start Date",
                "label-for": "input-startDate",
              },
            },
            [
              _c("b-form-datepicker", {
                staticClass: "mb-2",
                attrs: { id: "input-startDate", size: "sm" },
                model: {
                  value: _vm.campaign.dates.start,
                  callback: function ($$v) {
                    _vm.$set(_vm.campaign.dates, "start", $$v)
                  },
                  expression: "campaign.dates.start",
                },
              }),
            ],
            1
          ),
          _vm.pressButton && !_vm.$v.campaign.dates.start.required
            ? _c("div", { staticClass: "message-alert" }, [
                _vm._v(" Start Date is required "),
              ])
            : _vm._e(),
          _c(
            "b-form-group",
            {
              attrs: {
                id: "input-group-endDate",
                label: "End Date",
                "label-for": "input-endDate",
              },
            },
            [
              _c("b-form-datepicker", {
                staticClass: "mb-2",
                attrs: { id: "input-endDate", size: "sm" },
                model: {
                  value: _vm.campaign.dates.end,
                  callback: function ($$v) {
                    _vm.$set(_vm.campaign.dates, "end", $$v)
                  },
                  expression: "campaign.dates.end",
                },
              }),
            ],
            1
          ),
          _vm.pressButton && !_vm.$v.campaign.dates.end.required
            ? _c("div", { staticClass: "message-alert" }, [
                _vm._v(" End Date is required "),
              ])
            : _vm._e(),
          !_vm.isValidateDate
            ? _c("div", { staticClass: "message-alert" }, [
                _vm._v(" Invalid Date "),
              ])
            : _vm._e(),
        ],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "buttons-content mt-5" },
      [
        _c(
          "b-button",
          {
            staticClass: "px-4 mx-4 shadow-sm",
            attrs: { type: "button", variant: "mainbg" },
            on: {
              click: function ($event) {
                return _vm.closeModal()
              },
            },
          },
          [_vm._v("Prev")]
        ),
        _c(
          "b-button",
          {
            staticClass: "px-4 mx-4 shadow-sm",
            attrs: { type: "button", variant: "primary" },
            on: {
              click: function ($event) {
                return _vm.nextBudget()
              },
            },
          },
          [_vm._v("Next")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }