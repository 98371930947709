<template>
  <div class="interactiones">
    <div class="interactionGeneral__box">
      <div
        class="interactionGeneral__box--line"
        v-for="(item, index) in reactions"
        :key="index"
      >
        <div class="interactionGeneral__box--line__ico">
          <img :src="item.ico" class="h-5 mr-1" />
          <span>{{ (item.name === "like" ? "Like" : item.name) }}</span>
        </div>
        <div class="interactionGeneral__box--line__range">
          <div class="interactionGeneral__box--line__range--baseLine">
            <div
              class="interactionGeneral__box--line__range--baseLine__width"
              :style="{ width: `${item.pctValue}%` }"
            ></div>
          </div>
        </div>
        <div class="interactionGeneral__box--line__count">
          <span>{{ item.val }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Chart } from "highcharts-vue";
import Highcharts from "highcharts";
import exportingInit from "highcharts/modules/exporting";
import stockInit from "highcharts/modules/stock";
import { Helpers } from "@isc/styleguide";

stockInit(Highcharts);
exportingInit(Highcharts);

export default {
  name: "reactionFacebook",
  components: {
    highcharts: Chart,
  },
  data() {
    return {
      chartOptions: {},
      reactions: [],
    };
  },

  props: {
    grafico: Array,
  },
  methods: {
    Generate(objReactFb) {
      const maxValue = Helpers.getMaxValueObject(objReactFb);
      this.reactions = [
        {
          name: "like",
          ico: require("@/assets/all-images/reactions/like.svg"),
          val: objReactFb.like,
          pctValue: Helpers.convertToPercentage(objReactFb.like, maxValue),
        },
        {
          name: "Haha",
          ico: require("@/assets/all-images/reactions/haha.svg"),
          val: objReactFb.haha,
          pctValue: Helpers.convertToPercentage(objReactFb.haha, maxValue),
        },
        {
          name: "Wow",
          ico: require("@/assets/all-images/reactions/wow.svg"),
          val: objReactFb.wow,
          pctValue: Helpers.convertToPercentage(objReactFb.wow, maxValue),
        },
        {
          name: "Love",
          ico: require("@/assets/all-images/reactions/love.svg"),
          val: objReactFb.love,
          pctValue: Helpers.convertToPercentage(objReactFb.love, maxValue),
        },
        {
          name: "Sad",
          ico: require("@/assets/all-images/reactions/sad.svg"),
          val: objReactFb.sad,
          pctValue: Helpers.convertToPercentage(objReactFb.sad, maxValue),
        },
        {
          name: "Angry",
          ico: require("@/assets/all-images/reactions/angry.svg"),
          val: objReactFb.angry,
          pctValue: Helpers.convertToPercentage(objReactFb.angry, maxValue),
        },
      ];
    },
  },
};
</script>
