<template>
  <div class="interactiones">
    <div class="interactionGeneral__box">
      <div
        class="interactionGeneral__box--line"
        v-for="(item, index) in reactions"
        :key="index"
      >
        <div class="interactionGeneral__box--line__ico">
          <i class="mr-1" :class="[item.ico]"></i>
          <span class="text-capitalize">{{ item.name }}</span>
        </div>
        <div class="interactionGeneral__box--line__range">
          <div class="interactionGeneral__box--line__range--baseLine">
            <div
              class="interactionGeneral__box--line__range--baseLine__width"
              :style="{ width: `${item.pctValue}%` }"
            ></div>
          </div>
        </div>
        <div class="interactionGeneral__box--line__count">
          <span>{{ item.val }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Chart } from "highcharts-vue";
import Highcharts from "highcharts";
import exportingInit from "highcharts/modules/exporting";
import stockInit from "highcharts/modules/stock";
import { Helpers } from "@isc/styleguide";

stockInit(Highcharts);
exportingInit(Highcharts);

export default {
  name: "interactions",
  components: {
    highcharts: Chart,
  },
  data() {
    return {
      chartOptions: {},
      reactions: [],
    };
  },

  props: {
    grafico: Array,
  },
  methods: {
    Generate(obj) {
      const maxValue = Helpers.getMaxValueObject(obj);

      this.reactions = [
        {
          name: "Likes",
          ico: "far fa-thumbs-up",
          val: obj.like,
          pctValue: Helpers.convertToPercentage(obj.like, maxValue),
        },
        {
          name: "Celebrate",
          ico: "fas fa-sign-language",
          val: obj.celebrate,
          pctValue: Helpers.convertToPercentage(obj.celebrate, maxValue),
        },
        /* {
          name: "Curious",
          ico: "fas fa-surprise",
          val: obj.curious,
          pctValue: Helpers.convertToPercentage(obj.curious, maxValue),
        }, */
        {
          name: "Funny",
          ico: "fas fa-laugh",
          val: obj.curious,
          pctValue: Helpers.convertToPercentage(obj.curious, maxValue),
        },
        {
          name: "love",
          ico: "far fa-heart",
          val: obj.love,
          pctValue: Helpers.convertToPercentage(obj.love, maxValue),
        },
        {
          name: "Insightful",
          ico: "far fa-lightbulb",
          val: obj.insightful,
          pctValue: Helpers.convertToPercentage(obj.insightful, maxValue),
        },
        {
          name: "Support",
          ico: "fas fa-hand-holding-heart",
          val: obj.support,
          pctValue: Helpers.convertToPercentage(obj.support, maxValue),
        },
      ];
    },
  },
};
</script>
