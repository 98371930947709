var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "budgettype-content" }, [
    _vm._m(0),
    _c("div", { staticClass: "row px-5" }, [
      _c(
        "div",
        { staticClass: "col-4" },
        [
          _c(
            "b-form-group",
            {
              attrs: {
                id: "input-group-currency",
                label: "Currency",
                "label-for": "input-currency",
              },
            },
            [
              _c("CpTooltipWhiteCustom", {
                attrs: {
                  target: "input-group-currency",
                  message:
                    "The values entered for the campaign are merely informative",
                },
              }),
              _c("b-form-select", {
                attrs: {
                  id: "input-currency",
                  options: _vm.currencies,
                  "value-field": "id_moneda",
                  "text-field": "cod_moneda",
                  size: "sm",
                },
                on: { change: _vm.selectedCurrency },
                model: {
                  value: _vm.campaign.idcurrency,
                  callback: function ($$v) {
                    _vm.$set(_vm.campaign, "idcurrency", $$v)
                  },
                  expression: "campaign.idcurrency",
                },
              }),
            ],
            1
          ),
          _vm.pressButton && !_vm.$v.campaign.currency.required
            ? _c("div", { staticClass: "message-alert" }, [
                _vm._v(" Currency is required "),
              ])
            : _vm._e(),
          _c(
            "b-form-group",
            {
              attrs: { id: "input-group-budget", "label-for": "input-budget" },
            },
            [
              _c("p", [
                _vm._v(" Budget "),
                _c(
                  "span",
                  [
                    _c("i", {
                      staticClass: "fa fa-info-circle text-sm text-skygray",
                      attrs: { id: "budgetTooltip" },
                    }),
                    _c("CpTooltipWhiteCustom", {
                      attrs: {
                        target: "budgetTooltip",
                        title: "Budget",
                        message: "ISC Campaigns can only be organic.",
                        placement: "right",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("b-form-input", {
                attrs: {
                  id: "input-budget",
                  placeholder: "0.00",
                  type: "number",
                  size: "sm",
                },
                model: {
                  value: _vm.campaign.budget,
                  callback: function ($$v) {
                    _vm.$set(_vm.campaign, "budget", $$v)
                  },
                  expression: "campaign.budget",
                },
              }),
            ],
            1
          ),
          _vm.pressButton && !_vm.$v.campaign.budget.required
            ? _c("div", { staticClass: "message-alert" }, [
                _vm._v(" Budget is required "),
              ])
            : _vm._e(),
          _vm.pressButton && !_vm.$v.campaign.budget.decimal
            ? _c("div", { staticClass: "message-alert" }, [
                _vm._v(" Budget should be a number "),
              ])
            : _vm._e(),
          _vm.pressButton && !_vm.$v.campaign.budget.minValue
            ? _c("div", { staticClass: "message-alert" }, [
                _vm._v(" Budget should be a positive quantity "),
              ])
            : _vm._e(),
        ],
        1
      ),
      _c("div", { staticClass: "col-1" }),
      _c(
        "div",
        { staticClass: "col-7" },
        [
          _c("div", { staticClass: "d-flex justify-content-around mb-4" }, [
            _c(
              "span",
              {
                staticClass: "span-type-campaign audienceType",
                class: { "span-choosen": _vm.audienceChoosen },
                on: { click: _vm.chooseAudience },
              },
              [_vm._v("Audience")]
            ),
            _c(
              "span",
              {
                staticClass: "span-type-campaign leadType",
                class: { "span-choosen": _vm.leadsChoosen },
                on: { click: _vm.chooseLeads },
              },
              [_vm._v("Leads")]
            ),
          ]),
          _vm.audienceChoosen
            ? _c("goals-list", { attrs: { campaign: _vm.campaign } })
            : _vm._e(),
          _vm.leadsChoosen
            ? _c("leads", { attrs: { campaign: _vm.campaign } })
            : _vm._e(),
          _vm.pressButton && !_vm.$v.campaign.goals.required
            ? _c("div", { staticClass: "message-alert" }, [
                _vm._v(" Goal is required "),
              ])
            : _vm._e(),
        ],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "buttons-content mt-5" },
      [
        _c(
          "b-button",
          {
            staticClass: "px-4 mx-4 shadow-sm",
            attrs: { type: "button", variant: "mainbg" },
            on: {
              click: function ($event) {
                return _vm.goToNameData()
              },
            },
          },
          [_vm._v("Prev")]
        ),
        _c(
          "b-button",
          {
            staticClass: "px-4 mx-4 shadow-sm",
            attrs: { type: "button", variant: "primary" },
            on: {
              click: function ($event) {
                return _vm.nextConfirmation()
              },
            },
          },
          [_vm._v("Next")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row my-5 px-5" }, [
      _c("div", { staticClass: "col-4" }, [
        _c("strong", { staticClass: "text-primary d-inline-block text-lg" }, [
          _vm._v("Set Budget"),
        ]),
      ]),
      _c("div", { staticClass: "col-8" }, [
        _c("strong", { staticClass: "text-primary d-inline-block text-lg" }, [
          _vm._v("Choose your Campaign Type "),
          _c("br"),
          _c("span", { staticClass: "text-xs" }, [
            _vm._v("*Only 1 type can be chosen"),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }