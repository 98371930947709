var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "w-100 mb-4" }, [
    _c("div", { staticClass: "w-100 mb-4" }, [
      _c("div", { staticClass: "text-lg text-secondary" }, [
        _c("strong", [_vm._v("Campaign scorecards: ")]),
        _c("span", [_vm._v("How much efficient are you?")]),
        _c(
          "span",
          { staticClass: "text-secondary ml-1" },
          [
            _c("i", {
              staticClass: "fa fa-info-circle text-sm text-skygray",
              attrs: { id: "titleCampaign" },
            }),
            _c("CpTooltipWhiteCustom", {
              attrs: {
                target: "titleCampaign",
                title: "Tooltip title",
                message:
                  "This section will compare active and history campaigns at the time of the query.",
                "padding-container": "16px 12px 23px 10px",
              },
            }),
          ],
          1
        ),
      ]),
    ]),
    _c("div", { staticClass: "w-100 flex-between text-xs" }, [
      _c("div", { staticClass: "detail_block text-center" }, [
        _c(
          "p",
          { staticClass: "font-weight-bold text-lsm mb-2 text-secondary" },
          [_vm._v("Campaigns")]
        ),
        _vm._m(0),
        _c(
          "div",
          {
            staticClass:
              "flex-column flex-center h-40 bg-primary rounded text-white",
          },
          [
            _c("p", { staticClass: "text-3-5xl font-weight-bold" }, [
              _vm._v(_vm._s(_vm.obj.cantcampanias)),
            ]),
            _c("p", { staticClass: "mb-4 text-xs" }, [_vm._v("In date Range")]),
          ]
        ),
      ]),
      _c("div", { staticClass: "detail_block text-center" }, [
        _c(
          "p",
          { staticClass: "font-weight-bold text-lsm mb-2 text-secondary" },
          [_vm._v("Impressions")]
        ),
        _vm._m(1),
        _c(
          "div",
          {
            staticClass:
              "flex-column flex-center h-40 bg-white rounded position-relative",
          },
          [
            _c(
              "p",
              { staticClass: "text-3-5xl text-primary font-weight-bold" },
              [
                _vm._v(
                  " " +
                    _vm._s(_vm.addCharacter(_vm.obj.total_impresiones)) +
                    " "
                ),
              ]
            ),
            _c("p", { staticClass: "mb-4 text-xs text-color-text text-xs" }, [
              _vm._v("In date Range"),
            ]),
            _c(
              "div",
              {
                staticClass:
                  "w-100 bg-gray position-absolute bottom-0 flex-around pb-1",
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "p-2 m bg-mainbg rounded",
                    staticStyle: { width: "47.5%", "margin-left": "1.5px" },
                  },
                  [
                    _c("p", { staticClass: "color-secondary" }, [
                      _c("strong", [
                        _vm._v(
                          _vm._s(_vm.addCharacter(_vm.obj.paid_impressions))
                        ),
                      ]),
                    ]),
                    _c(
                      "p",
                      {
                        staticClass:
                          "text-overflow-dots text-color-text text-xxs",
                      },
                      [_vm._v(" Paid Impressions ")]
                    ),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "p-2 bg-mainbg rounded",
                    staticStyle: { width: "47.5%", "margin-right": "1.5px" },
                  },
                  [
                    _c("p", { staticClass: "color-secondary text-xxs" }, [
                      _c("strong", [
                        _vm._v(
                          _vm._s(_vm.addCharacter(_vm.obj.organic_impressions))
                        ),
                      ]),
                    ]),
                    _c(
                      "p",
                      {
                        staticClass:
                          "text-overflow-dots text-color-text text-xxs",
                      },
                      [_vm._v(" Organic Impressions ")]
                    ),
                  ]
                ),
              ]
            ),
          ]
        ),
      ]),
      _c("div", { staticClass: "detail_block text-center" }, [
        _c(
          "p",
          { staticClass: "font-weight-bold text-lsm mb-2 text-secondary" },
          [_vm._v(" Interactions ")]
        ),
        _vm._m(2),
        _c(
          "div",
          {
            staticClass:
              "flex-column flex-center h-40 bg-white rounded position-relative",
          },
          [
            _c(
              "p",
              { staticClass: "text-3-5xl text-primary font-weight-bold" },
              [
                _vm._v(
                  " " +
                    _vm._s(_vm.addCharacter(_vm.obj.totalinteractions)) +
                    " "
                ),
              ]
            ),
            _c("p", { staticClass: "mb-4 text-color-text text-xs" }, [
              _vm._v("In date Range"),
            ]),
            _c(
              "div",
              {
                staticClass:
                  "w-100 bg-gray position-absolute bottom-0 flex-around pb-1",
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "p-2 bg-mainbg rounded",
                    staticStyle: { width: "97%" },
                  },
                  [
                    _c("p", { staticClass: "text-secondary text-xxs" }, [
                      _c("strong", [
                        _vm._v(
                          " " +
                            _vm._s(
                              Math.round(_vm.obj.interactionPorc * 100).toFixed(
                                2
                              )
                            ) +
                            " %"
                        ),
                      ]),
                    ]),
                    _c(
                      "p",
                      {
                        staticClass:
                          "text-overflow-dots text-color-text text-xxs",
                      },
                      [_vm._v(" Interactions / Impressions ")]
                    ),
                  ]
                ),
              ]
            ),
          ]
        ),
      ]),
      _c("div", { staticClass: "detail_block text-center" }, [
        _c(
          "p",
          { staticClass: "font-weight-bold text-lsm text-secondary mb-2" },
          [_vm._v("Conversions")]
        ),
        _vm._m(3),
        _c(
          "div",
          { staticClass: "flex-column flex-center h-40 bg-white rounded" },
          [
            _c(
              "p",
              { staticClass: "text-3-5xl text-primary font-weight-bold" },
              [
                _vm._v(
                  " " + _vm._s(_vm.addCharacter(_vm.obj.t_conversions)) + " "
                ),
              ]
            ),
            _c("p", { staticClass: "mb-4 text-color-text text-xs" }, [
              _vm._v("In date Range"),
            ]),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "mb-2 text-color-text text-xs" }, [
      _vm._v(" How many campaigns "),
      _c("br"),
      _vm._v(" were executed? "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "mb-2 text-color-text text-xs" }, [
      _vm._v(" How many times were "),
      _c("br"),
      _vm._v(" my posts shown? "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "mb-2 text-xs text-color-text" }, [
      _vm._v(" How many times did people "),
      _c("br"),
      _vm._v(" interact with my posts? "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "mb-2 text-xs text-color-text" }, [
      _vm._v(" What milestones were "),
      _c("br"),
      _vm._v(" reached during the campaign? "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }