<template>
  <div class="interactiones">
    <div class="interactionGeneral__box">
      <div
        class="interactionGeneral__box--line"
        v-for="(item, index) in reactions"
        :key="index"
      >
        <div class="interactionGeneral__box--line__ico">
          <i class="mr-1" :class="[item.ico]"></i>
          <span class="text-capitalize">{{ item.name }}</span>
        </div>
        <div class="interactionGeneral__box--line__range">
          <div class="interactionGeneral__box--line__range--baseLine">
            <div
              class="interactionGeneral__box--line__range--baseLine__width"
              :style="{ width: `${item.pctValue}%` }"
            ></div>
          </div>
        </div>
        <div class="interactionGeneral__box--line__count">
          <span>{{ item.val }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Chart } from "highcharts-vue";
import Highcharts from "highcharts";
import exportingInit from "highcharts/modules/exporting";
import stockInit from "highcharts/modules/stock";
import { Helpers } from "@isc/styleguide";

stockInit(Highcharts);
exportingInit(Highcharts);

export default {
  name: "interactions",
  components: {
    highcharts: Chart,
  },
  data() {
    return {
      chartOptions: {},
      reactions: [],
    };
  },

  props: {
    grafico: Array,
  },
  mounted() {},
  methods: {
    Generate(obj) {
      const maxValue = Helpers.getMaxValueObject(obj);
      this.reactions = [
        {
          name: "likes",
          ico: "far fa-thumbs-up",
          val: obj.likes,
          pctValue: Helpers.convertToPercentage(obj.likes, maxValue)
        },
        {
          name: "shares",
          ico: "fas fa-share",
          val: obj.shares,
          pctValue: Helpers.convertToPercentage(obj.shares, maxValue)
        },
        {
          name: "retweets",
          ico: "fas fa-retweet",
          val: obj.retweets,
          pctValue: Helpers.convertToPercentage(obj.retweets, maxValue)
        },
        {
          name: "comments",
          ico: "far fa-comments",
          val: obj.comments,
          pctValue: Helpers.convertToPercentage(obj.comments, maxValue)
        },
        {
          name: "dislikes",
          ico: "far fa-thumbs-down",
          val: obj.dislikes,
          pctValue: Helpers.convertToPercentage(obj.dislikes, maxValue)
        },
      ];
    },
  },
};
</script>
