var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "div-goals" } },
    [
      _vm._m(0),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-4 pr-0" },
          [
            _c(
              "b-form-group",
              {
                attrs: {
                  id: "input-group-goal-type",
                  label: "",
                  "label-for": "input-goal-type",
                },
              },
              [
                _c("b-form-select", {
                  staticClass: "text-sm text-color-text",
                  attrs: {
                    id: "input-goal-type",
                    options: _vm.options,
                    "value-field": "idMetrica",
                    "text-field": "nombreMetrica",
                  },
                  on: {
                    change: function ($event) {
                      return _vm.selectMetric($event)
                    },
                  },
                  model: {
                    value: _vm.newGoalType,
                    callback: function ($$v) {
                      _vm.newGoalType = $$v
                    },
                    expression: "newGoalType",
                  },
                }),
              ],
              1
            ),
            !_vm.newGoalType && _vm.pressButton
              ? _c("span", { staticClass: "span-alert" }, [
                  _vm._v("Type Goal is required"),
                ])
              : _vm._e(),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-4 pr-0" },
          [
            _c(
              "b-form-group",
              {
                attrs: {
                  id: "input-group-goal-number",
                  label: "",
                  "label-for": "input-goal-number",
                },
              },
              [
                _c("b-form-input", {
                  staticClass: "text-sm text-color-text",
                  attrs: {
                    id: "input-goal-number",
                    min: "1",
                    label: "&nbsp;&nbsp;&nbsp;&nbsp;",
                    placeholder: "0.0",
                    type: "number",
                  },
                  model: {
                    value: _vm.newGoalNumber,
                    callback: function ($$v) {
                      _vm.newGoalNumber = $$v
                    },
                    expression: "newGoalNumber",
                  },
                }),
              ],
              1
            ),
            !_vm.newGoalNumber && _vm.pressButton
              ? _c("span", { staticClass: "span-alert" }, [
                  _vm._v("Amount of Audience is required"),
                ])
              : _vm._e(),
            _vm.newGoalNumber < 0
              ? _c("span", { staticClass: "span-alert" }, [
                  _vm._v("Amount of Audience must be positive"),
                ])
              : _vm._e(),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-4" },
          [
            _c(
              "b-button",
              {
                staticClass: "mb-4 pt-1 pb-1 pl-3 pr-3",
                attrs: { variant: "primary" },
                on: {
                  click: function ($event) {
                    return _vm.addGoal()
                  },
                },
              },
              [
                _c("i", { staticClass: "fa fa-plus text-sm" }),
                _c("span", { staticClass: "text-xs" }, [_vm._v("Add Goal")]),
              ]
            ),
          ],
          1
        ),
      ]),
      _c("div", [
        _c("table", { staticClass: "table table__goals" }, [
          _vm._m(1),
          _c("tbody", {
            ref: "contenedor",
            attrs: { id: "containerlistgoals" },
          }),
        ]),
        _vm.pressButton && _vm.campaign.goals.length == 0
          ? _c("div", { staticClass: "span-alert" }, [
              _vm._v(" You should need almost 1 goal "),
            ])
          : _vm._e(),
      ]),
      _c(
        "b-modal",
        {
          ref: "modal-edit-goal",
          attrs: { id: "modal-edit-goal", "hide-footer": "", size: "lg" },
          scopedSlots: _vm._u([
            {
              key: "modal-title",
              fn: function () {
                return [
                  _c(
                    "span",
                    { staticClass: "text-lg text-secondary font-weight-bold" },
                    [_vm._v(" Edit Goal ")]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("div", { staticClass: "d-block text-center" }, [
            _c(
              "div",
              {
                staticClass: "row px-5",
                staticStyle: { "padding-left": "1rem !important" },
              },
              [
                _c("div", { staticClass: "col-4" }),
                _c(
                  "div",
                  { staticClass: "col-4" },
                  [
                    _c(
                      "b-form-group",
                      {
                        attrs: {
                          id: "input-group-goal-type",
                          label: "Goal",
                          "label-for": "input-goal-type",
                        },
                      },
                      [
                        _c("b-form-input", {
                          attrs: {
                            id: "input-name-goal",
                            placeholder: "Lead",
                            type: "text",
                            size: "sm",
                          },
                          model: {
                            value: _vm.arrayGoalsExits.nameGoal,
                            callback: function ($$v) {
                              _vm.$set(_vm.arrayGoalsExits, "nameGoal", $$v)
                            },
                            expression: "arrayGoalsExits.nameGoal",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-form-group",
                      {
                        attrs: {
                          id: "input-group-name",
                          label: "Target",
                          "label-for": "input-name",
                        },
                      },
                      [
                        _c("b-form-input", {
                          attrs: {
                            id: "input-name",
                            placeholder: "0.0",
                            type: "number",
                            size: "sm",
                          },
                          model: {
                            value: _vm.arrayGoalsExits.newGoalNumber,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.arrayGoalsExits,
                                "newGoalNumber",
                                $$v
                              )
                            },
                            expression: "arrayGoalsExits.newGoalNumber",
                          },
                        }),
                        !_vm.arrayGoalsExits.newGoalNumber
                          ? _c("span", { staticClass: "span-alert" }, [
                              _vm._v("Number is required"),
                            ])
                          : _vm._e(),
                        _vm.arrayGoalsExits.newGoalNumber < 0
                          ? _c("span", { staticClass: "span-alert" }, [
                              _vm._v("Number must be positive"),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "b-button",
                      {
                        staticClass: "mt-3 bg-primary border-0",
                        attrs: { block: "" },
                        on: { click: _vm.saveEditGoals },
                      },
                      [
                        _c("span", { staticClass: "text-xs text-white" }, [
                          _vm._v("Send"),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
                _c("div", { staticClass: "col-4" }),
              ]
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "mb-2 pl-3" }, [
      _c("span", { staticClass: "text-sm text-secondary font-weight-bold" }, [
        _vm._v("Goal"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", { staticClass: "text-xs" }, [
        _c("th", [
          _c(
            "span",
            { staticClass: "font-weight-bold text-xs text-secondary" },
            [_vm._v("Type of goal")]
          ),
        ]),
        _c("th", [
          _c(
            "span",
            { staticClass: "font-weight-bold text-xs text-secondary" },
            [_vm._v("Number of goal")]
          ),
        ]),
        _c("th", [
          _c(
            "span",
            { staticClass: "font-weight-bold text-xs text-secondary" },
            [_vm._v("Action")]
          ),
        ]),
        _c("th", { staticStyle: { display: "none" } }, [_vm._v("id goal")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }