var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "bg-white w-full p-4 rounded flex-between flex-wrap" },
    [
      _c("div", { staticClass: "row col-12" }, [
        _c("div", { staticClass: "col-6" }, [
          _vm._m(0),
          _c(
            "div",
            [_c("highcharts", { attrs: { options: _vm.chartImpressions } })],
            1
          ),
        ]),
        _c("div", { staticClass: "col-6" }, [
          _vm._m(1),
          _c(
            "div",
            [_c("highcharts", { attrs: { options: _vm.chartInteractions } })],
            1
          ),
        ]),
      ]),
      _c("div", { staticClass: "row col-12" }, [
        _c("div", { staticClass: "col-6" }, [
          _vm._m(2),
          _c(
            "div",
            [_c("highcharts", { attrs: { options: _vm.chartConversions } })],
            1
          ),
        ]),
        _c("div", { staticClass: "col-6" }, [
          _vm._m(3),
          _c(
            "div",
            [
              _c("div", { attrs: { id: "div_ratechart" } }, [
                _c(
                  "span",
                  {
                    class: [
                      _vm.selecttype == "Audiencie" ? "ratec_active" : "",
                    ],
                    on: {
                      click: function ($event) {
                        return _vm.select("Audiencie")
                      },
                    },
                  },
                  [_vm._v("Audience")]
                ),
                _vm._v(" | "),
                _c(
                  "span",
                  {
                    class: [_vm.selecttype == "Leads" ? "ratec_active" : ""],
                    on: {
                      click: function ($event) {
                        return _vm.select("Leads")
                      },
                    },
                  },
                  [_vm._v("Leads")]
                ),
              ]),
              _c("highcharts", {
                attrs: { options: _vm.chartTypeConversions },
              }),
            ],
            1
          ),
        ]),
      ]),
      _c("div", { staticClass: "w-100 my-5 px-4" }, [
        _c("table", { staticClass: "table table-overview-general" }, [
          _vm._m(4),
          _c(
            "tbody",
            _vm._l(_vm.table, function (itemGraf) {
              return _c("tr", { key: itemGraf.id_campania }, [
                _c(
                  "td",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.visualizar(
                          itemGraf.id_campania,
                          itemGraf.fec_inicio_campania,
                          itemGraf.fec_fin_campania
                        )
                      },
                    },
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "text-xs text-color-text font-weight-bold",
                      },
                      [_vm._v(" " + _vm._s(itemGraf.nom_campania) + " ")]
                    ),
                  ]
                ),
                _c("td", [
                  _c("span", { staticClass: "text-xs text-color-text" }, [
                    _vm._v(
                      _vm._s(
                        _vm.transformDate(
                          itemGraf.fec_inicio_campania,
                          _vm.userInfo.TIME_ZONE,
                          "DD-MM-YYYY"
                        )
                      )
                    ),
                  ]),
                ]),
                _c("td", [
                  _c("span", { staticClass: "text-xs text-color-text" }, [
                    _vm._v(
                      _vm._s(
                        _vm.transformDate(
                          itemGraf.fec_fin_campania,
                          _vm.userInfo.TIME_ZONE,
                          "DD-MM-YYYY"
                        )
                      )
                    ),
                  ]),
                ]),
                _c("td", [
                  _c("span", { staticClass: "text-xs text-color-text" }, [
                    _vm._v(
                      _vm._s(
                        itemGraf.type === "Audiencie"
                          ? "Audience"
                          : itemGraf.type
                      )
                    ),
                  ]),
                ]),
                _c("td", [
                  _c("span", { staticClass: "text-xs text-color-text" }, [
                    _vm._v(_vm._s(itemGraf.impressions)),
                  ]),
                ]),
                _c("td", [
                  _c("span", { staticClass: "text-xs text-color-text" }, [
                    _vm._v(_vm._s(itemGraf.interactions)),
                  ]),
                ]),
                _c("td", [
                  _c("span", { staticClass: "text-xs text-color-text" }, [
                    _vm._v(_vm._s(itemGraf.increaseaudience + " %")),
                  ]),
                ]),
                _c("td", [
                  _c("span", { staticClass: "text-xs text-color-text" }, [
                    _vm._v(_vm._s(itemGraf.tasacomments + " %")),
                  ]),
                ]),
              ])
            }),
            0
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c(
        "span",
        {
          staticClass:
            "d-block mb-4 font-weight-bold text-lsm text-secondary mt-3 mb-3",
        },
        [_vm._v("Top 5 campaigns by Impressions")]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c(
        "span",
        {
          staticClass:
            "d-block mb-4 font-weight-bold text-lsm text-secondary mt-3 mb-3",
        },
        [_vm._v("Top 5 campaigns by Interactions")]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c(
        "span",
        {
          staticClass:
            "d-block mb-4 font-weight-bold text-lsm text-secondary mt-3 mb-3",
        },
        [_vm._v("Top 5 campaigns by Total Conversion Rate")]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c(
        "span",
        {
          staticClass:
            "d-block mb-4 font-weight-bold text-lsm text-secondary mt-3 mb-3",
        },
        [_vm._v("Top 5 Campaigns by Conversion Rate and Type")]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", { staticClass: "border-bottom" }, [
        _c("td", { staticStyle: { width: "auto" } }, [
          _c("strong", { staticClass: "text-xs text-secondary" }, [
            _vm._v("Campaigns"),
          ]),
        ]),
        _c("td", { staticStyle: { width: "auto" } }, [
          _c("strong", { staticClass: "text-xs text-secondary" }, [
            _vm._v("Start Date"),
          ]),
        ]),
        _c("td", { staticStyle: { width: "auto" } }, [
          _c("strong", { staticClass: "text-xs text-secondary" }, [
            _vm._v("End Date"),
          ]),
        ]),
        _c("td", { staticStyle: { width: "auto" } }, [
          _c("strong", { staticClass: "text-xs text-secondary" }, [
            _vm._v("Type"),
          ]),
        ]),
        _c("td", { staticStyle: { width: "auto" } }, [
          _c("strong", { staticClass: "text-xs text-secondary" }, [
            _vm._v("Impressions"),
          ]),
        ]),
        _c("td", { staticStyle: { width: "auto" } }, [
          _c("strong", { staticClass: "text-xs text-secondary" }, [
            _vm._v("Interactions"),
          ]),
        ]),
        _c("td", { staticStyle: { width: "auto" } }, [
          _c("strong", { staticClass: "text-xs text-secondary" }, [
            _vm._v("Audience Growth"),
          ]),
        ]),
        _c("td", { staticStyle: { width: "auto" } }, [
          _c("strong", { staticClass: "text-xs text-secondary" }, [
            _vm._v("Feedback Rate"),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }