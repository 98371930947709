import Router from "vue-router";
import viewAll from "./views/All.vue"
import editCampaign from "@/components/all/edit-campaign/EditCampaign.vue"
import detailCampaign from "@/components/all/detail-campaign/Campaign.vue"
import generalOverview from "./views/GeneralOverview.vue"

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    //all zone rutes
    {
      path: "/campaigns/all",
      name: "campaigns-all",
      component: viewAll,
    },
    {
      path: "/campaign/edit/:key?",
      name: "edit-campaign",
      component: editCampaign
    },
    {
      path: "/campaign",
      name: "campaign-detail",
      component: detailCampaign
    },

    //general-verview routes
    {
      path: "/campaigns/general-overview",
      name: "general-overview",
      component: generalOverview
    },
  ],
});
