var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "w-100 bg-mainbg campaign-general-overview-wrap" },
    [
      _vm.visibilityLoader === true ? _c("loader-custom") : _vm._e(),
      _c("bread-crumb"),
      _c("div", { staticClass: "detail-content-box" }, [
        _c("div", { staticClass: "row mt-4 mb-4" }, [
          _c("div", { staticClass: "col-7 pl-0" }, [
            _c(
              "div",
              { staticClass: "d-flex align-items-center" },
              [
                _c("b-img", {
                  staticClass: "rounded-circle h-12 w-12 mr-2",
                  attrs: { src: this.imagen },
                }),
                _c(
                  "span",
                  {
                    staticClass: "font-weight-bold text-lg mr-2 text-secondary",
                  },
                  [_vm._v(_vm._s(this.nombreMarca) + " ")]
                ),
                _vm._l(_vm.consultas, function (item, index) {
                  return _c("div", { key: item + index }, [
                    item.idRedSocial == 1
                      ? _c("i", {
                          staticClass: "mr-2 fab fa-facebook text-facebook",
                          on: {
                            click: function ($event) {
                              return _vm.abrirpestania(item.dscUrlRedsocial)
                            },
                          },
                        })
                      : _vm._e(),
                    item.idRedSocial == 4
                      ? _c("i", {
                          staticClass: "mr-2 fab fa-youtube text-youtube",
                          on: {
                            click: function ($event) {
                              return _vm.abrirpestania(item.dscUrlRedsocial)
                            },
                          },
                        })
                      : _vm._e(),
                    item.idRedSocial == 3
                      ? _c("i", {
                          staticClass: "mr-2 fab fa-instagram text-instagram",
                          on: {
                            click: function ($event) {
                              return _vm.abrirpestania(item.dscUrlRedsocial)
                            },
                          },
                        })
                      : _vm._e(),
                    item.idRedSocial == 2
                      ? _c("i", {
                          staticClass: "mr-2 fab fa-twitter text-twitter",
                          on: {
                            click: function ($event) {
                              return _vm.abrirpestania(item.dscUrlRedsocial)
                            },
                          },
                        })
                      : _vm._e(),
                    item.idRedSocial == 5
                      ? _c("i", {
                          staticClass: "mr-2 fab fa-linkedin text-linkedin",
                          on: {
                            click: function ($event) {
                              return _vm.abrirpestania(item.dscUrlRedsocial)
                            },
                          },
                        })
                      : _vm._e(),
                  ])
                }),
              ],
              2
            ),
          ]),
          _c("div", { staticClass: "col-5 pr-0" }, [
            _c("div", { staticClass: "main-select" }, [
              _c(
                "div",
                { staticClass: "brand-select" },
                [
                  _c("multi-select-custom", {
                    staticClass: "selected-brand",
                    attrs: {
                      selected: _vm.selectedDefaultBrand,
                      options: _vm.getListBrands(_vm.marcas),
                    },
                    on: { selectValue: _vm.selectedBrand },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "coin-select" },
                [
                  _c("multi-select-custom", {
                    staticClass: "selected-coin",
                    attrs: {
                      selected: _vm.selectedDefaultCoin,
                      options: _vm.getListCoins(_vm.monedas),
                    },
                    on: { selectValue: _vm.selectedCoin },
                  }),
                ],
                1
              ),
            ]),
          ]),
        ]),
        _c("div", [_c("ScoreCards", { attrs: { obj: _vm.totalResumen } })], 1),
        _c(
          "div",
          [
            _c("ComparativeCards", {
              attrs: {
                mayor: this.campMayores.nom_campania,
                menor: this.campMenor.nom_campania,
                totalcomments: _vm.totalcomments,
                totalengage: _vm.totalengagement,
                totalnewaudience: _vm.totoalnewaudience,
              },
            }),
            _c("OverviewGraphs", { ref: "overviewgraphs" }),
          ],
          1
        ),
      ]),
      _c("IscModalAlerts", {
        attrs: {
          icon: _vm.icon,
          iconColor: _vm.iconColor,
          title: _vm.title,
          paragraph: _vm.paragraph,
          buttonMessage: _vm.buttonMessage,
          noCloseBackdrop: true,
          refresh: true,
        },
        on: { sendResult: _vm.resultModalAlerts },
        model: {
          value: _vm.showAlert,
          callback: function ($$v) {
            _vm.showAlert = $$v
          },
          expression: "showAlert",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }