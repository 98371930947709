var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.visibilityLoader === true ? _c("loader-custom") : _vm._e(),
      _c(
        "div",
        { staticClass: "row mt-4" },
        _vm._l(_vm.campaignsDet, function (itemsa, index) {
          return _c(
            "div",
            { key: itemsa.id_campania, staticClass: "col-3 px-2" },
            [
              _c("div", { staticClass: "d-flex rounded-6 flex-column mb-3" }, [
                _c("div", { staticClass: "position-relative cursor-pointer" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "position-absolute w-100 h-100 d-flex justify-content-center align-items-center eye z-20 rounded-top-6",
                      on: {
                        click: function ($event) {
                          return _vm.visualizar(
                            itemsa.id_campania,
                            itemsa.fec_inicio_campania,
                            itemsa.fec_fin_campania
                          )
                        },
                      },
                    },
                    [_c("i", { staticClass: "far fa-eye" })]
                  ),
                  _c(
                    "div",
                    {
                      class: [
                        "p-3 d-flex flex-column justify-content-between z-10 text-white rounded-top-6 campaign-box-item-center",
                        itemsa.flgEstado ? "bg-primary" : "bg-danger",
                      ],
                    },
                    [
                      _c("div", {}, [
                        _c(
                          "span",
                          { staticClass: "text-lsm font-weight-bold" },
                          [_vm._v(" " + _vm._s(itemsa.nom_campania) + " ")]
                        ),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex justify-content-between align-items-center",
                        },
                        [
                          _c("div", [
                            _c(
                              "p",
                              { staticClass: "text-3-5xl font-weight-bold" },
                              [_vm._v(" " + _vm._s(itemsa.totalnew) + " ")]
                            ),
                            itemsa.descripcionmetrica == "Leads"
                              ? _c("p", { staticClass: "text-xs" }, [
                                  _vm._v(" Addon Leads "),
                                ])
                              : _vm._e(),
                            itemsa.descripcionmetrica == "Audiencie"
                              ? _c("p", { staticClass: "text-xs" }, [
                                  _vm._v(" New Audience "),
                                ])
                              : _vm._e(),
                          ]),
                          _c(
                            "div",
                            {
                              on: {
                                change: function ($event) {
                                  return _vm.consultaPost(
                                    itemsa.id_campania,
                                    itemsa.fec_inicio_campania,
                                    itemsa.fec_fin_campania
                                  )
                                },
                              },
                            },
                            [
                              _c(
                                "div",
                                _vm._l(itemsa.rss, function (itemsRed, index) {
                                  return _c("span", { key: index }, [
                                    itemsRed == 1
                                      ? _c("i", {
                                          staticClass:
                                            "mr-2 text-sm fab fa-facebook texticon-facebook",
                                        })
                                      : _vm._e(),
                                    itemsRed == 4
                                      ? _c("i", {
                                          staticClass:
                                            "mr-2 text-sm fab fa-youtube texticon-youtube",
                                        })
                                      : _vm._e(),
                                    itemsRed == 3
                                      ? _c("i", {
                                          staticClass:
                                            "mr-2 text-sm fab fa-instagram texticon-instagram",
                                        })
                                      : _vm._e(),
                                    itemsRed == 2
                                      ? _c("i", {
                                          staticClass:
                                            "mr-2 text-sm fab fa-twitter texticon-twitter",
                                        })
                                      : _vm._e(),
                                    itemsRed == 5
                                      ? _c("i", {
                                          staticClass:
                                            "mr-2 text-sm fab fa-linkedin texticon-linkedin",
                                        })
                                      : _vm._e(),
                                  ])
                                }),
                                0
                              ),
                            ]
                          ),
                        ]
                      ),
                      _c("div", { staticClass: "text-center mt-3" }, [
                        itemsa.flgEstado == 1 ? _c("p") : _vm._e(),
                        itemsa.flgEstado == 0
                          ? _c(
                              "p",
                              { staticClass: "text-lsm font-weight-bold" },
                              [_vm._v(" FINISHED ")]
                            )
                          : _vm._e(),
                      ]),
                      _c("div", { staticClass: "text-center text-xs" }, [
                        _vm._v(" " + _vm._s(itemsa.marcas) + " "),
                      ]),
                    ]
                  ),
                ]),
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex justify-content-between bg-white text-center text-xs p-3 border-bottom",
                  },
                  [
                    _c("div", [
                      _c(
                        "p",
                        {
                          staticClass:
                            "font-weight-bold text-xs text-color-text",
                        },
                        [_vm._v("Start")]
                      ),
                      _c("p", { staticClass: "text-xs text-color-text" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.transformDate(
                                itemsa.fec_inicio_campania,
                                _vm.userInfo.TIME_ZONE,
                                "DD-MM-YYYY"
                              )
                            ) +
                            " "
                        ),
                      ]),
                    ]),
                    _c("div", [
                      _c(
                        "p",
                        {
                          staticClass:
                            "font-weight-bold text-xs text-color-text",
                        },
                        [_vm._v("End")]
                      ),
                      _c("p", { staticClass: "text-xs text-color-text" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.transformDate(
                                itemsa.fec_fin_campania,
                                _vm.userInfo.TIME_ZONE,
                                "DD-MM-YYYY"
                              )
                            ) +
                            " "
                        ),
                      ]),
                    ]),
                    _c("div", [
                      _c(
                        "p",
                        {
                          staticClass:
                            "font-weight-bold text-xs text-color-text",
                        },
                        [_vm._v("Status")]
                      ),
                      itemsa.flgEstado == 1
                        ? _c("p", { staticClass: "text-xs text-color-text" }, [
                            _vm._v(" Active "),
                          ])
                        : _vm._e(),
                      itemsa.flgEstado == 0
                        ? _c("p", { staticClass: "text-xs text-color-text" }, [
                            _vm._v(" Inactive "),
                          ])
                        : _vm._e(),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex justify-content-between bg-white text-center text-xs p-3",
                  },
                  [
                    _c("div", [
                      _c(
                        "p",
                        {
                          staticClass:
                            "font-weight-bold text-xs text-color-text",
                        },
                        [_vm._v("Actions")]
                      ),
                      _c("p", { staticClass: "text-xs text-color-text" }, [
                        _vm._v(_vm._s(itemsa.totalactions)),
                      ]),
                    ]),
                    _c("div", [
                      _c(
                        "p",
                        {
                          staticClass:
                            "font-weight-bold text-xs text-color-text",
                          attrs: { id: "type" },
                        },
                        [_vm._v(" Type ")]
                      ),
                      _c("p", { staticClass: "text-xs text-color-text" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              itemsa.descripcionmetrica === "Audiencie"
                                ? "Audience"
                                : itemsa.descripcionmetrica
                            ) +
                            " "
                        ),
                      ]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "d-flex flex-center" },
                      [
                        itemsa.flgEstado == 1
                          ? _c("i", {
                              staticClass: "circulo-verde",
                              attrs: { id: `circulo-verde-${index}` },
                            })
                          : _vm._e(),
                        _c("CpTooltipIconCustom", {
                          attrs: {
                            target: `circulo-verde-${index}`,
                            title: "Active Campaign",
                            messageTitle: "Active Campaign",
                            messageSubTitle: "Data updated every 30 minutes.",
                            colorIcon: "#9fd895",
                          },
                        }),
                        itemsa.flgEstado == 0
                          ? _c("i", {
                              staticClass: "circulo-rojo",
                              attrs: { id: `circulo-rojo-${index}` },
                            })
                          : _vm._e(),
                        _c("CpTooltipIconCustom", {
                          attrs: {
                            target: `circulo-rojo-${index}`,
                            title: "Finished Campaign",
                            messageTitle: "Finished Campaign",
                            messageSubTitle: "Data updated every 24 hours.",
                            colorIcon: "#ff7e7e",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _c(
                  "div",
                  {
                    class: [
                      "p-1 bg-white rounded-bottom button-list",
                      itemsa.flgEstado ? "flex-around" : "flex-center",
                    ],
                  },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "mr-2",
                        on: {
                          click: function ($event) {
                            return _vm.visualizar(
                              itemsa.id_campania,
                              itemsa.fec_inicio_campania,
                              itemsa.fec_fin_campania
                            )
                          },
                        },
                      },
                      [
                        _c("i", {
                          staticClass: "text-lg text-color-text fas fa-eye",
                        }),
                      ]
                    ),
                    itemsa.flgEstado
                      ? _c(
                          "router-link",
                          {
                            attrs: {
                              to: {
                                name: "edit-campaign",
                                params: { key: itemsa.id_campania },
                              },
                            },
                          },
                          [
                            _c("i", {
                              staticClass:
                                "text-lg text-color-text fas fa-edit",
                            }),
                          ]
                        )
                      : _c("a", { staticClass: "link--disabled" }, [
                          _c("i", { staticClass: "text-lg fas fa-edit" }),
                        ]),
                    _c("button", { staticClass: "ml-2" }, [
                      _c("i", {
                        staticClass: "text-lg text-color-text fas fa-trash-alt",
                        on: {
                          click: function ($event) {
                            return _vm.openModalDelete(
                              itemsa.id_campania,
                              itemsa.flgEstado,
                              itemsa.nom_campania
                            )
                          },
                        },
                      }),
                    ]),
                  ],
                  1
                ),
              ]),
            ]
          )
        }),
        0
      ),
      _c(
        "div",
        [
          _c("IscModalAlerts", {
            attrs: {
              icon: _vm.iconCode,
              iconColor: _vm.iconColorCode,
              title: _vm.titleCode,
              paragraph: _vm.paragraphCode,
              buttonMessage: _vm.buttonMessageCode,
              noCloseBackdrop: true,
              refresh: true,
            },
            on: { sendResult: _vm.resultModalAlertsCode },
            model: {
              value: _vm.showAlertCode,
              callback: function ($$v) {
                _vm.showAlertCode = $$v
              },
              expression: "showAlertCode",
            },
          }),
          _c("IscModalAlerts", {
            attrs: {
              icon: _vm.icon,
              iconColor: _vm.iconColor,
              title: _vm.title,
              paragraph: _vm.paragraph,
              buttonMessage: _vm.buttonMessage,
              refresh: true,
              noCloseBackdrop: true,
            },
            on: {
              sendResult: _vm.resultModalAlerts,
              input: _vm.closeIconModal,
            },
            model: {
              value: _vm.showAlert,
              callback: function ($$v) {
                _vm.showAlert = $$v
              },
              expression: "showAlert",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }