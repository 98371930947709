<template>
  <div id="posts-edit-content" class="p-4">
    <div class="mb-3">
      <span class="text-lsm text-secondary font-weight-bold mr-1">Posts of: </span>
      <span class="text-lg text-color-text">{{ campaign.name }}</span>
    </div>
    <table class="table">
      <thead>
        <tr>
          <th class="pt-2 pb-2"><span class="text-xs text-secondary font-weight-bold">Title Post</span></th>
          <th class="pt-2 pb-2"><span class="text-xs text-secondary font-weight-bold">Social Network</span></th>
          <th class="pt-2 pb-2"><span class="text-xs text-secondary font-weight-bold">Publish Date</span></th>
          <th class="pt-2 pb-2"><span class="text-xs text-secondary font-weight-bold">Delete Post</span></th>
        </tr>
      </thead>
      <tbody id="posts-container">
        <post-edit
          v-for="post in campaign.posts"
          :key="post.key"
          :post="post"
          @deletePost="deletePost"
        ></post-edit>
      </tbody>
    </table>

    <b-modal
      hide-footer
      title="Delete Post"
      ref="modal-delete-post"
    >
      <div class="d-block text-center">
        <h3>Are you sure that you want to delete this post?</h3>
      </div>
      <b-button class="mt-3" variant="outline-warning" block @click="hideModal()">Cancel</b-button>
      <b-button
        class="mt-2"
        variant="outline-danger"
        block
        @click="confirmDeletePost()"
        >Ok</b-button
      >
    </b-modal>
    <b-button id="button-go-campaigns" class="bg-primary border-0" @click="goCampaigns()"><span class="text-xs text-white">Go to all Campaigns</span></b-button>
  </div>
</template>
<script>
import PostEdit from "./PostEdit";
export default {
  name: "PostsEdit",
  props: ["campaign"],
  components: {
    PostEdit,
  },
  data(){
      return{
          postKeyDeleted: null,
      }
  },
  methods:{
      deletePost(key){
          this.postKeyDeleted = key
          this.$refs['modal-delete-post'].show()
      },
      hideModal(){
        this.$refs['modal-delete-post'].hide()
      },
      confirmDeletePost(){
         let newPosts = this.campaign.posts.filter((post) => {
              return post.key != this.postKeyDeleted
          })
        this.campaign.posts = newPosts
      
        let containerPosts = document.querySelector('#posts-container')
        let postDeleted = document.querySelector(`#${this.postKeyDeleted}`)
        containerPosts.removeChild(postDeleted)

        console.log(this.campaign)

        this.$refs['modal-delete-post'].hide()
      },
      goCampaigns(){
          this.$router.push('/campaigns/all')
      }
  }
};
</script>
<style scoped>

#button-go-campaigns{
    margin-left: 40%;
    margin-bottom: 30px;
}
</style>
