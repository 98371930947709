var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "audi" },
    [
      _vm.visibilityLoader === true ? _c("loader-custom") : _vm._e(),
      _c("div", {}, [
        _c(
          "div",
          { staticClass: "bw", staticStyle: { "margin-top": "40px" } },
          [
            _c("div", { staticClass: "col-md-12" }, [
              _c(
                "div",
                { staticClass: "bw" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex justify-content-between align-items-center mb-3",
                    },
                    [
                      _c("div", {}, [
                        _c(
                          "span",
                          {
                            staticClass:
                              "font-weight-bold text-capitalize text-lsm text-secondary mr-1",
                          },
                          [_vm._v(" Audience ")]
                        ),
                        _c(
                          "span",
                          { staticClass: "text-secondary" },
                          [
                            _c("i", {
                              staticClass:
                                "fa fa-info-circle text-sm text-skygray",
                              attrs: { id: "impact1" },
                            }),
                            _c("CpTooltipWhiteCustom", {
                              attrs: {
                                target: "impact1",
                                title: "Tooltip title",
                                message:
                                  "Initial and current amount of fans, followers and subscribers per social network",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c(
                        "div",
                        { staticClass: "d-flex align-items-center" },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "text-xs text-color-text mr-2 mb-0",
                              attrs: { for: "sel1" },
                            },
                            [_vm._v(" Social Network: ")]
                          ),
                          _c("multi-select-custom", {
                            staticClass: "selected-social-network",
                            attrs: {
                              selected: _vm.selected,
                              options: _vm.optionsSocialNetwork,
                            },
                            on: { selectValue: _vm.Filtrar },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._l(_vm.accounts, function (item, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        class: [
                          "social_network",
                          index == _vm.accounts.length - 1 ? "last" : "",
                        ],
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "col-md-9 col-xs-9 pos_rel p-0" },
                          [
                            _c("div", { staticClass: "row" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "box_social_network col-md-6 col-xs-6 p-0",
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "in_box_social_network" },
                                    [
                                      _vm._m(0, true),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "d-flex align-items-end",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex align-items-center",
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "text-2xl pr-1",
                                                class: [
                                                  "fab",
                                                  item.idredsocial == 1
                                                    ? "fa-facebook fbco"
                                                    : item.idredsocial == 2
                                                    ? "fa-twitter twco"
                                                    : item.idredsocial == 3
                                                    ? "fa-instagram inco"
                                                    : item.idredsocial == 4
                                                    ? "fa-youtube ytco"
                                                    : item.idredsocial == 5
                                                    ? "fa-linkedin lico"
                                                    : "",
                                                ],
                                              }),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "text-3-5xl text-secondary font-weight-bold",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        item.audiencestart
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "pl-2 pb-2" },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "text-xxs text-color-text",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      item.idredsocial == 1
                                                        ? "Fans"
                                                        : item.idredsocial == 2
                                                        ? "Followers"
                                                        : item.idredsocial == 3
                                                        ? "Followers"
                                                        : item.idredsocial == 4
                                                        ? "Subs"
                                                        : item.idredsocial == 5
                                                        ? "Followers"
                                                        : ""
                                                    ) + " "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "text-xxs text-color-text",
                                                },
                                                [_vm._v("/ ")]
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "text-xxs text-color-text",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      "Since: " +
                                                        _vm.transformDate(
                                                          item.startdate,
                                                          _vm.userInfo
                                                            .TIME_ZONE,
                                                          "DD/MM/YYYY"
                                                        )
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "text-secondary text-xxs",
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(item.accountname) + " "
                                          ),
                                        ]
                                      ),
                                      _c("div", {
                                        staticClass:
                                          "graf_site graf_site_fb collapse",
                                      }),
                                    ]
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "box_social_network col-md-4 col-xs-6 p-0",
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "in_box_social_network" },
                                    [
                                      _vm._m(1, true),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "d-flex align-items-end",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex align-items-center",
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "text-2xl pr-1",
                                                class: [
                                                  "fab",
                                                  item.idredsocial == 1
                                                    ? "fa-facebook fbco"
                                                    : item.idredsocial == 2
                                                    ? "fa-twitter twco"
                                                    : item.idredsocial == 3
                                                    ? "fa-instagram inco"
                                                    : item.idredsocial == 4
                                                    ? "fa-youtube ytco"
                                                    : item.idredsocial == 5
                                                    ? "fa-linkedin lico"
                                                    : "",
                                                ],
                                              }),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "text-3-5xl text-secondary font-weight-bold",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        item.audiencecurrent
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "pl-2 pb-2" },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "text-xxs text-color-text",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      item.idredsocial == 1
                                                        ? "Fans"
                                                        : item.idredsocial == 2
                                                        ? "Followers"
                                                        : item.idredsocial == 3
                                                        ? "Followers"
                                                        : item.idredsocial == 4
                                                        ? "Subs"
                                                        : item.idredsocial == 5
                                                        ? "Followers"
                                                        : ""
                                                    ) + " "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "text-secondary text-xxs",
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(item.accountname) + " "
                                          ),
                                        ]
                                      ),
                                      _c("div", {
                                        staticClass:
                                          "graf_site graf_site_fb collapse",
                                      }),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                          ]
                        ),
                        _c("div", { staticClass: "col-md-3 col-xs-3 pr-0" }, [
                          _c(
                            "div",
                            { staticClass: "row d-flex justify-content-end" },
                            [
                              _c(
                                "div",
                                {
                                  class: [
                                    "bx_nt_if",
                                    item.idredsocial == 1
                                      ? "fbbg"
                                      : item.idredsocial == 2
                                      ? "twbg"
                                      : item.idredsocial == 3
                                      ? "inbg"
                                      : item.idredsocial == 4
                                      ? "ytbg"
                                      : item.idredsocial == 5
                                      ? "libg"
                                      : "",
                                  ],
                                },
                                [
                                  _vm._m(2, true),
                                  _c(
                                    "div",
                                    { staticClass: "d-flex align-items-end" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex align-items-center",
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "text-2xl pr-1 text-white",
                                            class: [
                                              "fab",
                                              item.idredsocial == 1
                                                ? "fa-facebook"
                                                : item.idredsocial == 2
                                                ? "fa-twitter"
                                                : item.idredsocial == 3
                                                ? "fa-instagram"
                                                : item.idredsocial == 4
                                                ? "fa-youtube"
                                                : item.idredsocial == 5
                                                ? "fa-linkedin"
                                                : "",
                                            ],
                                          }),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "text-3-5xl text-white font-weight-bold mr-1",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(item.audiencetarget) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "text-xxs text-white pb-2",
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  "New " +
                                                    (item.idredsocial == 1
                                                      ? "Fans"
                                                      : item.idredsocial == 2
                                                      ? "Followers"
                                                      : item.idredsocial == 3
                                                      ? "Followers"
                                                      : item.idredsocial == 4
                                                      ? "Subs"
                                                      : item.idredsocial == 5
                                                      ? "Followers"
                                                      : "")
                                                ) +
                                                " "
                                            ),
                                          ]),
                                          _c("span", [_vm._v("/")]),
                                          _c("span", [
                                            _vm._v(_vm._s(item.goal)),
                                          ]),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex align-items-center text-white",
                                    },
                                    [
                                      _c("div", [
                                        _c(
                                          "span",
                                          { staticClass: "text-xxs pr-1" },
                                          [_vm._v("Efficiency: ")]
                                        ),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "text-lsm font-weight-bold",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(item.eficency + " %") +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]),
                                      _vm._m(3, true),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]),
                        _c("div", { staticClass: "clear" }),
                      ]
                    )
                  }),
                ],
                2
              ),
            ]),
            _c("div", { staticClass: "clear" }),
          ]
        ),
      ]),
      _c("div", { staticClass: "void" }),
      _c("analysisInterest"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "mb-2 mt-2" }, [
      _c("span", { staticClass: "font-weight-bold text-lsm text-secondary" }, [
        _vm._v("Start"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "mb-2 mt-2" }, [
      _c("span", { staticClass: "font-weight-bold text-lsm text-secondary" }, [
        _vm._v("Current"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", {}, [
      _c("span", { staticClass: "text-white font-weight-bold text-lsm" }, [
        _vm._v(" Goal "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("span", { staticClass: "text-left text-xxs d-none" }, [
        _vm._v(" Target: (0 new fans) "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }