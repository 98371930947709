<template>
  <div class="w-100 mb-4">
    <p class="font-weight-bold mb-3 mt-2 text-lsm text-secondary">Information of Campaigns</p> 
    <div class="row">
      <div class="col">
        <div class="bg-primary d-flex flex-column justify-content-center align-items-center _card">
          <p class="font-weight-bold text-xs text-white">Total Comments</p>
          <p>
            <span class="font-weight-bold text-lg text-white">{{totalcomments}} </span>
          </p>
        </div>
      </div>
      <div class="col">
        <div class="bg-primary d-flex flex-column justify-content-center align-items-center _card">
          <p class="font-weight-bold text-xs text-white">Total engaged users</p>
          <p>
            <span class="font-weight-bold text-lg text-white">{{totalengage}} </span>
          </p>
        </div>
      </div>
      <div class="col">
        <div class="bg-primary d-flex flex-column justify-content-center align-items-center _card">
          <p class="font-weight-bold text-xs text-white">New Audience</p>
          <p>
            <span class="font-weight-bold text-lg text-white">{{totalnewaudience}} </span>
          </p>
        </div>
      </div>
      <div class="col">
        <div class="bg-success  interactionsBox _card">
          <div class="d-flex align-items-center justify-content-center">
            <span class="font-weight-bold text-xs text-white">Campaign with more interactions</span>
          </div>
          <div>
              <h1 class="d-block font-weight-bold text-base text-white interactionsBoxDetails text-truncate"  :title="mayor">{{mayor}}</h1>
          </div>
          <br v-if="mayor == ''">
        </div>
      </div>
       <div class="col pr-0">
        <div class="bg-danger interactionsBox _card">
          <div class="d-flex align-items-center justify-content-center">
            <span class="font-weight-bold text-xs text-white">Campaign with less interactions</span>
          </div>
          <div>
            <h1 class="d-block font-weight-bold text-base text-white interactionsBoxDetails text-truncate" :title="menor">{{menor}}</h1>
          </div>
          <br v-if="menor == ''">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    mayor: {type: String , default:""},
    menor: {type: String , default:""},
    totalcomments:Number,
    totalengage:Number,
    totalnewaudience:Number,
  },
  mounted() {
    // const baseHeight = this.$refs.boxBase.offsetHeight;
    // this.$refs.childBox1.style.height = baseHeight + "px";
    // this.$refs.childBox2.style.height = baseHeight + "px";
  },
};
</script>

<style>
._card {
  min-height: 80px;
  border-radius: 6px
}
.interactionsBox{
  padding-top: 18px;
}
.interactionsBoxDetails{
  max-width: 210px;
  text-align: center;
  margin: 0 auto;
  position: relative;
  top: 3px;
}
</style>
