var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "w-100 mb-4" }, [
    _c(
      "p",
      { staticClass: "font-weight-bold mb-3 mt-2 text-lsm text-secondary" },
      [_vm._v("Information of Campaigns")]
    ),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col" }, [
        _c(
          "div",
          {
            staticClass:
              "bg-primary d-flex flex-column justify-content-center align-items-center _card",
          },
          [
            _c("p", { staticClass: "font-weight-bold text-xs text-white" }, [
              _vm._v("Total Comments"),
            ]),
            _c("p", [
              _c(
                "span",
                { staticClass: "font-weight-bold text-lg text-white" },
                [_vm._v(_vm._s(_vm.totalcomments) + " ")]
              ),
            ]),
          ]
        ),
      ]),
      _c("div", { staticClass: "col" }, [
        _c(
          "div",
          {
            staticClass:
              "bg-primary d-flex flex-column justify-content-center align-items-center _card",
          },
          [
            _c("p", { staticClass: "font-weight-bold text-xs text-white" }, [
              _vm._v("Total engaged users"),
            ]),
            _c("p", [
              _c(
                "span",
                { staticClass: "font-weight-bold text-lg text-white" },
                [_vm._v(_vm._s(_vm.totalengage) + " ")]
              ),
            ]),
          ]
        ),
      ]),
      _c("div", { staticClass: "col" }, [
        _c(
          "div",
          {
            staticClass:
              "bg-primary d-flex flex-column justify-content-center align-items-center _card",
          },
          [
            _c("p", { staticClass: "font-weight-bold text-xs text-white" }, [
              _vm._v("New Audience"),
            ]),
            _c("p", [
              _c(
                "span",
                { staticClass: "font-weight-bold text-lg text-white" },
                [_vm._v(_vm._s(_vm.totalnewaudience) + " ")]
              ),
            ]),
          ]
        ),
      ]),
      _c("div", { staticClass: "col" }, [
        _c("div", { staticClass: "bg-success interactionsBox _card" }, [
          _vm._m(0),
          _c("div", [
            _c(
              "h1",
              {
                staticClass:
                  "d-block font-weight-bold text-base text-white interactionsBoxDetails text-truncate",
                attrs: { title: _vm.mayor },
              },
              [_vm._v(_vm._s(_vm.mayor))]
            ),
          ]),
          _vm.mayor == "" ? _c("br") : _vm._e(),
        ]),
      ]),
      _c("div", { staticClass: "col pr-0" }, [
        _c("div", { staticClass: "bg-danger interactionsBox _card" }, [
          _vm._m(1),
          _c("div", [
            _c(
              "h1",
              {
                staticClass:
                  "d-block font-weight-bold text-base text-white interactionsBoxDetails text-truncate",
                attrs: { title: _vm.menor },
              },
              [_vm._v(_vm._s(_vm.menor))]
            ),
          ]),
          _vm.menor == "" ? _c("br") : _vm._e(),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "d-flex align-items-center justify-content-center" },
      [
        _c("span", { staticClass: "font-weight-bold text-xs text-white" }, [
          _vm._v("Campaign with more interactions"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "d-flex align-items-center justify-content-center" },
      [
        _c("span", { staticClass: "font-weight-bold text-xs text-white" }, [
          _vm._v("Campaign with less interactions"),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }