<template>
  <div class="interactiones">
    <highcharts :options="chartOptions"> </highcharts>
  </div>
</template>
<script>
import { Chart } from "highcharts-vue";
import Highcharts from "highcharts";
import exportingInit from "highcharts/modules/exporting";
import stockInit from "highcharts/modules/stock";

stockInit(Highcharts);
exportingInit(Highcharts);

export default {
  name: "interactions",
  components: {
    highcharts: Chart,
  },
  data() {
    return {
      chartOptions: {},
    };
  },

  props: {
    grafico: Array,
  },
  methods: {
    Generate(objq) {
      let impresiones_pagoPorc = objq.impressionspaid;
      let impresiones_organicasPorc = objq.impressionsoorganic;
      let chartOption = {
        chart: {
          width: 330,
          height: 230,
          type: "pie",
          options3d: {
            enabled: true,
            alpha: 45,
          },
        },
        legend: {
          itemStyle: {
            color: '#616b86',
            fontSize: '9px',
            fontWeight: 'normal',
          },
          enabled: true,
          itemWidth: 200,
          align: 'right',
          verticalAlign: 'top',
          // layout: 'vertical',
          x: 90,
          y: 0,
        },
        credits: {
          text: "",
        },
        title: {
          text: "",
        },
        subtitle: {
          text: "",
        },
        plotOptions: {
          pie: {
            center: [100, 20],
            size: 150,
            innerSize: 110,
            depth: 65,
            showInLegend: true,
            dataLabels: {
            enabled: true,
              formatter: function () {
                  return this.y > 5 ? `${this.point.percentage.toFixed(0)}%` : null;
              },
              color: '#616b86',
              distance: 0
            },
          },
        },
        series: [
          {
            name: "",
            colors: ["#00a5ff", "#7acffd"],
            data: [
              ["Paid", impresiones_pagoPorc],
              ["Organic", impresiones_organicasPorc],
            ],
          },
        ],
        exporting: {
          enabled: false
        }
      };
      this.chartOptions = chartOption;
      return chartOption;
    },
  },
};
</script>
<style lang="css" scoped>
  .interactiones{
    display: flex;
    justify-content: center;
  }
</style>

