var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "bg-white mb-4 p-5" },
    [
      _c("div", { staticClass: "d-flex justify-content-between mb-5" }, [
        _c("div", { staticClass: "text-lsm text-secondary font-weight-bold" }, [
          _vm._v(" Timeline of Actions "),
        ]),
        _c("div", { staticClass: "d-flex align-items-center" }, [
          _c("div", { staticClass: "text-xs text-skygray" }, [_vm._v("Zoom:")]),
          _c(
            "div",
            {
              staticClass:
                "zoom-button d-flex align-items-center justify-content-center",
              on: { click: _vm.zoom_in },
            },
            [_c("i", { staticClass: "fa fa-plus" })]
          ),
          _c(
            "div",
            {
              staticClass:
                "zoom-button d-flex align-items-center justify-content-center",
              on: { click: _vm.zoom_out },
            },
            [_c("i", { staticClass: "fa fa-minus" })]
          ),
        ]),
      ]),
      _c("div", { ref: "myTimeline", attrs: { id: "mytimeline" } }),
      _c("contentPost", { ref: "modalcont", attrs: { obj: _vm.objpost } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }