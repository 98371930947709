import { render, staticRenderFns } from "./interactionsAnalytics.vue?vue&type=template&id=237c8496&"
import script from "./interactionsAnalytics.vue?vue&type=script&lang=js&"
export * from "./interactionsAnalytics.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/salmuz/Documents/Dev/Git/Monitor/mf-front/isc-campaign/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('237c8496')) {
      api.createRecord('237c8496', component.options)
    } else {
      api.reload('237c8496', component.options)
    }
    module.hot.accept("./interactionsAnalytics.vue?vue&type=template&id=237c8496&", function () {
      api.rerender('237c8496', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/all/detail-campaign/tabs/interactionsAnalytics.vue"
export default component.exports