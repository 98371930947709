<template>
  <div class="w-100 bg-mainbg">
    <bread-crumb />
    <div class="container container-center p-5" id="container-edit">
      <Tabs :cant="2" :titles="titles" @change="changeTab" />
      <div class="bg-white">
        <configuration-edit
          :campaign="campaign"
          v-if="activeTab === 0 && campaign.goals.length > 0"
          @messageCreated="messageCreated"
        ></configuration-edit>
        <posts-edit
          :campaign="campaign"
          v-if="activeTab === 1"
          @messageCreated="messageCreated"
        ></posts-edit>

      </div>

    </div>
  </div>
</template>

<script>
import { BreadCrumb, Tabs } from "@isc/styleguide";
import ConfigurationEdit from "./ConfigurationEdit.vue";
import PostsEdit from "./PostsEdit.vue";
import * as CampaignService from "@/services/campaign.service";

export default {
  name: "EditCampaign",
  components: {
    BreadCrumb,
    ConfigurationEdit,
    PostsEdit,
    Tabs,
  },
  data() {
    return {
      titles: [
        {
          name: "Configuration Edit",
        },
        {
          name: "Post Edit",
        },
      ],
      activeTab: 0,
      campaign: {
        key: null,
        name: null,
        type: null,
        goals: [],
        leads: null,
        reached: {
          facebook: null,
          twiter: null,
          instagram: null,
          youtube: null,
        },
        account_name: null,
        account_id: null,
        dates: {
          start: null,
          end: null,
        },
        timezoneId: 0,
        table: "",
        isActive: true,
        currency: null,
        idcurrency: null,
        budget: null,
        posts: [],
      },
    };
  },
  mounted() {
    // buscar en el api la campana con esta key
    this.campaign.key = this.$route.params.key;
    this.loadDataDetailCampaign(this.campaign.key);
  },
  methods: {
    messageCreated(created) {
      this.$emit("messageCreated", created);
    },
    changeTab(position) {
      this.activeTab = position;
    },
    async loadDataDetailCampaign(idCampaign) {
      const _detailCampaign = await CampaignService.listDetailCampaign(
        idCampaign
      );

      this.campaign.name = _detailCampaign.campaign.nom_campania;
      this.campaign.account_id = _detailCampaign.campaign.id_marca;
      this.campaign.dates.end =  _detailCampaign.campaign.fec_fin_campania.split("T")[0];
      this.campaign.dates.start = _detailCampaign.campaign.fec_inicio_campania.split("T")[0];
      this.campaign.account_name = _detailCampaign.campaign.MarcaPGS.dscMarca;
      if (_detailCampaign.goals.length > 0) {
        this.campaign.type =
          _detailCampaign.goals[0][0].MetricaPGS.descripcionmetrica;

        if (_detailCampaign.goals.length > 0) {
          for (
            let index = 0;
            index < _detailCampaign.goals[0].length;
            index++
          ) {
            const element = _detailCampaign.goals[0][index];
            this.campaign.goals.push({
              newGoalType: _detailCampaign.goals[0][index].idMetrica,
              nameMetric:
                _detailCampaign.goals[0][index].MetricaPGS.nombreMetrica,
              newGoalNumber: _detailCampaign.goals[0][index].ctdObjetivo,
              idCampaniaObjetivos:
                _detailCampaign.goals[0][index].idCampaniaObjetivos,
            });
          }
        }
      }
    },
  },
};
</script>

<style scoped>
#container-edit {
  margin-top: 30px;
}

.span-controller-edit {
  margin: 0px 200px;
}

.paint-span {
  background-color: skyblue;
}
</style>
