<template>
  <div class="bg-white w-full p-4 rounded flex-between flex-wrap">
    <div class="row col-12">
      <div class="col-6">
        <div>
          <span
            class="d-block mb-4 font-weight-bold text-lsm text-secondary mt-3 mb-3"
            >Top 5 campaigns by Impressions</span
          >
        </div>
        <div>
          <highcharts :options="chartImpressions" />
        </div>
      </div>
      <div class="col-6">
        <div>
          <span
            class="d-block mb-4 font-weight-bold text-lsm text-secondary mt-3 mb-3"
            >Top 5 campaigns by Interactions</span
          >
        </div>
        <div>
          <highcharts :options="chartInteractions" />
        </div>
      </div>
    </div>
    <div class="row col-12">
      <div class="col-6">
        <div>
          <span
            class="d-block mb-4 font-weight-bold text-lsm text-secondary mt-3 mb-3"
            >Top 5 campaigns by Total Conversion Rate</span
          >
        </div>
        <div>
          <highcharts :options="chartConversions" />
        </div>
      </div>
      <div class="col-6">
        <div>
          <span
            class="d-block mb-4 font-weight-bold text-lsm text-secondary mt-3 mb-3"
            >Top 5 Campaigns by Conversion Rate and Type</span
          >
        </div>
        <div>
          <div id="div_ratechart">
            <span
              @click="select('Audiencie')"
              :class="[selecttype == 'Audiencie' ? 'ratec_active' : '']"
              >Audience</span
            >
            |
            <span
              @click="select('Leads')"
              :class="[selecttype == 'Leads' ? 'ratec_active' : '']"
              >Leads</span
            >
            <!-- <span @click="select('Sales')"  :class="[selecttype == 'Sales' ? 'ratec_active' : '']">Sales</span> -->
          </div>
          <highcharts :options="chartTypeConversions" />
        </div>
      </div>
    </div>

    <div class="w-100 my-5 px-4">
      <table class="table table-overview-general">
        <thead>
          <tr class="border-bottom">
            <td style="width: auto">
              <strong class="text-xs text-secondary">Campaigns</strong>
            </td>
            <td style="width: auto">
              <strong class="text-xs text-secondary">Start Date</strong>
            </td>
            <td style="width: auto">
              <strong class="text-xs text-secondary">End Date</strong>
            </td>
            <td style="width: auto">
              <strong class="text-xs text-secondary">Type</strong>
            </td>
            <td style="width: auto">
              <strong class="text-xs text-secondary">Impressions</strong>
            </td>
            <td style="width: auto">
              <strong class="text-xs text-secondary">Interactions</strong>
            </td>
            <td style="width: auto">
              <strong class="text-xs text-secondary">Audience Growth</strong>
            </td>
            <td style="width: auto">
              <strong class="text-xs text-secondary">Feedback Rate</strong>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="itemGraf in table" :key="itemGraf.id_campania">
            <td @click="visualizar(itemGraf.id_campania,itemGraf.fec_inicio_campania, itemGraf.fec_fin_campania)">
              <span class="text-xs text-color-text font-weight-bold">
                {{ itemGraf.nom_campania }}
              </span>
            </td>
            <td>
              <span class="text-xs text-color-text">{{
                transformDate(
                  itemGraf.fec_inicio_campania,
                  userInfo.TIME_ZONE,
                  "DD-MM-YYYY"
                )
              }}</span>
            </td>
            <td>
              <span class="text-xs text-color-text">{{
                transformDate(
                  itemGraf.fec_fin_campania,
                  userInfo.TIME_ZONE,
                  "DD-MM-YYYY"
                )
              }}</span>
            </td>
            <td>
              <span class="text-xs text-color-text">{{ itemGraf.type === 'Audiencie' ? 'Audience' : itemGraf.type }}</span>
            </td>
            <td>
              <span class="text-xs text-color-text">{{
                itemGraf.impressions
              }}</span>
            </td>
            <td>
              <span class="text-xs text-color-text">{{
                itemGraf.interactions
              }}</span>
            </td>
            <td>
              <span class="text-xs text-color-text">{{
                itemGraf.increaseaudience + " %"
              }}</span>
            </td>
            <td>
              <span class="text-xs text-color-text">{{
                itemGraf.tasacomments + " %"
              }}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { Helpers, mapGetters } from "@isc/styleguide";
export default {
  name: "OverviewGraphs",
  data() {
    return {
      table: [],
      currentPage: 2,
      fields: [
        "Campaigns",
        "Start Date",
        "End Date",
        "Type",
        "Impressions",
        "Interactions",
        "Conversion rate",
        "Conversion",
      ],
      items: [
        {
          Campaigns: "otoño 2021",
          "Start Date": "20/03/2021",
          "End Date": "03/06/2021",
          Type: "New Followers",
          Impressions: "0",
          Interactions: "0",
          "Conversion rate": "0",
          Conversion: "306",
        },
      ],
      chartInteractions: {
        chart: {
          type: "bar",
        },
        title: {
          text: null,
        },
        subtitle: {
          text: null,
        },
        xAxis: {
          tickWidth: 0,
          title: {
            text: null,
          },

          lineColor: "#ffffff",
          categories: ["", "", "", "", ""],
          tickWidth: 0,
          labels: {
            align: "right",
            style: {
              color: "#ababab",
            },
          },
        },

        yAxis: {
          min: 0,
          lineColor: "#ffffff",
          title: {
            text: null,
          },
          gridLineWidth: 1,
          labels: {
            enabled: false,
            overflow: "justify",
          },
        },
        tooltip: {
          formatter: function () {
            let text = "<table style='margin-bottom:3px;margin-right:0px'>";
            text +=
              "<tr><td colspan='2' style='background:#F3F4F7;font-weight:bold;padding:4px;padding-left:8px'>" +
              this.x +
              "</td></tr>";
            for (let i = 0; i < this.points.length; i++) {
              ///console.log(this.points[i].series.color);
              text += "<tr><td style='padding:4px;padding-left:8px'>";
              text +=
                "<i class='fa fa-circle' style='margin-right:4px;vertical-align:middle;font-size:6px;color:" +
                this.points[i].series.color +
                " !important'></i> " +
                this.points[i].series.name;
              text +=
                "</td><td style='padding:4px;text-align:right;padding-right:8px'>";
              text += this.points[i].y;
              text += "</td></tr>";
            }
            text += "</table>";
            return text;
          },
          shared: true,
          padding: 0,
          backgroundColor: "#ffffff",
          useHTML: true,
          shadow: true,
          borderWidth: 0,
          borderRadius: 0,
          crosshairs: true,
        },
        plotOptions: {
          bar: {
            dataLabels: {
              enabled: true,
              formatter: function () {
                let tmp = this.y;
                let k = "K";
                let m = "M";
                let n = this.y;
                let text = n;
                if (n > 1000000000000)
                  text = Math.round((n / 1000000000000) * 100) / 100 + " T";
                else if (n > 1000000000)
                  text = Math.round((n / 1000000000) * 100) / 100 + " G";
                else if (n > 1000000)
                  text = Math.round((n / 1000000) * 100) / 100 + m;
                else if (n > 1000)
                  text = Math.round((n / 1000) * 100) / 100 + k;
                return text;
              },
            },
            colorByPoint: false,
            pointWidth: 8,
          },
        },
        legend: {
          align: "right",
          x: 10,
          y: 10,
          verticalAlign: "bottom",
          // floating: true,
          borderWidth: 0,
          shadow: false,
          itemStyle: {
            fontWeight: "normal",
            color: "#6F7478",
            fontSize: "10px",
          },
        },
        exporting: {
          enabled: false,
        },
        credits: {
          enabled: false,
        },
        series: [
          {
            name: "Interactions",
            color: "#00a5ff",
            data: [0, 0, 0, 0, 0],
          },
        ],
      },
      chartImpressions: {
        chart: {
          type: "bar",
        },
        title: {
          text: null,
        },
        subtitle: {
          text: null,
        },
        xAxis: {
          tickWidth: 0,
          title: {
            text: null,
          },

          lineColor: "#ffffff",
          categories: ["", "", "", "", ""],
          tickWidth: 0,
          labels: {
            align: "right",
            style: {
              color: "#ababab",
            },
          },
        },

        yAxis: {
          min: 0,
          lineColor: "#ffffff",
          title: {
            text: null,
          },
          gridLineWidth: 1,
          labels: {
            enabled: false,
            overflow: "justify",
          },
        },
        tooltip: {
          formatter: function () {
            let text = "<table style='margin-bottom:3px;margin-right:0px'>";
            text +=
              "<tr><td colspan='2' style='background:#F3F4F7;font-weight:bold;padding:4px;padding-left:8px'>" +
              this.x +
              "</td></tr>";
            for (let i = 0; i < this.points.length; i++) {
              text += "<tr><td style='padding:4px;padding-left:8px'>";
              text +=
                "<i class='fa fa-circle' style='margin-right:4px;vertical-align:middle;font-size:6px;color:" +
                this.points[i].series.color +
                " !important'></i> " +
                this.points[i].series.name;
              text +=
                "</td><td style='padding:4px;text-align:right;padding-right:8px'>";
              text += this.points[i].y;
              text += "</td></tr>";
            }
            text += "</table>";
            return text;
          },
          shared: true,
          padding: 0,
          backgroundColor: "#ffffff",
          useHTML: true,
          shadow: true,
          borderWidth: 0,
          borderRadius: 0,
          crosshairs: true,
        },
        plotOptions: {
          bar: {
            dataLabels: {
              enabled: true,
              formatter: function () {
                let tmp = this.y;
                let k = "K";
                let m = "M";
                let n = this.y;
                let text = n;
                if (n > 1000000000000)
                  text = Math.round((n / 1000000000000) * 100) / 100 + " T";
                else if (n > 1000000000)
                  text = Math.round((n / 1000000000) * 100) / 100 + " G";
                else if (n > 1000000)
                  text = Math.round((n / 1000000) * 100) / 100 + m;
                else if (n > 1000)
                  text = Math.round((n / 1000) * 100) / 100 + k;
                return text;
              },
            },
            colorByPoint: false,
            pointWidth: 8,
          },
        },
        legend: {
          align: "right",
          x: 10,
          y: 10,
          verticalAlign: "bottom",
          borderWidth: 0,
          shadow: false,
          itemStyle: {
            fontWeight: "normal",
            color: "#6F7478",
            fontSize: "10px",
          },
        },
        exporting: {
          enabled: false,
        },
        credits: {
          enabled: false,
        },
        series: [
          {
            name: "Impressions",
            color: "#00a5ff",
            data: [0, 0, 0, 0, 0],
          },
        ],
      },
      chartConversions: {
        chart: {
          type: "bar",
        },
        title: {
          text: null,
        },
        subtitle: {
          text: null,
        },
        xAxis: {
          tickWidth: 0,
          title: {
            text: null,
          },

          lineColor: "#ffffff",
          categories: ["", "", "", "", ""],
          tickWidth: 0,
          labels: {
            align: "right",
            style: {
              color: "#ababab",
            },
          },
        },

        yAxis: {
          min: 0,
          lineColor: "#ffffff",
          title: {
            text: null,
          },
          gridLineWidth: 1,
          labels: {
            enabled: false,
            overflow: "justify",
          },
        },
        tooltip: {
          formatter: function () {
            let text = "<table style='margin-bottom:3px;margin-right:0px'>";
            text +=
              "<tr><td colspan='2' style='background:#F3F4F7;font-weight:bold;padding:4px;padding-left:8px'>" +
              this.x +
              "</td></tr>";
            for (let i = 0; i < this.points.length; i++) {
              text += "<tr><td style='padding:4px;padding-left:8px'>";
              text +=
                "<i class='fa fa-circle' style='margin-right:4px;vertical-align:middle;font-size:6px;color:" +
                this.points[i].series.color +
                " !important'></i> " +
                this.points[i].series.name;
              text +=
                "</td><td style='padding:4px;text-align:right;padding-right:8px'>";
              text += this.points[i].y;
              text += "</td></tr>";
            }
            text += "</table>";
            return text;
          },
          shared: true,
          padding: 0,
          backgroundColor: "#ffffff",
          useHTML: true,
          shadow: true,
          borderWidth: 0,
          borderRadius: 0,
          crosshairs: true,
        },
        plotOptions: {
          bar: {
            dataLabels: {
              enabled: true,
              formatter: function () {
                let tmp = this.y;
                let k = "K";
                let m = "M";
                let n = this.y;
                let text = n;
                if (n > 1000000000000)
                  text = Math.round((n / 1000000000000) * 100) / 100 + " T";
                else if (n > 1000000000)
                  text = Math.round((n / 1000000000) * 100) / 100 + " G";
                else if (n > 1000000)
                  text = Math.round((n / 1000000) * 100) / 100 + m;
                else if (n > 1000)
                  text = Math.round((n / 1000) * 100) / 100 + k;
                return text;
              },
            },
            colorByPoint: false,
            pointWidth: 8,
          },
        },
        legend: {
          align: "right",
          x: 10,
          y: 10,
          verticalAlign: "bottom",
          // floating: true,
          borderWidth: 0,
          shadow: false,
          itemStyle: {
            fontWeight: "normal",
            color: "#6F7478",
            fontSize: "10px",
          },
        },
        exporting: {
          enabled: false,
        },
        credits: {
          enabled: false,
        },
        series: [
          {
            name: "Conversions",
            color: "#00a5ff",
            data: [0, 0, 0, 0, 0],
          },
        ],
      },
      chartTypeConversions: {
        chart: {
          type: "bar",
        },
        title: {
          text: null,
        },
        subtitle: {
          text: null,
        },
        xAxis: {
          tickWidth: 0,
          title: {
            text: null,
          },

          lineColor: "#ffffff",
          categories: ["", "", "", "", ""],
          tickWidth: 0,
          labels: {
            align: "right",
            style: {
              color: "#ababab",
            },
          },
        },

        yAxis: {
          min: 0,
          lineColor: "#ffffff",
          title: {
            text: null,
          },
          gridLineWidth: 1,
          labels: {
            enabled: false,
            overflow: "justify",
          },
        },
        tooltip: {
          formatter: function () {
            let text = "<table style='margin-bottom:3px;margin-right:0px'>";
            text +=
              "<tr><td colspan='2' style='background:#F3F4F7;font-weight:bold;padding:4px;padding-left:8px'>" +
              this.x +
              "</td></tr>";
            for (let i = 0; i < this.points.length; i++) {
              text += "<tr><td style='padding:4px;padding-left:8px'>";
              text +=
                "<i class='fa fa-circle' style='margin-right:4px;vertical-align:middle;font-size:6px;color:" +
                this.points[i].series.color +
                " !important'></i> " +
                this.points[i].series.name;
              text +=
                "</td><td style='padding:4px;text-align:right;padding-right:8px'>";
              text += this.points[i].y;
              text += "</td></tr>";
            }
            text += "</table>";
            return text;
          },
          shared: true,
          padding: 0,
          backgroundColor: "#ffffff",
          useHTML: true,
          shadow: true,
          borderWidth: 0,
          borderRadius: 0,
          crosshairs: true,
        },
        plotOptions: {
          bar: {
            dataLabels: {
              enabled: true,
              formatter: function () {
                let tmp = this.y;
                let k = "K";
                let m = "M";
                let n = this.y;
                let text = n;
                if (n > 1000000000000)
                  text = Math.round((n / 1000000000000) * 100) / 100 + " T";
                else if (n > 1000000000)
                  text = Math.round((n / 1000000000) * 100) / 100 + " G";
                else if (n > 1000000)
                  text = Math.round((n / 1000000) * 100) / 100 + m;
                else if (n > 1000)
                  text = Math.round((n / 1000) * 100) / 100 + k;
                return text;
              },
            },
            colorByPoint: false,
            pointWidth: 8,
          },
        },
        legend: {
          align: "right",
          x: 10,
          y: 10,
          verticalAlign: "bottom",
          borderWidth: 0,
          shadow: false,
          itemStyle: {
            fontWeight: "normal",
            color: "#6F7478",
            fontSize: "10px",
          },
        },
        exporting: {
          enabled: false,
        },
        credits: {
          enabled: false,
        },
        series: [
          {
            name: "Rate",
            color: "#00a5ff",
            data: [0, 0, 0, 0, 0],
          },
        ],
      },
      selecttype: "Audiencie",
      dataRate: [],
      transformDate: (date, countryTz, format) =>
        Helpers.transformStringDate(date, countryTz, format),
    };
  },
  computed: {
    ...mapGetters({
      userInfo: "settings/profile/getObjUser",
    }),
  },
  methods: {
    async visualizar(idCampaign, fec_ini, fec_fin) {
      this.$router
        .push({ name: "campaign-detail", query: {campaign: idCampaign, fec_ini, fec_fin} })
        .catch((_) => {});
    },
    dataTable: function (data) {
      this.table = data;
    },
    interacciones: function (obj) {
      let temptotal = obj;
      let series = [];
      let total_interacciones = [];
      let marca = [];
      let temp = temptotal.sort((a, b) => {
        if (a.interactions < b.interactions) {
          return 1;
        }
        if (a.interactions > b.interactions) {
          return -1;
        }
        return 0;
      });
      for (let index = 0; index < 5; index++) {
        let nom = temp[index] ? temp[index].nom_campania : "";
        let data = temp[index] ? temp[index].interactions : 0;
        marca.push(nom);
        total_interacciones.push(data);
      }

      let byInteraccionesConfig = {
        chart: {
          type: "bar",
        },
        title: {
          text: null,
        },
        subtitle: {
          text: null,
        },
        xAxis: {
          tickWidth: 0,
          title: {
            text: null,
          },

          lineColor: "#ffffff",
          categories: marca,
          tickWidth: 0,
          labels: {
            align: "right",
            style: {
              color: "#ababab",
            },
          },
        },

        yAxis: {
          min: 0,
          lineColor: "#ffffff",
          title: {
            text: null,
          },
          gridLineWidth: 1,
          labels: {
            enabled: false,
            overflow: "justify",
          },
        },
        tooltip: {
          formatter: function () {
            let text = "<table style='margin-bottom:3px;margin-right:0px'>";
            text +=
              "<tr><td colspan='2' style='background:#F3F4F7;font-weight:bold;padding:4px;padding-left:8px'>" +
              this.x +
              "</td></tr>";
            for (let i = 0; i < this.points.length; i++) {
              text += "<tr><td style='padding:4px;padding-left:8px'>";
              text +=
                "<i class='fa fa-circle' style='margin-right:4px;vertical-align:middle;font-size:6px;color:" +
                this.points[i].series.color +
                " !important'></i> " +
                this.points[i].series.name;
              text +=
                "</td><td style='padding:4px;text-align:right;padding-right:8px'>";
              text += this.points[i].y;
              text += "</td></tr>";
            }
            text += "</table>";
            return text;
          },
          shared: true,
          padding: 0,
          backgroundColor: "#ffffff",
          useHTML: true,
          shadow: true,
          borderWidth: 0,
          borderRadius: 0,
          crosshairs: true,
        },
        plotOptions: {
          bar: {
            dataLabels: {
              enabled: true,
              formatter: function () {
                let tmp = this.y;
                let k = "K";
                let m = "M";
                let n = this.y;
                let text = n;
                if (n > 1000000000000)
                  text = Math.round((n / 1000000000000) * 100) / 100 + " T";
                else if (n > 1000000000)
                  text = Math.round((n / 1000000000) * 100) / 100 + " G";
                else if (n > 1000000)
                  text = Math.round((n / 1000000) * 100) / 100 + m;
                else if (n > 1000)
                  text = Math.round((n / 1000) * 100) / 100 + k;
                return text;
              },
            },
            colorByPoint: false,
            pointWidth: 8,
          },
        },
        legend: {
          align: "right",
          x: 10,
          y: 10,
          verticalAlign: "bottom",
          borderWidth: 0,
          shadow: false,
          itemStyle: {
            fontWeight: "normal",
            color: "#6F7478",
            fontSize: "10px",
          },
        },
        exporting: {
          enabled: false,
        },
        credits: {
          enabled: false,
        },
        series: [
          {
            name: "Interactions",
            color: "#00a5ff",
            data: total_interacciones,
          },
        ],
      };
      this.chartInteractions = byInteraccionesConfig;
      return byInteraccionesConfig;
    },
    impressions: function (obj) {
      let temptotal = obj;
      this.dataRate = obj;
      let series = [];
      let total_impressions = [];
      let marca = [];
      let temp = temptotal.sort((a, b) => {
        if (a.impressions < b.impressions) {
          return 1;
        }
        if (a.impressions > b.impressions) {
          return -1;
        }
        return 0;
      });
      for (let index = 0; index < 5; index++) {
        let nom = temp[index] ? temp[index].nom_campania : "";
        let data = temp[index] ? temp[index].impressions : 0;
        marca.push(nom);
        total_impressions.push(data);
      }

      let byImpressionsConfig = {
        chart: {
          type: "bar",
        },
        title: {
          text: null,
        },
        subtitle: {
          text: null,
        },
        xAxis: {
          tickWidth: 0,
          title: {
            text: null,
          },

          lineColor: "#ffffff",
          categories: marca,
          tickWidth: 0,
          labels: {
            align: "right",
            style: {
              color: "#ababab",
            },
          },
        },
        yAxis: {
          min: 0,
          lineColor: "#ffffff",
          title: {
            text: null,
          },
          gridLineWidth: 1,
          labels: {
            enabled: false,
            overflow: "justify",
          },
        },
        tooltip: {
          formatter: function () {
            let text = "<table style='margin-bottom:3px;margin-right:0px'>";
            text +=
              "<tr><td colspan='2' style='background:#F3F4F7;font-weight:bold;padding:4px;padding-left:8px'>" +
              this.x +
              "</td></tr>";
            for (let i = 0; i < this.points.length; i++) {
              text += "<tr><td style='padding:4px;padding-left:8px'>";
              text +=
                "<i class='fa fa-circle' style='margin-right:4px;vertical-align:middle;font-size:6px;color:" +
                this.points[i].series.color +
                " !important'></i> " +
                this.points[i].series.name;
              text +=
                "</td><td style='padding:4px;text-align:right;padding-right:8px'>";
              text += this.points[i].y;
              text += "</td></tr>";
            }
            text += "</table>";
            return text;
          },
          shared: true,
          padding: 0,
          backgroundColor: "#ffffff",
          useHTML: true,
          shadow: true,
          borderWidth: 0,
          borderRadius: 0,
          crosshairs: true,
        },

        plotOptions: {
          bar: {
            dataLabels: {
              enabled: true,
              formatter: function () {
                let tmp = this.y;
                let k = "K";
                let m = "M";
                let n = this.y;
                let text = n;
                if (n > 1000000000000)
                  text = Math.round((n / 1000000000000) * 100) / 100 + " T";
                else if (n > 1000000000)
                  text = Math.round((n / 1000000000) * 100) / 100 + " G";
                else if (n > 1000000)
                  text = Math.round((n / 1000000) * 100) / 100 + m;
                else if (n > 1000)
                  text = Math.round((n / 1000) * 100) / 100 + k;
                return text;
              },
            },
            colorByPoint: false,
            pointWidth: 8,
          },
        },
        legend: {
          align: "right",
          x: 10,
          y: 10,
          verticalAlign: "bottom",
          borderWidth: 0,
          shadow: false,
          itemStyle: {
            fontWeight: "normal",
            color: "#6F7478",
            fontSize: "10px",
          },
        },
        exporting: {
          enabled: false,
        },
        credits: {
          enabled: false,
        },
        series: [
          {
            name: "Impressions",
            color: "#00a5ff",
            data: total_impressions,
          },
        ],
      };
      this.chartImpressions = byImpressionsConfig;
      return byImpressionsConfig;
    },
    Conversions: function (obj) {
      let temptotal = obj;
      let total_conversion = [];
      let marca = [];
      let temp = temptotal.sort((a, b) => {
        if (a.conversion < b.conversion) {
          return 1;
        }
        if (a.conversion > b.conversion) {
          return -1;
        }
        return 0;
      });
      for (let index = 0; index < 5; index++) {
        let nom = temp[index] ? temp[index].nom_campania : "";
        let data = temp[index] ? temp[index].conversion : 0;
        marca.push(nom);
        total_conversion.push(data);
      }

      let byConversionConfig = {
        chart: {
          type: "bar",
        },
        title: {
          text: null,
        },
        subtitle: {
          text: null,
        },
        xAxis: {
          tickWidth: 0,
          title: {
            text: null,
          },

          lineColor: "#ffffff",
          categories: marca,
          tickWidth: 0,
          labels: {
            align: "right",
            style: {
              color: "#ababab",
            },
          },
        },
        yAxis: {
          min: 0,
          lineColor: "#ffffff",
          title: {
            text: null,
          },
          gridLineWidth: 1,
          labels: {
            enabled: false,
            overflow: "justify",
          },
        },
        tooltip: {
          formatter: function () {
            let text = "<table style='margin-bottom:3px;margin-right:0px'>";
            text +=
              "<tr><td colspan='2' style='background:#F3F4F7;font-weight:bold;padding:4px;padding-left:8px'>" +
              this.x +
              "</td></tr>";
            for (let i = 0; i < this.points.length; i++) {
              text += "<tr><td style='padding:4px;padding-left:8px'>";
              text +=
                "<i class='fa fa-circle' style='margin-right:4px;vertical-align:middle;font-size:6px;color:" +
                this.points[i].series.color +
                " !important'></i> " +
                this.points[i].series.name;
              text +=
                "</td><td style='padding:4px;text-align:right;padding-right:8px'>";
              text += this.points[i].y;
              text += "</td></tr>";
            }
            text += "</table>";
            return text;
          },
          shared: true,
          padding: 0,
          backgroundColor: "#ffffff",
          useHTML: true,
          shadow: true,
          borderWidth: 0,
          borderRadius: 0,
          crosshairs: true,
        },
        plotOptions: {
          bar: {
            dataLabels: {
              enabled: true,
              formatter: function () {
                let tmp = this.y;
                let k = "K";
                let m = "M";
                let n = this.y;
                let text = n;
                if (n > 1000000000000)
                  text = Math.round((n / 1000000000000) * 100) / 100 + " T";
                else if (n > 1000000000)
                  text = Math.round((n / 1000000000) * 100) / 100 + " G";
                else if (n > 1000000)
                  text = Math.round((n / 1000000) * 100) / 100 + m;
                else if (n > 1000)
                  text = Math.round((n / 1000) * 100) / 100 + k;
                return text;
              },
            },
            colorByPoint: false,
            pointWidth: 8,
          },
        },
        legend: {
          align: "right",
          x: 10,
          y: 10,
          verticalAlign: "bottom",
          borderWidth: 0,
          shadow: false,
          itemStyle: {
            fontWeight: "normal",
            color: "#6F7478",
            fontSize: "10px",
          },
        },
        exporting: {
          enabled: false,
        },
        credits: {
          enabled: false,
        },
        series: [
          {
            name: "Conversions",
            color: "#00a5ff",
            data: total_conversion,
          },
        ],
      };
      this.chartConversions = byConversionConfig;
      return byConversionConfig;
    },
    Rate: function (txt) {
      //let temptotal =this.table.filter(item=>item.type==txt);
      let series = [];
      let total_conversionrate = [];
      let marca = [];
      let temp = {};
      let attrName = "";
      if (txt === "Audiencie") {
        temp = this.sortData(this.dataRate, "conversion");
        attrName = "conversion";
      } else {
        temp = this.sortData(this.dataRate, "leads");
        attrName = "leads";
      }

      for (let index = 0; index < 5; index++) {
        let nom = temp[index] ? temp[index].nom_campania : "";
        let data = temp[index] ? temp[index][attrName] : 0;
        marca.push(nom);
        total_conversionrate.push(data);
      }

      let byConversionsRateConfig = {
        chart: {
          type: "bar",
        },
        title: {
          text: null,
        },
        subtitle: {
          text: null,
        },
        xAxis: {
          tickWidth: 0,
          title: {
            text: null,
          },

          lineColor: "#ffffff",
          categories: marca,
          tickWidth: 0,
          labels: {
            align: "right",
            style: {
              color: "#ababab",
            },
          },
        },
        yAxis: {
          min: 0,
          lineColor: "#ffffff",
          title: {
            text: null,
          },
          gridLineWidth: 1,
          labels: {
            enabled: false,
            overflow: "justify",
          },
        },
        tooltip: {
          formatter: function () {
            let text = "<table style='margin-bottom:3px;margin-right:0px'>";
            text +=
              "<tr><td colspan='2' style='background:#F3F4F7;font-weight:bold;padding:4px;padding-left:8px'>" +
              this.x +
              "</td></tr>";
            for (let i = 0; i < this.points.length; i++) {
              text += "<tr><td style='padding:4px;padding-left:8px'>";
              text +=
                "<i class='fa fa-circle' style='margin-right:4px;vertical-align:middle;font-size:6px;color:" +
                this.points[i].series.color +
                " !important'></i> " +
                this.points[i].series.name;
              text +=
                "</td><td style='padding:4px;text-align:right;padding-right:8px'>";
              text += this.points[i].y;
              text += "</td></tr>";
            }
            text += "</table>";
            return text;
          },
          shared: true,
          padding: 0,
          backgroundColor: "#ffffff",
          useHTML: true,
          shadow: true,
          borderWidth: 0,
          borderRadius: 0,
          crosshairs: true,
        },

        plotOptions: {
          bar: {
            dataLabels: {
              enabled: true,
              formatter: function () {
                let tmp = this.y;
                let k = "K";
                let m = "M";
                let n = this.y;
                let text = n;
                if (n > 1000000000000)
                  text = Math.round((n / 1000000000000) * 100) / 100 + " T";
                else if (n > 1000000000)
                  text = Math.round((n / 1000000000) * 100) / 100 + " G";
                else if (n > 1000000)
                  text = Math.round((n / 1000000) * 100) / 100 + m;
                else if (n > 1000)
                  text = Math.round((n / 1000) * 100) / 100 + k;
                return text;
              },
            },
            colorByPoint: false,
            pointWidth: 8,
          },
        },
        legend: {
          align: "right",
          x: 10,
          y: 10,
          verticalAlign: "bottom",
          // floating: true,
          borderWidth: 0,
          shadow: false,
          itemStyle: {
            fontWeight: "normal",
            color: "#6F7478",
            fontSize: "10px",
          },
        },
        exporting: {
          enabled: false,
        },
        credits: {
          enabled: false,
        },
        series: [
          {
            name: "Rate",
            color: "#00a5ff",
            data: total_conversionrate,
          },
        ],
      };
      this.chartTypeConversions = byConversionsRateConfig;
      return byConversionsRateConfig;
    },
    select(ms) {
      this.selecttype = ms;
      this.Rate(ms);
    },
    sortData(data, type) {
      let sortData = data.sort((a, b) => {
        if (a[type] < b[type]) {
          return 1;
        }
        if (a[type] > b[type]) {
          return -1;
        }
        return 0;
      });
      return sortData;
    },
  },
};
</script>

<style scoped>
.textAll {
  font-family: "Oxygen" !important;
  font-size: 12px !important;
}
#div_ratechart {
  text-align: center !important;
}
#div_ratechart span {
  cursor: pointer;
  font-size: 13px;
}
#div_ratechart .ratec_active {
  color: #009dd1;
  text-decoration: underline;
}
.table-overview-general tbody tr:nth-of-type(odd) {
  background-color: white;
}
.table-overview-general tbody tr:nth-of-type(even) {
  background-color: #f7f8f9;
}
.table-overview-general thead tr {
  border-color: #c7cfe2 !important;
}
.table-overview-general thead tr td {
  color: #333b52;
  padding-bottom: 10px;
}
.table-overview-general tbody tr td,
.table-overview-general thead tr td {
  color: #333b52;
  font-size: 0.75em;
  text-align: center;
  border: none !important;
}
.table-overview-general tbody tr td {
  color: #616b86;
}
.table-overview-general tbody tr td:first-child {
  cursor: pointer;
  font-weight: bold;
}
.table-overview-general tbody tr td:first-child,
.table-overview-general thead tr td:first-child {
  text-align: left !important;
}

thead tr td {
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: #ffffff;
}

.table {
  height: 200px;
  overflow: scroll;
}
</style>
