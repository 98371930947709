<template>
  <div class="w-100 mb-4">
    <div class="w-100 mb-4">
      <div class="text-lg text-secondary">
        <strong>Campaign scorecards: </strong>
        <span>How much efficient are you?</span>
        <span class="text-secondary ml-1">
          <i
            id="titleCampaign"
            class="fa fa-info-circle text-sm text-skygray"
          ></i>
          <CpTooltipWhiteCustom
            target="titleCampaign"
            title="Tooltip title"
            message="This section will compare active and history campaigns at the time of the query."
            padding-container="16px 12px 23px 10px"
          ></CpTooltipWhiteCustom>
        </span>
      </div>
    </div>
    <div class="w-100 flex-between text-xs">
      <div class="detail_block text-center">
        <p class="font-weight-bold text-lsm mb-2 text-secondary">Campaigns</p>
        <p class="mb-2 text-color-text text-xs">
          How many campaigns <br />
          were executed?
        </p>
        <div class="flex-column flex-center h-40 bg-primary rounded text-white">
          <p class="text-3-5xl font-weight-bold">{{ obj.cantcampanias }}</p>
          <p class="mb-4 text-xs">In date Range</p>
        </div>
      </div>
      <div class="detail_block text-center">
        <p class="font-weight-bold text-lsm mb-2 text-secondary">Impressions</p>
        <p class="mb-2 text-color-text text-xs">
          How many times were  <br/>
          my posts shown?
        </p>
        <div
          class="flex-column flex-center h-40 bg-white rounded position-relative"
        >
          <p class="text-3-5xl text-primary font-weight-bold">
            {{ addCharacter(obj.total_impresiones) }}
          </p>

          <p class="mb-4 text-xs text-color-text text-xs">In date Range</p>
          <div
            class="w-100 bg-gray position-absolute bottom-0 flex-around pb-1"
          >
            <div
              class="p-2 m bg-mainbg rounded"
              style="width: 47.5%; margin-left: 1.5px"
            >
              <p class="color-secondary">
                <strong>{{ addCharacter(obj.paid_impressions) }}</strong>
              </p>
              <p class="text-overflow-dots text-color-text text-xxs">
                Paid Impressions
              </p>
            </div>
            <div
              class="p-2 bg-mainbg rounded"
              style="width: 47.5%; margin-right: 1.5px"
            >
              <p class="color-secondary text-xxs">
                <strong>{{ addCharacter(obj.organic_impressions) }}</strong>
              </p>
              <p class="text-overflow-dots text-color-text text-xxs">
                Organic Impressions
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="detail_block text-center">
        <p class="font-weight-bold text-lsm mb-2 text-secondary">
          Interactions
        </p>
        <p class="mb-2 text-xs text-color-text">
          How many times did people <br />
          interact with my posts?
        </p>
        <div
          class="flex-column flex-center h-40 bg-white rounded position-relative"
        >
          <p class="text-3-5xl text-primary font-weight-bold">
            {{ addCharacter(obj.totalinteractions) }}
          </p>
          <p class="mb-4 text-color-text text-xs">In date Range</p>
          <div
            class="w-100 bg-gray position-absolute bottom-0 flex-around pb-1"
          >
            <div class="p-2 bg-mainbg rounded" style="width: 97%">
              <p class="text-secondary text-xxs">
                <strong>
                  {{
                    Math.round(obj.interactionPorc * 100).toFixed(2)
                  }}
                  %</strong
                >
              </p>
              <p class="text-overflow-dots text-color-text text-xxs">
                Interactions / Impressions
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="detail_block text-center">
        <p class="font-weight-bold text-lsm text-secondary mb-2">Conversions</p>
        <p class="mb-2 text-xs text-color-text">
          What milestones were <br />
          reached during the campaign?
        </p>
        <div class="flex-column flex-center h-40 bg-white rounded">
          <p class="text-3-5xl text-primary font-weight-bold">
            {{ addCharacter(obj.t_conversions) }}
          </p>
          <p class="mb-4 text-color-text text-xs">In date Range</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Helpers, CpTooltipWhiteCustom } from "@isc/styleguide";

export default {
  components: {
    CpTooltipWhiteCustom,
  },
  props: {
    obj: (Array, Object),
  },
  methods: {
    round(value, position, character) {
      let valueArr = value.split("");
      valueArr.splice(valueArr.length - position, 0, ".");
      let valueStr = valueArr.toString().split(",").join("");
      let valueFloat = parseFloat(valueStr).toFixed(2);
      return valueFloat.toString().concat(character);
    },
    addCharacter(value) {
      return Helpers.addCharacter(value);
    },
  },
};
</script>

<style scoped>
.detail_block {
  width: 24%;
}
.text-nums {
  color: #5ca3fc !important;
}
</style>
