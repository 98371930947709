<template>
  <div class="budgettype-content">
    <div class="row my-5 px-5">
      <div class="col-4">
        <strong class="text-primary d-inline-block text-lg">Set Budget</strong>
      </div>
      <div class="col-8">
        <strong class="text-primary d-inline-block text-lg"
          >Choose your Campaign Type <br />
          <span class="text-xs">*Only 1 type can be chosen</span></strong
        >
      </div>
    </div>
    <div class="row px-5">
      <div class="col-4">
        <b-form-group
          id="input-group-currency"
          label="Currency"
          label-for="input-currency"
         
        >
          <CpTooltipWhiteCustom 
            target="input-group-currency"  
            message="The values entered for the campaign are merely informative"
          />
          <b-form-select
            id="input-currency"
            v-model="campaign.idcurrency"
            @change="selectedCurrency"
            :options="currencies"
            value-field="id_moneda"
            text-field="cod_moneda"
            size="sm"
          ></b-form-select>
        </b-form-group>
        <div
          v-if="pressButton && !$v.campaign.currency.required"
          class="message-alert"
        >
          Currency is required
        </div>
        <!-- <b-form-group
          id="input-group-budget"
          label="Budget"
          label-for="input-budget"
          v-b-tooltip title="The values entered for the campaign are merely informative" 
        >
          <b-form-input
            id="input-budget"
            v-model="campaign.budget"
            placeholder="0.00"
            type="text"
            size="sm"
          ></b-form-input>
        </b-form-group> -->
        <b-form-group
          id="input-group-budget"
          label-for="input-budget"
        >
          <p>
            Budget
            <span>
              <i
                id="budgetTooltip"
                class="fa fa-info-circle text-sm text-skygray"
              ></i>
              <CpTooltipWhiteCustom 
                target="budgetTooltip" 
                title="Budget" 
                message="ISC Campaigns can only be organic."
                placement="right"  
              >
              </CpTooltipWhiteCustom>
            </span>
          </p>
          <b-form-input
            id="input-budget"
            v-model="campaign.budget"
            placeholder="0.00"
            type="number"
            size="sm"
          ></b-form-input>
        </b-form-group>
        <div
          v-if="pressButton && !$v.campaign.budget.required"
          class="message-alert"
        >
          Budget is required
        </div>
        <div
          v-if="pressButton && !$v.campaign.budget.decimal"
          class="message-alert"
        >
          Budget should be a number
        </div>
        <div
          v-if="pressButton && !$v.campaign.budget.minValue"
          class="message-alert"
        >
          Budget should be a positive quantity
        </div>
      </div>
      <div class="col-1"></div>
      <div class="col-7">
        <div class="d-flex justify-content-around mb-4">
          <span
            class="span-type-campaign audienceType"
            @click="chooseAudience"
            :class="{ 'span-choosen': audienceChoosen }"
            >Audience</span
          >
          <span
            class="span-type-campaign leadType"
            @click="chooseLeads"
            :class="{ 'span-choosen': leadsChoosen }"
            >Leads</span
          >
        </div>
        <goals-list :campaign="campaign" v-if="audienceChoosen"></goals-list>
        <leads :campaign="campaign" v-if="leadsChoosen"></leads>
        <div
          v-if="pressButton && !$v.campaign.goals.required"
          class="message-alert"
        >
          Goal is required
        </div>
      </div>
    </div>

    <div class="buttons-content mt-5">
      <b-button
        type="button"
        variant="mainbg"
        class="px-4 mx-4 shadow-sm"
        @click="goToNameData()"
        >Prev</b-button
      >
      <b-button
        type="button"
        variant="primary"
        class="px-4 mx-4 shadow-sm"
        @click="nextConfirmation()"
        >Next</b-button
      >
    </div>
  </div>
</template>
<script>
import { required, decimal, minValue } from "vuelidate/lib/validators";
import GoalsList from "../goals/GoalsList";
import Leads from "../goals/Leads.vue";
import * as CampaignService from "@/services/campaign.service";
import { CpTooltipWhiteCustom } from "@isc/styleguide";
export default {
  name: "BudgetType",
  props: ["campaign"],
  components: {
    GoalsList,
    Leads,
    CpTooltipWhiteCustom,
  },
  data() {
    return {
      pressButton: false,
      audienceChoosen: true,
      emptyGoals:false,
      leadsChoosen: false,
      currencies: [],
    };
  },
  mounted() {
    if (this.campaign.type == "Audience") {
      this.audienceChoosen = true;
      this.leadsChoosen = false;
      this.campaign.type = "Audience";
    }

    if (this.campaign.type == "Leads") {
      this.audienceChoosen = false;
      this.leadsChoosen = true;
      this.campaign.type = "Leads";
    }
    this.getCurrency();
  },
  methods: {
    selectedCurrency(e) {
      const currenciasArray = this.currencies.find(
        (option) => option.id_moneda === e
      );
      this.campaign.currency = currenciasArray.cod_moneda;
    },
    nextConfirmation() {
      this.pressButton = true;
      this.$v.$touch();

      if (this.$v.$invalid == true) {
        return false;
      }

      if (this.audienceChoosen) {
        let numberGoals = Object.entries(this.campaign.goals).filter(
          (tupla) => {
            return tupla[1] != null;
          }
        ).length;

        if (!numberGoals) {
          return false;
        }
      }
       if (this.leadsChoosen) {
        let numberGoals = Object.entries(this.campaign.goals).filter(
          (tupla) => {
            return tupla[1] != null;
          }
        ).length;

        if (!numberGoals) {
          return false;
        }
      }

      this.$emit("next", true);
    },
    async getCurrency() {
      this.currencies = await CampaignService.listCurrency();

      if (this.currencies === -1) {
        this.$bvToast.toast("Error cargando Monedas", {
          title: "Error",
          autoHideDelay: 5000,
          appendToast: true,
          variant: "danger",
          solid: true,
        });
      }

      this.currencies.push({
        id_moneda: null,
        cod_moneda: "Select Currency",
        disabled: true,
      });
    },
    goToNameData() {
      this.$emit("prev", true);
    },
    chooseAudience() {
      this.campaign.goals = [];
      this.campaign.table = "";
      this.audienceChoosen = true;
      this.leadsChoosen = false;
      this.campaign.type = "Audience";
    },
    chooseLeads() {
      this.campaign.table = "";
      this.audienceChoosen = false;
      this.leadsChoosen = true;
      this.campaign.type = "Leads";
      this.campaign.goals = [];
    },
  },
  validations: {
    campaign: {
      currency: { required },
      budget: { required, decimal, minValue: minValue(0.0) },
      goals: { required },
    },
  },
};
</script>
<style scoped>
.budgettype-content {
  text-align: center;
}
.message-alert {
  color: red;
  font-size: 12px;
}
.span-type-campaign {
  padding: 1rem;
}
.span-type-campaign {
  background-color: var(--mainbg);
}

.span-choosen {
  background-color: #ffbe28;
  color: white;
}
.audienceType{
  width: 100px;
  height: 50px;
}
.leadType{
  width: 100px;
  height: 50px;
}
</style>
