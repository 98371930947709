var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "configurationEdit-content bg-white pt-4" }, [
    _c(
      "div",
      { staticClass: "containter" },
      [
        _c(
          "b-form",
          [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c("div", { staticClass: "row mb-3" }, [
                    _c("div", { staticClass: "col-4" }, [
                      _c("label", { staticClass: "text-sm text-color-text" }, [
                        _vm._v("Account :"),
                      ]),
                    ]),
                    _c("div", { staticClass: "col" }, [
                      _c("strong", { staticClass: "text-sm text-secondary" }, [
                        _vm._v(_vm._s(_vm.campaign.account_name)),
                      ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "row mb-2" }, [
                    _c("div", { staticClass: "col-4" }, [
                      _c(
                        "label",
                        {
                          staticClass: "text-sm text-color-text",
                          attrs: { for: "input-group-name" },
                        },
                        [_vm._v("Name")]
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "col" },
                      [
                        _c(
                          "b-form-group",
                          { attrs: { id: "input-group-name mb-0" } },
                          [
                            _c("b-form-input", {
                              staticClass: "text-sm text-secondary",
                              attrs: { id: "input-name-edit" },
                              model: {
                                value: _vm.campaign.name,
                                callback: function ($$v) {
                                  _vm.$set(_vm.campaign, "name", $$v)
                                },
                                expression: "campaign.name",
                              },
                            }),
                          ],
                          1
                        ),
                        !_vm.$v.campaign.name.required
                          ? _c(
                              "div",
                              { staticClass: "message-alert text-sm" },
                              [_vm._v(" Name is required ")]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "row mb-2" }, [
                    _c("div", { staticClass: "col-4" }, [
                      _c(
                        "label",
                        {
                          staticClass: "text-sm text-color-text",
                          attrs: { for: "input-group-startDate-edit" },
                        },
                        [_vm._v("Start Date")]
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "col" },
                      [
                        _c(
                          "b-form-group",
                          { attrs: { id: "input-group-startDate-edit" } },
                          [
                            _c("b-form-datepicker", {
                              staticClass: "text-sm text-secondary",
                              attrs: { id: "input-startDate-edit mb-0" },
                              model: {
                                value: _vm.campaign.dates.start,
                                callback: function ($$v) {
                                  _vm.$set(_vm.campaign.dates, "start", $$v)
                                },
                                expression: "campaign.dates.start",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm.pressButton && !_vm.$v.campaign.dates.start.required
                          ? _c(
                              "div",
                              { staticClass: "message-alert text-sm" },
                              [_vm._v(" Start Date is required ")]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "row mb-2" }, [
                    _c("div", { staticClass: "col-4" }, [
                      _c(
                        "label",
                        {
                          staticClass: "text-sm text-color-text",
                          attrs: { for: "input-group-startDate-edit" },
                        },
                        [_vm._v("End Date")]
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "col" },
                      [
                        _c(
                          "b-form-group",
                          { attrs: { id: "input-group-endDate-edit" } },
                          [
                            _c("b-form-datepicker", {
                              staticClass: "text-sm text-secondary",
                              attrs: { id: "input-endDate-edit" },
                              model: {
                                value: _vm.campaign.dates.end,
                                callback: function ($$v) {
                                  _vm.$set(_vm.campaign.dates, "end", $$v)
                                },
                                expression: "campaign.dates.end",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm.pressButton && !_vm.$v.campaign.dates.end.required
                          ? _c(
                              "div",
                              { staticClass: "text-sm message-alert" },
                              [_vm._v(" End Date is required ")]
                            )
                          : _vm._e(),
                        _vm.isValidateDate
                          ? _c(
                              "div",
                              { staticClass: "text-sm message-alert" },
                              [_vm._v(" Invalid date ")]
                            )
                          : _vm._e(),
                        _c("cp-warning-message", {
                          attrs: {
                            message:
                              "You can change the campaign end date while the campaign still active.",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-4" }, [
                      _c("label", { staticClass: "text-sm text-secondary" }, [
                        _vm._v("Type :"),
                      ]),
                    ]),
                    _c("div", { staticClass: "col" }, [
                      _c("strong", { staticClass: "text-sm text-secondary" }, [
                        _vm._v(
                          _vm._s(
                            _vm.campaign.type === "Audiencie"
                              ? "Audience"
                              : _vm.campaign.type
                          )
                        ),
                      ]),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "col-1" }),
              ]),
              _c(
                "div",
                { staticClass: "col" },
                [_c("goals-list-edit", { attrs: { campaign: _vm.campaign } })],
                1
              ),
            ]),
            _c(
              "b-button",
              {
                staticClass: "mt-3",
                attrs: {
                  type: "button",
                  variant: "primary",
                  id: "button-submit-edit",
                },
                on: {
                  click: function ($event) {
                    return _vm.saveCampaign()
                  },
                },
              },
              [
                _c("span", { staticClass: "text-sm text-white" }, [
                  _vm._v(" Save Changes "),
                ]),
              ]
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }