var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "betafonoi" }, [
    _vm.valueFacebook +
      _vm.valueTwitter +
      _vm.valueInstagram +
      _vm.valueYoutube +
      _vm.valueLinkedin ==
      0 && !_vm.isAddonVisit
      ? _c("i", {
          staticClass: "fa fa-ban text-white text-3xl",
          attrs: { title: "No resources found to apply to this stage." },
        })
      : _vm._e(),
    _vm.valueFacebook > 0
      ? _c("i", { staticClass: "fab fa-facebook icointe text-base mr-1" })
      : _vm._e(),
    _vm.valueTwitter > 0
      ? _c("i", { staticClass: "fab fa-twitter icointe text-base mr-1" })
      : _vm._e(),
    _vm.valueInstagram > 0
      ? _c("i", { staticClass: "fab fa-instagram icointe text-base mr-1" })
      : _vm._e(),
    _vm.valueYoutube > 0
      ? _c("i", { staticClass: "fab fa-youtube icointe text-base mr-1" })
      : _vm._e(),
    _vm.valueLinkedin > 0
      ? _c("i", { staticClass: "fab fa-linkedin icointe text-base mr-1" })
      : _vm._e(),
    _vm.valueDirect > 0
      ? _c("i", { staticClass: "fa fa-bullhorn icointe text-base" })
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }