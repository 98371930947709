var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "div-goals" } }, [
    _c(
      "div",
      {
        staticClass: "row px-5",
        staticStyle: { "padding-left": "1rem !important" },
      },
      [
        _c(
          "div",
          { staticClass: "col-4" },
          [
            _c(
              "b-form-group",
              {
                attrs: {
                  id: "input-group-goal-type",
                  label: "Goal",
                  "label-for": "input-goal-type",
                },
              },
              [
                _c("b-form-select", {
                  attrs: {
                    size: "sm",
                    id: "input-goal-type",
                    options: _vm.options,
                    "value-field": "idMetrica",
                    "text-field": "nombreMetrica",
                  },
                  on: {
                    change: function ($event) {
                      return _vm.selectMetric($event)
                    },
                  },
                  model: {
                    value: _vm.newGoalType,
                    callback: function ($$v) {
                      _vm.newGoalType = $$v
                    },
                    expression: "newGoalType",
                  },
                }),
              ],
              1
            ),
            !_vm.newGoalType && _vm.pressButton
              ? _c("span", { staticClass: "span-alert" }, [
                  _vm._v("Type Goal is required"),
                ])
              : _vm._e(),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-4" },
          [
            _c(
              "b-form-group",
              {
                staticStyle: { "margin-top": "32px" },
                attrs: {
                  id: "input-group-goal-number",
                  label: "",
                  "label-for": "input-goal-number",
                },
              },
              [
                _c("b-form-input", {
                  attrs: {
                    size: "sm",
                    id: "input-goal-number",
                    min: "1",
                    label: "&nbsp;&nbsp;&nbsp;&nbsp;",
                    placeholder: "0.0",
                    type: "number",
                  },
                  model: {
                    value: _vm.newGoalNumber,
                    callback: function ($$v) {
                      _vm.newGoalNumber = $$v
                    },
                    expression: "newGoalNumber",
                  },
                }),
              ],
              1
            ),
            !_vm.newGoalNumber && _vm.pressButton
              ? _c("span", { staticClass: "span-alert" }, [
                  _vm._v("Amount of Audience is required"),
                ])
              : _vm._e(),
            _vm.newGoalNumber < 0
              ? _c("span", { staticClass: "span-alert" }, [
                  _vm._v("Amount of Audience must be positive"),
                ])
              : _vm._e(),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-4", staticStyle: { "margin-top": "32px" } },
          [
            _c(
              "b-button",
              {
                staticClass: "mb-4",
                attrs: { variant: "primary", size: "sm" },
                on: {
                  click: function ($event) {
                    return _vm.addGoal()
                  },
                },
              },
              [
                _c("i", {
                  staticClass: "fa fa-plus",
                  staticStyle: { "font-size": "14px" },
                }),
                _vm._v(" Add New"),
              ]
            ),
          ],
          1
        ),
      ]
    ),
    _c("div", [
      _c("table", { staticClass: "table" }, [
        _vm._m(0),
        _c("tbody", { ref: "contenedor", attrs: { id: "containerlistgoals" } }),
      ]),
      _vm.pressButton && _vm.campaign.goals.length == 0
        ? _c("div", { staticClass: "span-alert" }, [
            _vm._v(" You should need almost 1 goal "),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", { staticClass: "text-xs", staticStyle: { display: "none" } }, [
        _c("th", [_vm._v("Type of goal")]),
        _c("th", [_vm._v("Number of goal")]),
        _c("th", { staticStyle: { display: "none" } }, [_vm._v("id goal")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }