var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "w-100 bg-mainbg" },
    [
      _c("bread-crumb"),
      _c(
        "div",
        {
          staticClass: "container container-center p-5",
          attrs: { id: "container-edit" },
        },
        [
          _c("Tabs", {
            attrs: { cant: 2, titles: _vm.titles },
            on: { change: _vm.changeTab },
          }),
          _c(
            "div",
            { staticClass: "bg-white" },
            [
              _vm.activeTab === 0 && _vm.campaign.goals.length > 0
                ? _c("configuration-edit", {
                    attrs: { campaign: _vm.campaign },
                    on: { messageCreated: _vm.messageCreated },
                  })
                : _vm._e(),
              _vm.activeTab === 1
                ? _c("posts-edit", {
                    attrs: { campaign: _vm.campaign },
                    on: { messageCreated: _vm.messageCreated },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }