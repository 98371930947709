<template id="tmline">
  <div class="bg-white mb-4 p-5">
    <div class="d-flex justify-content-between mb-5">
      <div class="text-lsm text-secondary font-weight-bold">
        Timeline of Actions
      </div>
      <div class="d-flex align-items-center">
        <div class="text-xs text-skygray">Zoom:</div>
        <div
          @click="zoom_in"
          class="zoom-button d-flex align-items-center justify-content-center"
        >
          <i class="fa fa-plus"></i>
        </div>
        <div
          @click="zoom_out"
          class="zoom-button d-flex align-items-center justify-content-center"
        >
          <i class="fa fa-minus"></i>
        </div>
      </div>
    </div>

    <div ref="myTimeline" id="mytimeline"></div>
    <contentPost :obj="objpost" ref="modalcont"></contentPost>
  </div>
</template>

<script>
import { GoogleCharts } from "google-charts";
import links from "@/lib/timeline/timeline-min";
import contentPost from "@/components/all/detail-campaign/ContentPostModal";
import { Helpers, mapGetters, Dayjs } from "@isc/styleguide";
//import moment from "moment";
export default {
  name: "Timeline",
  props: {
    fecInis: String,
    fecFinals: String,
    id_campanias: Number,
    dato: Array,
  },
  components: {
    contentPost,
  },
  data() {
    return {
      camp_timeline: null,
      anio: "0000",
      mes: "00",
      //dia: "00",
      dias: "00",
      anioFin: "0000",
      mesFin: "00",
      diasFin: "00",
      datosPost: [],
      objpost: {},
      initialTimeHour: "00:00:00",
      endTimeHour: "00:00:00",
      countryZone: "",
    };
  },
  computed: {
    ...mapGetters({
      userInfo: "settings/profile/getObjUser",
    }),
  },
  methods: {
    opendetailPost(temp) {
      if (temp == 1) this.$refs.modalcont.show();
    },
    async campaniasDet() {
      //* breaking up initial date
      let splitDateInitial = this.fecInis.split(" ");
      this.initialTimeHour = splitDateInitial[1];
      let fecha = splitDateInitial[0].split("-");
      this.anio = fecha[0];
      this.mes = fecha[1];
      this.dias = fecha[2];
      //* breaking up end date
      let splitDateEnd = this.fecFinals.split(" ");
      this.endTimeHour = splitDateEnd[1];
      let fechaFin = splitDateEnd[0].split("-");
      this.anioFin = fechaFin[0];
      this.mesFin = fechaFin[1];
      this.diasFin = fechaFin[2];
    },
    zoom_in() {
      this.camp_timeline.zoom(0.1);
    },
    zoom_out() {
      this.camp_timeline.zoom(-0.1);
    },
    drawChart() {
      const data = new GoogleCharts.api.visualization.DataTable();
      data.addColumn("datetime", "start");
      data.addColumn("datetime", "end");
      data.addColumn("string", "content");
      let arrRowsTimeline = [];
      let startTimeLine = [
        Dayjs([
          this.anio +
            "-" +
            this.mes +
            "-" +
            this.dias +
            " " +
            this.initialTimeHour,
        ]).toDate(),
        ,
        '<a class="ts" style="background:#ffbd28">Start</a>',
      ];
      arrRowsTimeline.push(startTimeLine);
      this.dato.forEach((timeLine) => {
        var clase = "";
        if (timeLine.id_red_social == 1) {
          clase = '<a class="ts" style="background:#1877f2">Facebook Post</a>';
        }
        if (timeLine.id_red_social == 2) {
          clase =
            '<a class="ts" style="background:rgb(29, 161, 242)">Tweet</a>';
        }
        if (timeLine.id_red_social == 3) {
          clase =
            '<a class="ts" style="background:rgb(255, 105, 161)">Instagram Post</a>';
        }
        if (timeLine.id_red_social == 4) {
          clase =
            '<a class="ts" style="background:rgb(255, 101, 100)">Video</a>';
        }
        if (timeLine.id_red_social == 5) {
          clase = '<a class="ts" style="background:#0073b1">LinkedIn Post</a>';
        }

        let arrObj = [this.convertNewDate(timeLine.post_fecha), , clase];
        arrRowsTimeline.push(arrObj);
      });
      console.log("End time hour ", this.endTimeHour);
      let endTimeLine = [
        Dayjs(
          this.anioFin +
            "-" +
            this.mesFin +
            "-" +
            this.diasFin +
            " " +
            this.endTimeHour
        ).toDate(),

        ,
        '<a class="ts" style="background:#ffbd28">End</a>',
      ];
      arrRowsTimeline.push(endTimeLine);
      data.addRows(arrRowsTimeline);
      var options = {
        width: "100%",
        height: "100%",
        style: "box", // optional
        zoomable: true,
        node: {
          interactivity: true,
        },
      };

      this.camp_timeline = new links.Timeline(this.$refs.myTimeline);
      let temp = this.camp_timeline;
      let temp2 = this.dato;

      var that = this;
      google.visualization.events.addListener(temp, "select", function () {
        let selection = temp.getSelection();
        if (!selection[0]) return;
        let numbSelectionRow = selection[0].row;
        // validation if the selecction is first and last from Array
        if (
          numbSelectionRow === 0 ||
          numbSelectionRow === arrRowsTimeline.length - 1
        )
          return;

        let rowselect = arrRowsTimeline[numbSelectionRow];
        let post = temp2.find((item) => {
          let ls = that.convertNewDate(item.post_fecha);
          if (ls.getTime() == rowselect[0].getTime()) {
            return item;
          }
        });
        that.objpost = post;
        that.opendetailPost(1);
      });
      this.opendetailPost(that);
      this.camp_timeline.draw(data, options);
    },
    convertNewDate(date) {
      const postDateFormat = Helpers.transformStringDate(
        date,
        this.countryZone,
        "YYYY-MM-DD HH:mm:ss"
      );
      let splitDatePost = postDateFormat.split(" ");
      let hourPost = splitDatePost[1];
      let _date = splitDatePost[0].split("-");
      let year = _date[0];
      let month = _date[1];
      let days = _date[2];
      return Dayjs([year + "-" + month + "-" + days + " " + hourPost]).toDate();
    },
  },
  mounted() {
    this.campaniasDet();
    GoogleCharts.load(this.drawChart);
    this.countryZone = this.userInfo.TIME_ZONE;
  },
};
</script>

<style>
.zoom-button {
  cursor: pointer;
  width: 35px;
  height: 35px;
  line-height: 35px;
  padding: 0;
  color: #616b86;
  background: #f3f4f7;
  margin-left: 8px;
  border-radius: 3px;
  box-shadow: 0px 2px 3.9px 0.1px rgba(0, 0, 0, 0.15);
  border: solid 1px #c7cfe2;
}
.zoom-button:hover {
  background: var(--primary);
  border-color: var(--primary);
  color: white;
}
.zoom-button i {
  font-size: 12px;
}
.ts {
  padding: 10px 30px !important;
  font-size: 10px;
}
.timeline-event {
  background: none !important;
  padding: 0px !important;
  margin: 0px !important;
}
.timeline-event:hover {
  background: none !important;
}
.timeline-event-box {
  padding: 0px !important;
  margin: 0px !important;
  border: none !important;
}
#mytimeline a {
  color: white !important;
}
#mytimeline .timeline-event-box {
  border-bottom: none !important;
  margin: 0px !important;
  padding: 0px !important;
}
@media (max-width: 1024px) {
  #mytimeline {
    height: 330px !important;
  }
}
</style>
