<template>
  <div id="div-goals">
    <div class="row px-5" style="padding-left: 1rem !important;">
      <div class="col-4">
        <b-form-group
          id="input-group-goal-type"
          label="Goal"
          label-for="input-goal-type"
        >
          <b-form-select
            size="sm"
            id="input-goal-type"
            v-model="newGoalType"
            :options="options"
            @change="selectMetric($event)"
            value-field="idMetrica"
            text-field="nombreMetrica"
          ></b-form-select>
        </b-form-group>
        <span
          v-if="!newGoalType && pressButton"
          class="span-alert"
          >Type Goal is required</span
        >
      </div>
      <div class="col-4">
        <b-form-group
          id="input-group-goal-number"
          label=""
          label-for="input-goal-number"
          style="margin-top:32px"
        >
          <b-form-input
            size="sm"
            id="input-goal-number"
            min="1"
            v-model="newGoalNumber"
            label="&nbsp;&nbsp;&nbsp;&nbsp;"
            placeholder="0.0"
            type="number"
          ></b-form-input>
        </b-form-group>
        <span
          v-if="!newGoalNumber && pressButton"
          class="span-alert"
          >Amount of Audience is required</span
        >
        <span
          v-if="newGoalNumber < 0"
          class="span-alert"
          >Amount of Audience must be positive</span
        >
      </div>
      <div class="col-4" style="margin-top:32px">
        <b-button variant="primary" @click="addGoal()" size="sm" class="mb-4">
          <i class="fa fa-plus" style="font-size:14px"></i> Add New</b-button
        >
      </div>
    </div>

    <div>
      <table class="table">
        <thead>
          <tr class="text-xs" style="display:none;">
            <th>Type of goal</th>
            <th>Number of goal</th>
            <th style="display:none;">id goal</th>
          </tr>
        </thead>
        <tbody id="containerlistgoals" ref="contenedor"></tbody>
      </table>
      <div
        v-if="pressButton && campaign.goals.length == 0"
        class="span-alert"
      >
        You should need almost 1 goal
      </div>
    </div>
  </div>
</template>
<script>
import * as CampaignService from "@/services/campaign.service";
export default {
  name: "GoalsList",
  props: ["campaign"],

  data() {
    return {
      pressButton: false,
      options: [],
      arrayGoals: [],
      newGoalType: null,
      nameMetric: "",
      newGoalNumber: null,
    };
  },
  computed: {
    emptyGoals() {
      if (this.campaign.goals) {
        return !Object.entries(this.campaign.goals).filter((tupla) => {
          return tupla[1] != null;
        }).length;
      } else {
        return true;
      }
    },
  },
  mounted() {
    this.$nextTick(function() {
      const tableHtml = document.getElementById("containerlistgoals");
      tableHtml.innerHTML = this.campaign.table;
    });
  },
  created() {
    this.optionsSorted();
  },
  methods: {
    async optionsSorted() {
      this.options = await CampaignService.listMetrics("Audiencie");
      if (this.options != undefined) {
        this.options.push({
          nombreMetrica: "Select a metric",
          idMetrica: null,
        });
        if (this.options.length == 0) {
          return [
            {
              nombreMetrica: "Select a metric",
              idMetrica: null,
              disabled: true,
            },
          ];
        }
        return this.options.sort();
      }
    },
    selectMetric(idMetrica) {
      const metricaArray = this.options.find(
        (option) => option.idMetrica === idMetrica
      );
      this.nameMetric = metricaArray.nombreMetrica;
    },
    addGoal() {
      this.numberGoalValidation();
      if (this.newGoalType && this.newGoalNumber > 0) {
        this.pressButton = false;
        const containerGoals = document.querySelector("#containerlistgoals");
        const row_goal = document.createElement("tr");

        const cell_goal_type = document.createElement("td");
        cell_goal_type.textContent = `${this.newGoalType}`;
        cell_goal_type.style = "display:none;";

        const cell_goal_name = document.createElement("td");
        cell_goal_name.textContent = `${this.nameMetric}`;

        const cell_goal_number = document.createElement("td");
        cell_goal_number.innerHTML =
          "<input type='text' class='form-control form-control-sm' value='" +
          `${this.newGoalNumber}` +
          "'>";

        row_goal.appendChild(cell_goal_name);
        row_goal.appendChild(cell_goal_number);
        row_goal.appendChild(cell_goal_type);

        if (this.campaign.goals.length > 0) {
          const metricaArray = this.campaign.goals.find(
            (option) => option.newGoalType === this.newGoalType
          );
          if (metricaArray != undefined) {
            if (metricaArray.newGoalType > 0) {
              this.$bvToast.toast("Goal already exist.", {
                variant: "danger",
                solid: true,
                title: "Message",
              });

              return false;
            }
          }
        }

        containerGoals.appendChild(row_goal);

        this.campaign.goals.push({
          newGoalType: this.newGoalType,
          nameMetric: this.nameMetric,
          newGoalNumber: this.newGoalNumber,
        });

        this.options = this.options.filter((option) => {
          return option != this.newGoalType;
        });

        this.newGoalType = null;
        this.newGoalNumber = null;

        const tableHtml = document.getElementById("containerlistgoals");
        this.campaign.table = tableHtml.innerHTML;
      } else {
        this.pressButton = true;
        return;
      }
    },
    numberGoalValidation() {
      if (!this.newGoalNumber) return;
      if (this.newGoalNumber < 0) return;
      //if (this.newGoalNumber % 1 != 0) return;
    },
  },
};
</script>
<style scoped>
.span-alert {
  color: red;
  /* margin-left: 30%; */
  font-size: 12px;
  /* font-weight: bolder; */
}
#div-goals {
  position: relative;
  border: 1px solid #cdd1da;
  padding: 8px;
  border-radius: 4px;
  /* width: 510px; */
  color: #9ca6b0;
  margin: 10px;
  margin-top: 22px;
}
</style>
