var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "p-4", attrs: { id: "posts-edit-content" } },
    [
      _c("div", { staticClass: "mb-3" }, [
        _c(
          "span",
          { staticClass: "text-lsm text-secondary font-weight-bold mr-1" },
          [_vm._v("Posts of: ")]
        ),
        _c("span", { staticClass: "text-lg text-color-text" }, [
          _vm._v(_vm._s(_vm.campaign.name)),
        ]),
      ]),
      _c("table", { staticClass: "table" }, [
        _vm._m(0),
        _c(
          "tbody",
          { attrs: { id: "posts-container" } },
          _vm._l(_vm.campaign.posts, function (post) {
            return _c("post-edit", {
              key: post.key,
              attrs: { post: post },
              on: { deletePost: _vm.deletePost },
            })
          }),
          1
        ),
      ]),
      _c(
        "b-modal",
        {
          ref: "modal-delete-post",
          attrs: { "hide-footer": "", title: "Delete Post" },
        },
        [
          _c("div", { staticClass: "d-block text-center" }, [
            _c("h3", [
              _vm._v("Are you sure that you want to delete this post?"),
            ]),
          ]),
          _c(
            "b-button",
            {
              staticClass: "mt-3",
              attrs: { variant: "outline-warning", block: "" },
              on: {
                click: function ($event) {
                  return _vm.hideModal()
                },
              },
            },
            [_vm._v("Cancel")]
          ),
          _c(
            "b-button",
            {
              staticClass: "mt-2",
              attrs: { variant: "outline-danger", block: "" },
              on: {
                click: function ($event) {
                  return _vm.confirmDeletePost()
                },
              },
            },
            [_vm._v("Ok")]
          ),
        ],
        1
      ),
      _c(
        "b-button",
        {
          staticClass: "bg-primary border-0",
          attrs: { id: "button-go-campaigns" },
          on: {
            click: function ($event) {
              return _vm.goCampaigns()
            },
          },
        },
        [
          _c("span", { staticClass: "text-xs text-white" }, [
            _vm._v("Go to all Campaigns"),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", { staticClass: "pt-2 pb-2" }, [
          _c(
            "span",
            { staticClass: "text-xs text-secondary font-weight-bold" },
            [_vm._v("Title Post")]
          ),
        ]),
        _c("th", { staticClass: "pt-2 pb-2" }, [
          _c(
            "span",
            { staticClass: "text-xs text-secondary font-weight-bold" },
            [_vm._v("Social Network")]
          ),
        ]),
        _c("th", { staticClass: "pt-2 pb-2" }, [
          _c(
            "span",
            { staticClass: "text-xs text-secondary font-weight-bold" },
            [_vm._v("Publish Date")]
          ),
        ]),
        _c("th", { staticClass: "pt-2 pb-2" }, [
          _c(
            "span",
            { staticClass: "text-xs text-secondary font-weight-bold" },
            [_vm._v("Delete Post")]
          ),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }