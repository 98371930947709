var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "modal-contant" }, [
    _c(
      "div",
      {
        staticClass:
          "d-flex justify-content-center mb-4 text-center label-list",
      },
      [
        _c(
          "span",
          {
            staticClass: "span-progress",
            class: { "paint-span": _vm.nameDate },
          },
          [_vm._v("Name and Date")]
        ),
        _c(
          "span",
          {
            staticClass: "span-progress",
            class: { "paint-span": _vm.budgetType },
          },
          [_vm._v("Budget and Type")]
        ),
        _c(
          "span",
          {
            staticClass: "span-progress",
            class: { "paint-span": _vm.confirmation },
          },
          [_vm._v("Confirmation")]
        ),
      ]
    ),
    _c(
      "div",
      { staticClass: "container" },
      [
        _c(
          "b-form",
          {
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.onSubmit.apply(null, arguments)
              },
            },
          },
          [
            _vm.nameDate
              ? _c("name-date", {
                  attrs: { campaign: _vm.campaign },
                  on: { next: _vm.nextBudget, closeModal: _vm.closeModal },
                })
              : _vm._e(),
            _vm.budgetType
              ? _c("budget-type", {
                  attrs: { campaign: _vm.campaign },
                  on: { next: _vm.nextConfirmation, prev: _vm.prevNameData },
                })
              : _vm._e(),
            _vm.confirmation
              ? _c("confirmation", {
                  attrs: { campaign: _vm.campaign },
                  on: {
                    prev: _vm.prevBudget,
                    closeModal: _vm.closeModal,
                    messageCreated: _vm.messageCreated,
                    submit: _vm.submit,
                    "active-loader": _vm.activeLoader,
                    "inactive-loader": _vm.inactiveLoader,
                  },
                })
              : _vm._e(),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }