<template>
  <div class="betafonoi">
    <i
      v-if="
        valueFacebook +
          valueTwitter +
          valueInstagram +
          valueYoutube +
          valueLinkedin ==
        0 && !isAddonVisit
      "
      class="fa fa-ban text-white text-3xl"
      title="No resources found to apply to this stage."
    ></i>
    <i v-if="valueFacebook > 0" class="fab fa-facebook icointe text-base mr-1"></i>
    <i v-if="valueTwitter > 0" class="fab fa-twitter icointe text-base mr-1"></i>
    <i v-if="valueInstagram > 0" class="fab fa-instagram icointe text-base mr-1"></i>
    <i v-if="valueYoutube > 0" class="fab fa-youtube icointe text-base mr-1"></i>
    <i v-if="valueLinkedin > 0" class="fab fa-linkedin icointe text-base mr-1"></i>
    <i v-if="valueDirect > 0" class="fa fa-bullhorn icointe text-base"></i>
  </div>
</template>

<script>
export default {
  name: "CpIcons",
  props: {
    valueFacebook: {
      type: Number,
      value: 0,
    },
    valueInstagram: {
      type: Number,
      value: 0,
    },
    valueTwitter: {
      type: Number,
      value: 0,
    },
    valueYoutube: {
      type: Number,
      value: 0,
    },
    valueLinkedin: {
      type: Number,
      value: 0,
    },
    valueDirect: {
      type: Number,
      value: 0,
    },
    isAddonVisit: {
      type: Boolean,
      value: false,
    },
  },
};
</script>

<style lang="css" scoped>
.betafonoi {
  text-align: center;
}
</style>
