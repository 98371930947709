<template>
  <div class="w-100 bg-mainbg">
    <loader-custom v-if="visibilityLoader === true"></loader-custom>
    <div class="container-cabecera"></div>
    <bread-crumb />
    <div class="mt-4 w-100">
      <div class="detail-content-box">
        <div class="all-select-st">
          <div class="d-flex justify-content-between">
            <div class="d-flex align-items-center">
              <span class="text-lsm text-secondary mr-2 font-weight-bold pl-2"
                >Select accounts</span
              >
              <multi-select-custom
                class="campaign-selected-accounts"
                :selected="selectedDefaultAccount"
                :options="getListAllAccounts(marcas)"
                @selectValue="setAccount"
              />
            </div>
            <div>
              <b-button
                id="show-btn"
                variant="primary"
                class="shadow-btn rounded-3 px-5 py-2"
                @click="showModal"
              >
                <span class="text-white text-xs"> New campaign </span>
              </b-button>
            </div>
          </div>
        </div>
        <div class="">
          <CampaignBox
            :campaignsDet="detallAlls"
            :redSocCamps="this.redSocCamp"
            @updateDetail="consultaAll(1)"
          >
          </CampaignBox>
        </div>
        <div>
          <div class="">
            <div id="paginado">
              <ul id="pagination" class="mr-0">
                <li
                  :class="[
                    'li_paginado',
                    index == currentpage ? 'select_paginado' : '',
                  ]"
                  @click="consultaAll(index)"
                  v-for="index in totalPages"
                  :key="index"
                  id="1"
                  style=""
                >
                  {{ index }}
                </li>
              </ul>
              <div class="clear"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal
      ref="modal-campaign"
      no-close-on-backdrop
      size="xl"
      header-class="border-0 pr-5"
      header-close-variant="primary"
      hide-footer
    >
      <modal-campaign
        @closeModal="closeModal"
        @messageCreated="messageCreated"
        @active-loader="visibilityLoader = true"
        @inactive-loader="visibilityLoader = false"
      ></modal-campaign>
    </b-modal>
    <IscModalAlerts
      v-model="showAlert"
      :icon="icon"
      :iconColor="iconColor"
      :title="title"
      :paragraph="paragraph"
      :buttonMessage="buttonMessage"
      @sendResult="resultModalAlerts"
      :noCloseBackdrop="true"
      :refresh="true"
    >
    </IscModalAlerts>
  </div>
</template>

<script>
import {
  BreadCrumb,
  loaderCustom,
  MultiSelectCustom,
  AlertSetting,
  IscModalAlerts,
  UserObjMixin
} from "@isc/styleguide";
import CampaignBox from "@/components/all/dashboard/CampaignBox.vue";
import ModalCampaign from "@/components/all/create-campaign/ModalCampaign.vue";
import * as CampaignService from "@/services/campaign.service";
export default {
  name: "All",
  components: {
    BreadCrumb,
    CampaignBox,
    ModalCampaign,
    loaderCustom,
    MultiSelectCustom,
    IscModalAlerts,
  },
  mixins: [AlertSetting, UserObjMixin],
  data() {
    return {
      detallAllsTemp: [],
      detallAlls: [],
      marcas: [],
      redSocCamp: [],
      histRedSocial: [],
      totalPage: [],
      currentpage: 1,
      totalPages: 1,
      visibilityLoader: false,
      selectedDefaultAccount: { text: "All Accounts", value: 0 },
      accountsList: [],
    };
  },
  async mounted() {
    this.visibilityLoader = true;
    await this.setCurrentObjUser();// function from styleguide (Mixin)
    await this.listarMarcas();
    await this.consultaAll(1);
    console.log("NODE_ENV- campaign (All Module): ", process.env.NODE_ENV);
  },
  methods: {
    showModal() {
      this.$refs["modal-campaign"].show();
    },
    async closeModal(closeModal) {
      if (closeModal) {
        this.$refs["modal-campaign"].hide();
        await this.listarMarcas();
        await this.consultaAll(1);
      }
    },
    messageCreated(created) {
      if (created) {
        this.$bvToast.toast("Campaign Created.", {
          title: "Message",
          variant: "success",
          autoHideDelay: 60000,
          solid: true,
          title: "Message",
        });
      }
    },
    async listarMarcas() {
      const data = this.userInfo;
      this.marcas = await CampaignService.listaMarca(data.id_cliente);
    },
    async consultaAll(page = 1) {
      try {
        const data = this.userInfo;
        let valor = this.selectedDefaultAccount.value;
        this.visibilityLoader = true;
        this.detallAllsTemp = await CampaignService.detallAll(
          valor,
          data.id_cliente,
          page
        );
        this.detallAlls = this.detallAllsTemp["temp"];
        this.currentpage = this.detallAllsTemp.pagination.currentPage;
        this.totalPages = this.detallAllsTemp.pagination.totalPages;
      } catch (error) {
        console.error("el error: ", error);
      } finally {
        this.visibilityLoader = false;
      }
    },
    getListAllAccounts(marcas) {
      let listAccounts = marcas.map((marca) => ({
        text: marca.dscMarca,
        value: marca.idMarca,
      }));
      listAccounts.unshift({ text: "All Accounts", value: 0 });
      return listAccounts;
    },
    async setAccount(account) {
      this.selectedDefaultAccount = account;
      await this.consultaAll(1);
    },
  },
};
</script>
<style scoped>
.contenedor {
  width: 100%;
  height: 882px;
  margin: auto;
  background-color: #f3f4f7;
}
.container-cabecera {
  width: 100%;
  margin: auto;
}
.campaign-selected-accounts :deep(.multiselect__tags) {
  width: 250px;
}
@media (min-width: 1366px) {
  .modal-xl {
    max-width: 100%;
  }
}

.modal-contant {
  padding: 20px 20px 0 80px !important;
}

.container {
  width: 100%;
  font-family: "Oxigen", sans-serif;
}
.container-detalls {
  width: 100%;
  margin: auto;
  font-family: "Oxigen", sans-serif;
}
.newCampaign {
  max-width: 100%;
  margin: auto;
  height: 35px;
  margin: 16px 0px 0px -5px;
  margin-left: -90px;
}
.select {
  height: 35px;
  width: 250px;
  margin-left: 10px;
  box-shadow: 1px 1px 11px #5bbef0;
  border: 2px solid #5bbef0;
}
.textos {
  font-family: Oxygen;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.58;
  letter-spacing: normal;
  text-align: left;
  color: #616b86;
}
.boton {
  width: 171px;
  height: 35px;
  margin: 0px 0px 0px 247px;

  padding: 14px 59px 9px 44px;
  border-radius: 3px;
  box-shadow: 0px 2px 3.9px 0.1px rgba(0, 0, 0, 0.15);
  background-color: var(--azure);
}

#paginado {
  margin-top: 0.8em;
}
#pagination {
  float: right;
}
#pagination {
  text-align: center;
  margin: 0px;
  padding: 0px;
  margin-bottom: 10px;
  margin-right: 5%;
}

.select_paginado {
  background-color: rgb(0, 165, 255) !important;
  color: rgb(255, 255, 255) !important;
  border: 1px solid rgb(0, 165, 255) !important;
}

.li_paginado {
  height: 35px;
  width: 35px;
  background-color: transparent;
  border-radius: 0.5em;
  color: #5d6b88;
  line-height: 1.6em;
  border: 1px solid #c2d1e6;
  cursor: pointer;
}
.li_paginado {
  list-style: none;
  float: left;
  margin-right: 2px;
  padding: 7px;
  background: #e7eaef;
  font-size: 12px !important;
}
.li_paginado:hover {
  background-color: rgb(0, 165, 255) !important;
  color: rgb(255, 255, 255) !important;
  border: 1px solid rgb(0, 165, 255) !important;
}
</style>
