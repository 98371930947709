var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "contenedoressx" },
    [
      _vm.visibilityLoader ? _c("loader-custom") : _vm._e(),
      _c("bread-crumb"),
      _c(
        "div",
        { staticClass: "contenedores detail-content-box" },
        [
          _c(
            "div",
            {
              staticClass:
                "d-flex align-items-center justify-content-between mt-3 mb-3",
            },
            [
              _c(
                "div",
                { staticClass: "d-flex align-items-center" },
                [
                  _c(
                    "div",
                    { staticClass: "d-flex align-items-center" },
                    [
                      _c(
                        "span",
                        {
                          staticClass:
                            "font-weight-bold text-lg text-secondary",
                        },
                        [_vm._v(_vm._s(this.marcaRes) + " ")]
                      ),
                      _c("i", {
                        staticClass: "fa fa-info-circle iconInfoCampView",
                        attrs: { id: "allViewTitle" },
                      }),
                      _c("CpTooltipWhiteCustom", {
                        attrs: {
                          target: "allViewTitle",
                          title: "allViewTitle",
                          paddingContainer: "15px 5px 10px 15px",
                          message:
                            "The campaign analysis depends on the start and end date of the campaign.",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._l(_vm.redSocialCamp, function (idRedSocial, index) {
                    return _c("div", { key: index }, [
                      idRedSocial == 1
                        ? _c("i", {
                            staticClass: "mr-2 fab fa-facebook text-facebook",
                            on: {
                              click: function ($event) {
                                return _vm.abrirpestania(
                                  _vm.itemsq.dscUrlRedsocial
                                )
                              },
                            },
                          })
                        : _vm._e(),
                      idRedSocial == 4
                        ? _c("i", {
                            staticClass: "mr-2 fab fa-youtube text-youtube",
                            on: {
                              click: function ($event) {
                                return _vm.abrirpestania(
                                  _vm.itemsq.dscUrlRedsocial
                                )
                              },
                            },
                          })
                        : _vm._e(),
                      idRedSocial == 3
                        ? _c("i", {
                            staticClass: "mr-2 fab fa-instagram text-instagram",
                            on: {
                              click: function ($event) {
                                return _vm.abrirpestania(
                                  _vm.itemsq.dscUrlRedsocial
                                )
                              },
                            },
                          })
                        : _vm._e(),
                      idRedSocial == 2
                        ? _c("i", {
                            staticClass: "mr-2 fab fa-twitter text-twitter",
                            on: {
                              click: function ($event) {
                                return _vm.abrirpestania(
                                  _vm.itemsq.dscUrlRedsocial
                                )
                              },
                            },
                          })
                        : _vm._e(),
                      idRedSocial == 5
                        ? _c("i", {
                            staticClass: "mr-2 fab fa-linkedin text-linkedin",
                            on: {
                              click: function ($event) {
                                return _vm.abrirpestania(
                                  _vm.itemsq.dscUrlRedsocial
                                )
                              },
                            },
                          })
                        : _vm._e(),
                    ])
                  }),
                ],
                2
              ),
              _c(
                "span",
                { staticClass: "font-weight-bold text-lg text-primary" },
                [_vm._v(_vm._s(this.campaniaDescs))]
              ),
            ]
          ),
          _vm.datos.length > 0
            ? _c("Timeline", {
                attrs: {
                  dato: this.datos,
                  fecInis: _vm.transformDate(
                    this.redSocialCamp[0].fecIni,
                    _vm.userInfo.TIME_ZONE,
                    "YYYY-MM-DD HH:mm:ss"
                  ),
                  fecFinals: _vm.transformDate(
                    this.redSocialCamp[0].fecFinal,
                    _vm.userInfo.TIME_ZONE,
                    "YYYY-MM-DD HH:mm:ss"
                  ),
                  id_campanias: Number(this.$route.query.campaign),
                },
              })
            : _vm._e(),
          _c("Funnel", { attrs: { funneldata: _vm.funneldata } }),
          _c(
            "div",
            { staticClass: "bg-white" },
            [
              _c("tabs", {
                attrs: { cant: 3, titles: _vm.tabs },
                on: { change: _vm.changeTab },
              }),
              _c(
                "div",
                { staticClass: "w-100 p-3" },
                [
                  _vm.activeTab === 0 && _vm.userInfo
                    ? _c("action", {
                        attrs: {
                          fec_ini: _vm.fechaInicio,
                          fec_fin: _vm.fechaFinal,
                          timezoneId: _vm.userInfo.ID_ZONA_HORARIA,
                        },
                      })
                    : _vm.activeTab === 1 && _vm.userInfo
                    ? _c("audience-conversion", {
                        attrs: { idCampaniasx: this.idCampaniass },
                      })
                    : _vm.activeTab === 2 && _vm.userInfo
                    ? _c("semantic-emotion", {
                        attrs: {
                          fec_ini: _vm.fechaInicio,
                          fec_fin: _vm.fechaFinal,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("IscModalAlerts", {
        attrs: {
          icon: _vm.icon,
          iconColor: _vm.iconColor,
          title: _vm.title,
          paragraph: _vm.paragraph,
          buttonMessage: _vm.buttonMessage,
          noCloseBackdrop: true,
          refresh: true,
        },
        on: { sendResult: _vm.resultModalAlerts },
        model: {
          value: _vm.showAlert,
          callback: function ($$v) {
            _vm.showAlert = $$v
          },
          expression: "showAlert",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }