var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "interactiones" }, [
    _c(
      "div",
      { staticClass: "interactionGeneral__box" },
      _vm._l(_vm.reactions, function (item, index) {
        return _c(
          "div",
          { key: index, staticClass: "interactionGeneral__box--line" },
          [
            _c("div", { staticClass: "interactionGeneral__box--line__ico" }, [
              _c("img", { staticClass: "h-5 mr-1", attrs: { src: item.ico } }),
              _c("span", [
                _vm._v(_vm._s(item.name === "like" ? "Like" : item.name)),
              ]),
            ]),
            _c("div", { staticClass: "interactionGeneral__box--line__range" }, [
              _c(
                "div",
                {
                  staticClass: "interactionGeneral__box--line__range--baseLine",
                },
                [
                  _c("div", {
                    staticClass:
                      "interactionGeneral__box--line__range--baseLine__width",
                    style: { width: `${item.pctValue}%` },
                  }),
                ]
              ),
            ]),
            _c("div", { staticClass: "interactionGeneral__box--line__count" }, [
              _c("span", [_vm._v(_vm._s(item.val))]),
            ]),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }