var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("tr", { attrs: { id: _vm.post.key } }, [
    _c("td", [
      _c("span", { staticClass: "text-xs text-color-text font-weight-bold" }, [
        _vm._v(_vm._s(_vm.post.title)),
      ]),
    ]),
    _c("td", [
      _c("span", { staticClass: "text-xs text-color-text" }, [
        _vm._v(_vm._s(_vm.post.social_network)),
      ]),
    ]),
    _c("td", [
      _c("span", { staticClass: "text-xs text-color-text" }, [
        _vm._v(_vm._s(_vm.post.publish_date)),
      ]),
    ]),
    _c("td", [
      _c(
        "span",
        {
          staticClass: "delete-post",
          on: {
            click: function ($event) {
              return _vm.emitDeletePost()
            },
          },
        },
        [
          _c("i", {
            staticClass: "fa fa-trash text-xs text-color-tex",
            attrs: { "aria-hidden": "true" },
          }),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }