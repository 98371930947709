<template>
  <div class="funnel mb-4">
    <div style="text-align: left; margin-bottom: 20px; margin-top: 0">
      <div class="elti1">Social Media Conversion Funnel</div>
      <div class="elti1sub">The best way to turn strangers into customers</div>
    </div>
    <div class="row funnel_row pl-2 pr-2">
      <div class="box_funnel_01 d-flex flex-column justify-content-between">
        <div class="mb-2 text-center">
          <span class="text-xs text-theme">Actions</span>
        </div>
        <div
          class="engagement-item-1 text-white d-flex flex-column justify-content-between"
        >
          <div class="top">
            <div
              class="d-flex align-items-center justify-content-between mb-1 pb-1"
              v-if="funneldata.facebook.posts > 0"
            >
              <div><span class="text-lsm">Facebook Posts</span></div>
              <div>
                <span class="font-weight-bold text-lsm">{{
                  funneldata.facebook.posts
                }}</span>
              </div>
            </div>
            <div
              class="d-flex align-items-center justify-content-between mb-1 pb-1"
              v-if="funneldata.twitter.posts > 0"
            >
              <div><span class="text-lsm">Tweets</span></div>
              <div>
                <span class="font-weight-bold text-lsm">{{
                  funneldata.twitter.posts
                }}</span>
              </div>
            </div>
            <div
              class="d-flex align-items-center justify-content-between mb-1 pb-1"
              v-if="funneldata.instagram.posts > 0"
            >
              <div><span class="text-lsm">Instagram Posts</span></div>
              <div>
                <span class="font-weight-bold text-lsm">{{
                  funneldata.instagram.posts
                }}</span>
              </div>
            </div>
            <div
              class="d-flex align-items-center justify-content-between mb-1 pb-1"
              v-if="funneldata.youtube.posts > 0"
            >
              <div><span class="text-lsm">Videos</span></div>
              <div>
                <span class="font-weight-bold text-lsm">{{
                  funneldata.youtube.posts
                }}</span>
              </div>
            </div>
            <div
              class="d-flex align-items-center justify-content-between"
              v-if="funneldata.linkedin.posts > 0"
            >
              <div><span class="text-lsm">LinkedIn Posts</span></div>
              <div>
                <span class="font-weight-bold text-lsm">{{
                  funneldata.linkedin.posts
                }}</span>
              </div>
            </div>
          </div>
          <div class="bottom d-flex align-items-center justify-content-between">
            <div><span class="font-weight-bold text-lsm">Total</span></div>
            <div>
              <span class="font-weight-bold text-lsm">
                {{
                  funneldata.facebook.posts +
                  funneldata.twitter.posts +
                  funneldata.instagram.posts +
                  funneldata.youtube.posts +
                  funneldata.linkedin.posts
                }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="box_funnel_02">
        <div class="mb-2 text-center">
          <span class="text-xs text-theme">Conversion</span>
        </div>
        <div class="position-relative">
          <img src="../../../assets/all-images/funnel.svg" alt="" />
          <div class="box_into_funnel d-flex text-white">
            <div class="bx_ b_a">
              <div class="xengagement-item-2">
                <div class="middle">
                  <div class="icon text-2xl">
                    <i class="fa fa-eye" aria-hidden="true"></i>
                  </div>
                  <cp-icons
                    :value-facebook="funneldata.facebook.impresions"
                    :value-twitter="funneldata.twitter.impresions"
                    :value-instagram="funneldata.instagram.impresions"
                    :value-youtube="funneldata.youtube.impresions"
                    :value-linkedin="funneldata.linkedin.impresions"
                  />
                  <h2 class="informacion text-lsm">
                    Impressions
                    <!-- <i
                      class="infoicon fa fa-question-circle text-white mr-1"             
                      v-b-tooltip title="All Campaign and Post Impressions were added, including video views. Tweets, Instagram and LinkedIn Posts are not included."          
                    ></i> -->
                  </h2>
                  <h1 class="text-3-5xl font-weight-bold">
                    <span title="0"
                      >{{
                        numFormatter(
                          funneldata.facebook.impresions +
                            funneldata.twitter.impresions +
                            funneldata.instagram.impresions +
                            funneldata.youtube.impresions +
                            funneldata.linkedin.impresions
                        )
                      }}
                    </span>
                  </h1>
                  <h3 class="text-xxs">Total</h3>
                </div>
              </div>
            </div>
            <div class="bx_ b_b">
              <div class="xengagement-item-3">
                <div class="middle">
                  <div class="icon text-2xl">
                    <i class="fa fa-mouse-pointer" aria-hidden="true"></i>
                  </div>
                  <cp-icons
                    :value-facebook="funneldata.facebook.interactions"
                    :value-twitter="funneldata.twitter.interactions"
                    :value-instagram="funneldata.instagram.interactions"
                    :value-youtube="funneldata.youtube.interactions"
                    :value-linkedin="funneldata.linkedin.interactions"
                  />
                  <div class="d-flex containerInteractions">
                    <h2 class="informacion text-lsm">Interactions</h2>
                    <i
                      id="interactions-b_b"
                      class="fa fa-info-circle iconInfo"
                      aria-hidden="true"
                    ></i>
                    <CpTooltipDoubleCustom
                      target="interactions-b_b"
                      tooltipTitle="interactions-b_b"
                      title="All Campaign actions and Post Interactions were added."
                      subTitle1=""
                      subTitle2="Facebook: Reacts + comments + shares Twitter: Fav + comments Instagram: Likes + comments Linkedin: Comments + Reacts"
                      heightSubTitle1="60px"
                      heightSubTitle2="86px"
                    >
                    </CpTooltipDoubleCustom>
                  </div>
                  <h1 class="text-3-5xl font-weight-bold">
                    <span>{{
                      numFormatter(
                        funneldata.facebook.interactions +
                          funneldata.twitter.interactions +
                          funneldata.instagram.interactions +
                          funneldata.youtube.interactions +
                          funneldata.linkedin.interactions
                      )
                    }}</span>
                  </h1>
                  <h3 class="text-xxs">Total</h3>
                </div>
              </div>
            </div>
            <div class="bx_ b_c">
              <div class="xengagement-item-4">
                <div class="middle">
                  <div class="icon text-2xl">
                    <i class="fa fa-list-alt" aria-hidden="true"></i>
                  </div>
                  <cp-icons
                    :value-facebook="funneldata?.facebook?.addons"
                    :value-twitter="funneldata?.twitter?.addons"
                    :value-instagram="funneldata?.instagram?.addons"
                    :value-youtube="funneldata?.youtube?.addons"
                    :value-linkedin="funneldata?.linkedin?.addons"
                    :value-direct="funneldata?.direct?.addons"
                    :is-addon-visit="true"
                  />
                  <h2 class="text-lsm mt-0 mb-0">Addon Visits</h2>
                  <h1 class="text-3-5xl font-weight-bold mb-1">
                    <span
                      v-if="
                        funneldata.facebook.addons +
                          funneldata.twitter.addons +
                          funneldata.instagram.addons +
                          funneldata.youtube.addons +
                          funneldata.linkedin.addons >
                        0
                      "
                      >{{
                        numFormatter(
                          funneldata.facebook.addons +
                            funneldata.twitter.addons +
                            funneldata.instagram.addons +
                            funneldata.youtube.addons +
                            funneldata.linkedin.addons +
                            funneldata.direct.addons
                        )
                      }}
                    </span>
                    <span
                      v-if="
                        funneldata.facebook.addons +
                          funneldata.twitter.addons +
                          funneldata.instagram.addons +
                          funneldata.youtube.addons +
                          funneldata.linkedin.addons ==
                        0
                      "
                      >-</span
                    >
                  </h1>

                  <div class="d-flex flex-column align-items-center">
                    <!-- <div class="addonmegavis" v-if="funneldata.facebook.addons>0"> -->
                    <div
                      class="pl-3 pr-3 w-75 d-flex align-items-center justify-content-between mb-0"
                      v-if="true"
                    >
                      <div class="addonmegat text-xxs">Facebook</div>
                      <div class="addonmegav text-xxs font-weight-bold">
                        <span>{{ funneldata.facebook.addons }}</span>
                      </div>
                    </div>
                    <div
                      class="pl-3 pr-3 w-75 d-flex align-items-center justify-content-between mb-0"
                      v-if="funneldata.twitter.addons > 0"
                    >
                      <div class="addonmegat text-xxs">Twitter</div>
                      <div class="addonmegav text-xxs font-weight-bold">
                        <span>{{ funneldata.twitter.addons }}</span>
                      </div>
                    </div>
                    <div
                      class="pl-3 pr-3 w-75 d-flex align-items-center justify-content-between mb-0"
                      v-if="funneldata.youtube.addons > 0"
                    >
                      <div class="addonmegat text-xxs">Youtube</div>
                      <div class="addonmegav text-xxs font-weight-bold">
                        <span>{{ funneldata.youtube.addons }}</span>
                      </div>
                    </div>
                    <div
                      class="pl-3 pr-3 w-75 d-flex align-items-center justify-content-between mb-0"
                      v-if="funneldata.linkedin.addons > 0"
                    >
                      <div class="addonmegat text-xxs">Linkedin</div>
                      <div class="addonmegav text-xxs font-weight-bold">
                        <span>{{ funneldata.linkedin.addons }}</span>
                      </div>
                    </div>
                    <div
                      class="pl-3 pr-3 w-75 d-flex align-items-center justify-content-between mb-0"
                      v-if="funneldata.instagram.addons > 0"
                    >
                      <div class="addonmegat text-xxs">Instagram</div>
                      <div class="addonmegav text-xxs font-weight-bold">
                        <span>{{ funneldata.instagram.addons }}</span>
                      </div>
                    </div>
                    <div
                      class="pl-3 pr-3 w-75 d-flex align-items-center justify-content-between mb-0"
                      v-if="funneldata.facebook.addons > 0"
                    >
                      <div class="addonmegat text-xxs">Direct</div>
                      <div class="addonmegav text-xxs font-weight-bold">
                        <span>{{ funneldata.direct.addons }} </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="box_funnel_03 d-flex flex-column justify-content-between">
        <div class="mb-2 text-center">
          <span class="text-xs text-theme">Social Performance</span>
        </div>
        <div
          class="engagement-item-6 text-white d-flex flex-column justify-content-between"
        >
          <div
            class="middle d-flex flex-column justify-content-center align-items-center"
          >
            <span class="text-lsm"> Results </span>
            <span class="mt-0 mb-1 text-3-5xl font-weight-bold"
              >{{
                funneldata.facebook.new_audience +
                funneldata.twitter.new_audience +
                funneldata.instagram.new_audience +
                funneldata.youtube.new_audience +
                funneldata.linkedin.new_audience
              }}
            </span>
            <div class="funnel-data w-100 flex-column">
              <div
                v-if="funneldata.facebook.posts > 0"
                class="funnel-data-result"
              >
                <span class="text-xxs">New Facebook Followers</span>
                <span class="text-xxs font-weight-bold">{{
                  funneldata.facebook.new_audience
                }}</span>
              </div>
              <div v-if="funneldata.twitter.posts > 0"
                class="funnel-data-result"
              >
                <span class="text-xxs">New Twitter Followers</span
                ><span class="text-xxs font-weight-bold">{{
                  funneldata.twitter.new_audience
                }}</span>
              </div>
              <div v-if="funneldata.instagram.posts > 0"
                class="funnel-data-result"
              >
                <span class="text-xxs">New Instagram Followers</span
                ><span class="text-xxs font-weight-bold">{{
                  funneldata.instagram.new_audience
                }}</span>
              </div>
              <div v-if="funneldata.youtube.posts > 0"
                class="funnel-data-result"
              >
                <span class="text-xxs">New YouTube Subscribers</span
                ><span class="text-xxs font-weight-bold">{{
                  funneldata.youtube.new_audience
                }}</span>
              </div>
              <div v-if="funneldata.linkedin.posts > 0"
                class="funnel-data-result"
              >
                <span class="text-xxs">New LinkedIn Followers</span
                ><span class="text-xxs font-weight-bold">{{
                  funneldata.linkedin.new_audience
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CpIcons from "./shared/CpIcons.vue";
import { CpTooltipDoubleCustom, Helpers } from "@isc/styleguide";
export default {
  components: {
    CpIcons,
    CpTooltipDoubleCustom,
  },
  props: {
    funneldata: Object,
  },
  data() {
    return {
      numFormatter: (num) => Helpers.numFormatter(num), 
    }
  },
  methods: {},
};
</script>

<style scoped>
.funnel {
  font-family: "Oxygen", sans-serif;
  font-size: 12px;
  margin: auto;
  background: white !important;
  padding: 20px;
}
.funnel-data-result {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.pd-lr-10 {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.row [class^="col-xs-"],
.row [class^="col-sm-"],
.row [class^="col-md-"],
.row [class^="col-lg-"] {
  min-height: 1px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
}
.bw {
  background: white !important;
}
.pad20 {
  padding: 20px;
}
.clear {
  clear: both;
}
.row {
  margin-left: 0px;
  margin-right: 0px;
}
.funnel_row {
  display: flex;
  justify-content: center;
}
.elti1 {
  font-size: 1.25em !important;
  font-weight: 600;
  color: #2c3852;
}
.elti1 {
  text-align: left;
}
.elti1sub {
  color: #2c3852;
  font-size: 1em;
}
.mfst {
  color: #2c3852;
  font-size: 1em;
  text-align: center;
}
.box_funnel_01 {
}
.box_funnel_01 .engagement-item-1,
.box_funnel_01 {
  flex-grow: 1;
}
.box_funnel_01 .engagement-item-1,
.box_funnel_01 .engagement-item-1 .bottom {
  background-color: #6971c6;
}
.engagement .engagement-item-1 {
  background: #00ccc0;
  padding-left: 0px;
  padding-bottom: 0px;
  padding-right: 0px;
}
.box_funnel_01 .top {
  padding: 45px 20px 0 20px !important;
}
.engagement-item-1 .top {
  background: transparent;
  border: 0px;
}
.engagement-item-1 .bottom {
  background: #00ccc0;
  padding: 0 20px 15px 20px;
}
.engagement-item-1 .bottom {
  border: 0px;
  text-align: center;
}
.box_into_funnel {
  position: absolute;
  top: 0;
  width: 100%;
}
.box_into_funnel .bx_.b_a {
  width: 213px;
}
.box_into_funnel .bx_ {
  padding-top: 45px;
}
.box_into_funnel .bx_.b_b {
  width: 225px;
}
.box_into_funnel .bx_.b_c {
  flex-grow: 1;
}
.box_funnel_02 {
  width: 697px;
  min-height: 100px;
  padding: 0 5px;
  position: relative;
}
.funnel_row .icon {
  margin-bottom: 10px;
  text-align: center;
}
.funnel_row h2 {
  text-align: center;
  padding-bottom: 0;
  padding-top: 0;
  margin-top: 10px;
  margin-bottom: 5px;
}
.box_into_funnel .bx_ h1 {
  margin-bottom: 0;
}
.funnel_row h1 {
  text-align: center;
  padding-top: 0px;
  margin-top: 0px;
  padding-bottom: 0px;
}
.funnel_row h3 {
  text-align: center;
  padding-top: 0px;
  margin-top: 0px;
  font-weight: normal;
}

.infoicon {
  position: relative;
  cursor: pointer;
}
.betafonoi {
  text-align: center;
}
.box_funnel_03 {
  flex-grow: 1;
}
.box_funnel_03 .engagement-item-6 {
  top: 0;
  flex-grow: 1;
  margin-top: 32px;
  margin-bottom: 32px;
}
.funnel_row div.engagement-item-6 .middle,
.engagement .engagement-item-1 .top {
  /* padding-top: 43px; */
}
.funnel_row div.engagement-item-6 .middle {
  background: #00a5ff !important;
}
.box_funnel_03 .engagement-item-6 .middle {
  background: #00a5ff !important;
  padding: 0 20px;
  flex-grow: 1;
}
.totalresult {
  margin-top: 10px !important;
}
.icointe {
  margin-left: 5px;
}
.infoitem {
  display: none;
}
.informacion .infoicon {
  display: none;
}
.informacion:hover .infoicon {
  display: inline;
}
.results {
  margin: 0;
  padding: 0;
  margin-bottom: 10px;
  top: 10px;
  position: absolute;
  left: 0;
  right: 0;
}
.funnel-data {
  display: flex;
  flex-wrap: wrap;
}
@media (max-width: 1024px) {
  .box_funnel_02 {
    width: 507px;
  }
  .box_funnel_02 img {
    width: 100%;
  }
  .box_into_funnel .bx_.b_a {
    width: 152px;
  }
  .box_into_funnel .bx_.b_b {
    width: 164px;
  }
  .funnel_row .icon {
    margin-bottom: 5px;
  }
  .box_into_funnel .bx_ {
    padding-top: 25px;
  }
  .box_funnel_03 .engagement-item-6 {
    margin-top: 23px;
    margin-bottom: 23px;
  }
}
.containerInteractions {
  align-items: center;
  justify-content: center;
}
.containerInteractions .iconInfo {
  margin-top: 10px;
  padding-left: 0.2rem;
}
</style>
