<template>
  <tr :id="post.key">
    <td><span class="text-xs text-color-text font-weight-bold">{{ post.title }}</span></td>
    <td><span class="text-xs text-color-text">{{ post.social_network }}</span></td>
    <td><span class="text-xs text-color-text">{{ post.publish_date }}</span></td>
    <td>
      <span class="delete-post" @click="emitDeletePost()"
        ><i class="fa fa-trash text-xs text-color-tex" aria-hidden="true"></i
      ></span>
    </td>
  </tr>
</template>
<script>
export default {
  name: "PostEdit",
  props: ["post"],
  methods: {
      emitDeletePost(){
          this.$emit("deletePost", this.post.key)
      },
  }
};
</script>