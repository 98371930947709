<template>
  <div id="div-goals">
    <div class="mb-2 pl-3">
      <span class="text-sm text-secondary font-weight-bold">Goal</span>
    </div>
    <div class="row">
      <div class="col-4 pr-0">
        <b-form-group
          id="input-group-goal-type"
          label=""
          label-for="input-goal-type"
        >
          <b-form-select
            id="input-goal-type"
            v-model="newGoalType"
            :options="options"
            @change="selectMetric($event)"
            value-field="idMetrica"
            text-field="nombreMetrica"
            class="text-sm text-color-text"
          ></b-form-select>
        </b-form-group>
        <span v-if="!newGoalType && pressButton" class="span-alert"
          >Type Goal is required</span
        >
      </div>
      <div class="col-4 pr-0">
        <b-form-group
          id="input-group-goal-number"
          label=""
          label-for="input-goal-number"
        >
          <b-form-input
            id="input-goal-number"
            class="text-sm text-color-text"
            min="1"
            v-model="newGoalNumber"
            label="&nbsp;&nbsp;&nbsp;&nbsp;"
            placeholder="0.0"
            type="number"
          ></b-form-input>
        </b-form-group>
        <span v-if="!newGoalNumber && pressButton" class="span-alert"
          >Amount of Audience is required</span
        >
        <span v-if="newGoalNumber < 0" class="span-alert"
          >Amount of Audience must be positive</span
        >
      </div>
      <div class="col-4">
        <b-button
          variant="primary"
          @click="addGoal()"
          class="mb-4 pt-1 pb-1 pl-3 pr-3"
        >
          <i class="fa fa-plus text-sm"></i>
          <span class="text-xs">Add Goal</span></b-button
        >
      </div>
    </div>
    <div>
      <table class="table table__goals">
        <thead>
          <tr class="text-xs">
            <th>
              <span class="font-weight-bold text-xs text-secondary"
                >Type of goal</span
              >
            </th>
            <th>
              <span class="font-weight-bold text-xs text-secondary"
                >Number of goal</span
              >
            </th>
            <th>
              <span class="font-weight-bold text-xs text-secondary"
                >Action</span
              >
            </th>
            <th style="display: none">id goal</th>
          </tr>
        </thead>
        <tbody id="containerlistgoals" ref="contenedor"></tbody>
      </table>
      <div
        v-if="pressButton && campaign.goals.length == 0"
        class="span-alert"
      >
        You should need almost 1 goal
      </div>
    </div>
    <b-modal id="modal-edit-goal" ref="modal-edit-goal" hide-footer size="lg">
      <template #modal-title>
        <span class="text-lg text-secondary font-weight-bold"> Edit Goal </span>
      </template>
      <div class="d-block text-center">
        <div class="row px-5" style="padding-left: 1rem !important">
          <div class="col-4"></div>
          <div class="col-4">
            <b-form-group
              id="input-group-goal-type"
              label="Goal"
              label-for="input-goal-type"
            >
              <b-form-input
                  id="input-name-goal"
                  v-model="arrayGoalsExits.nameGoal"
                  placeholder="Lead"
                  type="text"
                  size="sm"
                ></b-form-input>
              <!-- <b-form-select
                size="sm"
                id="input-goal-type"
                v-model="arrayGoalsExits.newGoalType"
                :options="options"
                value-field="idMetrica"
                text-field="nombreMetrica"
              ></b-form-select> -->
            </b-form-group>
            <b-form-group
              id="input-group-name"
              label="Target"
              label-for="input-name"
            >
              <b-form-input
                id="input-name"
                v-model="arrayGoalsExits.newGoalNumber"
                placeholder="0.0"
                type="number"
                size="sm"
              ></b-form-input>
              <span v-if="!arrayGoalsExits.newGoalNumber" class="span-alert"
                >Number is required</span
              >
              <span v-if="arrayGoalsExits.newGoalNumber < 0" class="span-alert"
                >Number must be positive</span
              >
            </b-form-group>
            <b-button
              class="mt-3 bg-primary border-0"
              block
              @click="saveEditGoals"
              ><span class="text-xs text-white">Send</span></b-button
            >
          </div>
          <div class="col-4"></div>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import * as CampaignService from "@/services/campaign.service";

export default {
  name: "GoalsListEdit",
  props: ["campaign"],

  data() {
    return {
      pressButton: false,
      options: [],
      arrayGoals: [],
      arrayGoalsExits: {
        newGoalType: null,
        newGoalNumber: null,
      },
      newGoalType: null,
      nameMetric: "",
      newGoalNumber: null,
      target: null,
      goalTypeExist: null,
    };
  },
  computed: {
    emptyGoals() {
      if (this.campaign.goals) {
        return !Object.entries(this.campaign.goals).filter((tupla) => {
          return tupla[1] != null;
        }).length;
      } else {
        return true;
      }
    },
  },
  mounted() {
    if (this.campaign.goals.length > 0) {
      for (var i = 0; i < this.campaign.goals.length; i++) {
        this.addGoalEdit(this.campaign.goals[i]);
      }
    }
  },
  created() {
    this.optionsSorted();
  },
  methods: {
    async optionsSorted() {
      this.options = await CampaignService.listMetrics("Audiencie");
      if (this.options != undefined) {
        this.options.push({
          nombreMetrica: "Select a metric",
          idMetrica: null,
        });
        if (this.options.length == 0) {
          return [
            {
              nombreMetrica: "Select a metric",
              idMetrica: null,
              disabled: true,
            },
          ];
        }
        return this.options.sort();
      }
    },
    saveEditGoals() {
      if (!this.arrayGoalsExits.newGoalNumber) return;
      if (this.arrayGoalsExits.newGoalNumber < 0) return;
      const newType = this.arrayGoalsExits.newGoalType;
      const newNumber = this.arrayGoalsExits.newGoalNumber;
      const arrayNewCamapaign = this.campaign.goals.map((goals) => {
        if (goals.newGoalType == newType) {
          return {
            newGoalType: goals.newGoalType,
            nameMetric: goals.nameMetric,
            newGoalNumber: newNumber,
            idCampaniaObjetivos: goals.idCampaniaObjetivos,
          };
        } else {
          return goals;
        }
      });
      if (arrayNewCamapaign) {
        this.campaign.goals = [];
        this.campaign.goals = arrayNewCamapaign;
      }
      const containerGoals = document.querySelector("#containerlistgoals");
      containerGoals.innerHTML = "";
      if (this.campaign.goals.length > 0) {
        for (var i = 0; i < this.campaign.goals.length; i++) {
          this.addGoalEdit(this.campaign.goals[i]);
        }
        this.$refs["modal-edit-goal"].hide(); // hide modal
      }
    },
    selectMetric(idMetrica) {
      const metricaArray = this.options.find(
        (option) => option.idMetrica === idMetrica
      );
      this.nameMetric = metricaArray.nombreMetrica;
    },
    //* create a list one by one of list of goals, also edit  
    addGoalEdit(goals) {
      const containerGoals = document.querySelector("#containerlistgoals");
      const row_goal = document.createElement("tr");
      const cell_goal_type = document.createElement("td");
      cell_goal_type.textContent = goals.newGoalType;
      cell_goal_type.style = "display:none;";
      const cell_goal_name = document.createElement("td");
      cell_goal_name.textContent = goals.nameMetric;
      const cell_goal_number = document.createElement("td");
      cell_goal_number.textContent = goals.newGoalNumber;
      const cell_goal_delete = document.createElement("td");
      const span_goal_delete = document.createElement("span");
      span_goal_delete.className = goals.newGoalType;
      span_goal_delete.addEventListener("click", () => {
        this.$refs["modal-edit-goal"].show();
        const metricaArrayExist = this.campaign.goals.find(
          (option) => option.newGoalType === Number(span_goal_delete.className)
        );
        //If selected object exits, set it up  
        if (metricaArrayExist) {
          this.arrayGoalsExits.newGoalType = metricaArrayExist.newGoalType;
          this.arrayGoalsExits.newGoalNumber = metricaArrayExist.newGoalNumber;
          this.arrayGoalsExits.nameGoal = metricaArrayExist.nameMetric;
        }
      });

      const icon_goal_delete = document.createElement("i");
      icon_goal_delete.className = "fa fa-edit";
      span_goal_delete.appendChild(icon_goal_delete);
      cell_goal_delete.appendChild(span_goal_delete);
      row_goal.appendChild(cell_goal_name);
      row_goal.appendChild(cell_goal_number);
      row_goal.appendChild(cell_goal_delete);
      row_goal.appendChild(cell_goal_type);
      containerGoals.appendChild(row_goal);
      const tableHtml = document.getElementById("containerlistgoals");
      this.campaign.table = tableHtml.innerHTML;
    },
    cgoal_edit(position, idType, number) {},
    addGoal() {
      this.numberGoalValidation();
      if (this.newGoalType && this.newGoalNumber > 0) {
        this.pressButton = false;
        const containerGoals = document.querySelector("#containerlistgoals");
        const row_goal = document.createElement("tr");
        const cell_goal_type = document.createElement("td");
        cell_goal_type.textContent = `${this.newGoalType}`;
        cell_goal_type.style = "display:none;";
        const cell_goal_name = document.createElement("td");
        cell_goal_name.textContent = `${this.nameMetric}`;
        const cell_goal_number = document.createElement("td");
        cell_goal_number.textContent = `${this.newGoalNumber}`;
        const cell_goal_delete = document.createElement("td");
        const span_goal_delete = document.createElement("span");
        span_goal_delete.className = `${this.newGoalType}`;
        span_goal_delete.addEventListener("click", () => {
          this.$refs["modal-edit-goal"].show();
          const metricaArrayExist = this.campaign.goals.find(
            (option) =>
              option.newGoalType === Number(span_goal_delete.className)
          );
          if (metricaArrayExist) {
            this.arrayGoalsExits.newGoalType = metricaArrayExist.newGoalType;
            this.arrayGoalsExits.newGoalNumber =
              metricaArrayExist.newGoalNumber;
            this.arrayGoalsExits.nameGoal = metricaArrayExist.nameMetric;
          }
        });
        const icon_goal_delete = document.createElement("i");
        icon_goal_delete.className = "fa fa-edit";
        span_goal_delete.appendChild(icon_goal_delete);
        cell_goal_delete.appendChild(span_goal_delete);
        row_goal.appendChild(cell_goal_name);
        row_goal.appendChild(cell_goal_number);
        row_goal.appendChild(cell_goal_delete);
        row_goal.appendChild(cell_goal_type);
        if (this.campaign.goals.length > 0) {
          const metricaArray = this.campaign.goals.find(
            (option) => option.newGoalType === this.newGoalType
          );
          if (metricaArray != undefined) {
            if (metricaArray.newGoalType > 0) {
              this.$bvToast.toast("Goal already exist.", {
                variant: "danger",
                solid: true,
                title: "Message",
              });
              return false;
            }
          }
        }

        containerGoals.appendChild(row_goal);
        this.campaign.goals.push({
          newGoalType: this.newGoalType,
          nameMetric: this.nameMetric,
          newGoalNumber: this.newGoalNumber,
          idCampaniaObjetivos: null,
        });
        this.options = this.options.filter((option) => {
          return option != this.newGoalType;
        });
        this.newGoalType = null;
        this.newGoalNumber = null;
        const tableHtml = document.getElementById("containerlistgoals");
        this.campaign.table = tableHtml.innerHTML;
      } else {
        this.pressButton = true;
        return;
      }
    },
    numberGoalValidation() {
      if (!this.newGoalNumber) return;
      if (this.newGoalNumber < 0) return;
      //if (this.newGoalNumber % 1 != 0) return;
    },
  },
};
</script>
<style scoped>
.span-alert {
  color: red;
  font-size: 12px;
}
/* .span-alert-goals {
  color: red;
  margin-left: 30%;
  font-size: 12px;
  font-weight: bolder;
} */
#div-goals {
  position: relative;
  border: 1px solid #cdd1da;
  padding: 8px;
  border-radius: 4px;
  /* width: 510px; */
  color: #9ca6b0 !important;
  margin: 10px;
  margin-top: 22px;
}
.table.table__goals tbody#containerlistgoals tr td {
  color: #5d6b88 !important;
  font-size: 0.75rem !important;
  line-height: 1rem !important;
}
</style>
